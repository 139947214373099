"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card modal-upload-batch-processing"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Adicionar novo arquivo")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("form", {
    attrs: {
      id: "form-upload-batch-processing-file"
    }
  }, [_vm.isAdmin ? _c("div", [_c("app-select", {
    attrs: {
      label: "Cliente",
      placeholder: "Selecione o cliente",
      items: _vm.listClients,
      option: "_id",
      "name-item": "name"
    },
    model: {
      value: _vm.clientUser,
      callback: function callback($$v) {
        _vm.clientUser = $$v;
      },
      expression: "clientUser"
    }
  }), _vm._v(" "), _vm.listBusinessUnits.length ? _c("app-select", {
    attrs: {
      label: "Área de negócio",
      placeholder: "Área de negócio",
      items: _vm.listBusinessUnits,
      option: "_id",
      "name-item": "name"
    },
    model: {
      value: _vm.clientBusinessUnit,
      callback: function callback($$v) {
        _vm.clientBusinessUnit = $$v;
      },
      expression: "clientBusinessUnit"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.processingType.type ? _c("div", [_vm.processingType.type === "select" && _vm.processingType.list.length ? _c("app-select", {
    attrs: {
      label: "Tipo do processamento",
      placeholder: "Informe qual o tipo de processamento",
      items: _vm.processingType.list
    },
    model: {
      value: _vm.form.processType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "processType", $$v);
      },
      expression: "form.processType"
    }
  }) : _vm._e(), _vm._v(" "), _vm.processingType.type === "checkbox" ? _c("b-field", {
    attrs: {
      label: "Tipo do processamento"
    }
  }, _vm._l(_vm.processingType.list, function (item, idx) {
    return _c("b-field", {
      key: idx
    }, [_c("b-checkbox", {
      attrs: {
        "native-value": item
      },
      model: {
        value: _vm.form.processType,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "processType", $$v);
        },
        expression: "form.processType"
      }
    }, [_vm._v(_vm._s(item))])], 1);
  }), 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c("b-field", {
    class: _vm.$v.form.file.$error ? "is-danger" : "",
    attrs: {
      label: "Arquivo"
    }
  }, [_c("b-upload", {
    attrs: {
      "drag-drop": "",
      accept: _vm.typeFile
    },
    model: {
      value: _vm.fileLogo,
      callback: function callback($$v) {
        _vm.fileLogo = $$v;
      },
      expression: "fileLogo"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.fileLogo ? _c("p", [_vm._v("Busque ou arraste o arquivo")]) : _c("p", [_vm._v(_vm._s(_vm.fileLogo.name || "Arquivo selecionado"))])], 1)])])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "content-btns"
  }, [_c("b-button", {
    attrs: {
      type: "is-info",
      disabled: _vm.$v.form.$invalid || _vm.isLoading,
      loading: _vm.isLoading
    },
    on: {
      click: _vm.checkForm
    }
  }, [_vm._v("\n        Enviar arquivo\n      ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;