"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-card-component-default"
  }, [_c("b-table", {
    attrs: {
      data: _vm.display
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "name",
      label: "Nome"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.person.personName) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "cnpj",
      label: "CNPJ"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.person.cnpjCpf) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "status",
      centered: "",
      label: "Status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "tag",
          class: {
            "is-success is-light": props.row.status === "A",
            "is-warning": props.row.status === "C",
            "is-danger": props.row.status === "I"
          }
        }, [_vm._v("\n          " + _vm._s(props.row.status === "A" ? "Ativo" : props.row.status === "I" ? "Inativo" : "Cancelada") + "\n        ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          attrs: {
            "icon-right": "file-outline"
          },
          on: {
            click: function click($event) {
              return _vm.actionOpen(props.row.person.personId);
            }
          }
        })];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          attrs: {
            "icon-right": "pen"
          },
          on: {
            click: function click($event) {
              return _vm.updatePage(props.row.person.personId);
            }
          }
        })];
      }
    }])
  })]], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;