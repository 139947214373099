"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-client-list"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Processos de Onboarding")]), _vm._v(" "), _vm.listClients > 3 ? _c("header-filter", {
    attrs: {
      placeholder: "Busque pelo nome ou CPF"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "column is-flex is-justify-content-flex-end is-align-content-flex-end"
  }, [_c("b-button", {
    attrs: {
      type: "is-info"
    },
    on: {
      click: _vm.openDialogConfigRegra
    }
  }, [_vm._v("Configurar\n    ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "content-list-table"
  }, [_vm.listItems.length ? _c("table-list-persons-onboarding", {
    attrs: {
      list: _vm.listItems,
      "action-open": _vm.openDetail
    }
  }) : _c("not-found", {
    attrs: {
      text: "Sem processos de onboarding no momento..."
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;