"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Alterar senha de acesso")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("form", {
    attrs: {
      id: "formNewTypeService"
    }
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Senha",
      placeholder: "*******",
      error: _vm.$v.form.UserPassword.$error,
      type: "password",
      complete: "new-password"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.UserPassword, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.UserPassword.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.UserPassword, "$model", $$v);
      },
      expression: "$v.form.UserPassword.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "Confirmar Senha",
      placeholder: "*******",
      error: _vm.$v.form.ConfirmUserPassword.$error,
      type: "password",
      complete: "new-password"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.ConfirmUserPassword, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.ConfirmUserPassword.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.ConfirmUserPassword, "$model", $$v);
      },
      expression: "$v.form.ConfirmUserPassword.$model"
    }
  }), _vm._v(" "), _c("check-password", {
    attrs: {
      pass: _vm.form.UserPassword,
      "confirm-pass": _vm.form.ConfirmUserPassword
    },
    on: {
      valid: _vm.validPass
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "content-btns"
  }, [_c("b-button", {
    attrs: {
      type: "is-info",
      disabled: !_vm.passIsValid
    },
    on: {
      click: _vm.checkForm
    }
  }, [_vm._v("\n        Salvar\n      ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;