"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "monitoring-report-general-page"
  }, [_c("div", {
    staticClass: "columns"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "column is-half"
  }, [_c("div", {
    staticClass: "card-filters"
  }, [_c("h3", [_vm._v("Período:")]), _vm._v(" "), _c("div", {
    staticClass: "content-filters"
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("b-datepicker", {
    attrs: {
      locale: "pt-BR",
      placeholder: "Selecione o périodo",
      "mobile-native": false,
      range: ""
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1), _vm._v(" "), _c("b-button", {
    attrs: {
      "icon-right": "magnify"
    },
    on: {
      click: _vm.getTotalBilling
    }
  }, [_vm._v("\n            Filtrar\n          ")])], 1)])])]), _vm._v(" "), _c("tiles", {
    attrs: {
      "max-per-row": 3
    }
  }, [_c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-primary",
      number: _vm.totalFaturamento || 0,
      "is-real": true,
      label: "TOTAL FATURAMENTO"
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.totalRequests || 0,
      label: "TOTAL CHAMADAS"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    attrs: {
      title: "Gestão de faturamento"
    }
  }, [_vm.chartRequestsTotal ? _c("doughnut-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartRequestsTotal
    }
  }) : _vm._e()], 1)], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "column is-half column-header-title"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_vm._v("\n          Acompanhamento Geral\n        ")])])]);
}];
render._withStripped = true;