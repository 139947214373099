"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("Doughnut", {
    attrs: {
      "chart-options": _vm.chartOptions,
      "chart-data": _vm.chartData,
      "chart-id": _vm.chartId,
      "dataset-id-key": _vm.datasetIdKey,
      plugins: _vm.plugins,
      "css-classes": _vm.cssClasses,
      styles: _vm.styles,
      width: _vm.width,
      height: _vm.height
    }
  });
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;