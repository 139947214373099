"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'FullPage',
  created: function created() {
    this.$store.dispatch('toggleFullPage', true);
  },
  beforeDestroy: function beforeDestroy() {
    this.$store.dispatch('toggleFullPage', false);
  }
};