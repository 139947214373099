"use strict";

var _vue = _interopRequireDefault(require("vue"));
var _moment = _interopRequireDefault(require("moment"));
var _constants = require("@/config/constants");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
_vue.default.filter('maskCNPJ', function (value) {
  if (value) {
    var cnpj = String(value).replace(/\D/g, '');
    var result = cnpj.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');
    return result;
  }
  return '';
});
_vue.default.filter('maskCPF', function (value) {
  if (value) {
    var CPF = String(value).replace(/\D/g, '');
    var result = CPF.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return result;
  }
  return '';
});
_vue.default.filter('maskHideCPF', function (value) {
  if (value) {
    var CPF = String(value).replace(/\D/g, '');
    var result = CPF.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.xxx.xxx-$4');
    return result;
  }
  return '';
});
_vue.default.filter('dateFormat', function (value) {
  if (value) {
    var date = _moment.default.utc(value);
    var d = date.format('DD');
    var m = date.format('MM');
    var y = date.format('YYYY');
    return "".concat(d, "/").concat(m, "/").concat(y);
  }
});
_vue.default.filter('monthDay', function (value) {
  if (value) {
    var date = (0, _moment.default)(String(value));
    var d = date.format('DD');
    var m = date.format('MMM');
    var y = date.format('YYYY');
    return "".concat(m, " ").concat(d, ", ").concat(y);
  }
});
_vue.default.filter('monthYear', function (value) {
  if (value) {
    var date = (0, _moment.default)(String(value));
    var m = date.format('MMMM');
    var y = date.format('YYYY');
    return "".concat(m, " \u2014 ").concat(y);
  }
});
_vue.default.filter('dateTimeWithToday', function (value) {
  if (value) {
    var momentFormat = 'DD/MM/YYYY';
    var date = (0, _moment.default)(String(value));
    var dateInfo = date.format(momentFormat);
    var returnDate = dateInfo === (0, _moment.default)().format(momentFormat) ? 'Hoje' : dateInfo;
    var h = date.format('HH:mm');
    return "".concat(returnDate, " \xE0s ").concat(h);
  }
  return '--';
});
_vue.default.filter('dateBrazil', function (value) {
  if (value) {
    return _moment.default.utc(value).local().format('DD/MM/YYYY - HH:mm:ss');
  }
  return '';
});
_vue.default.filter('date', function (value) {
  if (value) {
    return (0, _moment.default)(value).format('DD/MM/YYYY - HH:mm');
  }
  return '';
});
_vue.default.filter('dateComplete', function (value) {
  if (value) {
    return (0, _moment.default)(value).format('DD/MM/YYYY - HH:mm:ss');
  }
  return '--';
});
_vue.default.filter('dateCompleteWithMiliseconds', function (value) {
  if (value) {
    return (0, _moment.default)(value).format('DD/MM/YYYY - HH:mm:ss.SSS');
  }
  return '--';
});
_vue.default.filter('dateCompleteLocal', function (value) {
  if (value) {
    return (0, _moment.default)(value).local().format('DD/MM/YYYY - HH:mm:ss');
  }
  return '--';
});
_vue.default.filter('translateTypePerson', function (value) {
  if (value) {
    return _constants.TYPES_PERSONS[value];
  }
  return 'Outro';
});
_vue.default.filter('maskPhoneMinus', function (value) {
  if (value) {
    if (value.length === 8) return value.replace(/^(\d{4})(\d{4})/, '$1-$2');
    if (value.length === 9) return value.replace(/^(\d{5})(\d{4})/, '$1-$2');
  }
  return '--';
});
_vue.default.filter('maskPhone', function (value) {
  if (value) {
    return value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  return '--';
});
_vue.default.filter('maskPhoneComplete', function (value) {
  if (value) {
    var phone = String(value).replace(/\D/g, '');
    var valid = value.length === 12 ? /^(\d{2})(\d{2})(\d{4})(\d{4})/ : /^(\d{2})(\d{2})(\d{4})(\d{5})/;
    return phone.replace(valid, '+$1 ($2) $3-$4');
  }
  return '';
});
_vue.default.filter('maskMoney', function (value) {
  if (value) {
    var valueFin = Number(value);
    if (valueFin) {
      return valueFin.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      });
    }
  }
  return '--';
});
_vue.default.filter('dateDetail', function (value) {
  if (value) {
    var date = (0, _moment.default)(String(value));
    var d = date.format('DD');
    var m = date.format('MMMM');
    var y = date.format('YYYY');
    return "".concat(d, " de ").concat(m, " de ").concat(y);
  }
});
_vue.default.filter('percent', function (value) {
  if (value) {
    return "".concat(value.toFixed(2), "%");
  }
  return '--';
});
_vue.default.filter('maskEndPoint', function (value) {
  if (value) {
    var numbers = value.replace(/\D/g, '');
    if (numbers.length >= 11) {
      return value.replace(new RegExp('[0-9]', 'g'), 'x');
    }
    return value;
  }
  return '--';
});
_vue.default.filter('bytesToSize', function (value) {
  if (value) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (value === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(value) / Math.log(1024)));
    return Math.round(value / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }
  return '--';
});
_vue.default.filter('maskNumber', function (value) {
  if (value) {
    var num = value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0
    });
    num = num.replace('R$', '');
    num = num.replace(/\s/g, '');
    return num;
  }
  return '--';
});
_vue.default.filter('toUpperCase', function (value) {
  if (value) {
    var text = value.replace('accounts.', '');
    return text.toUpperCase();
  }
  return '--';
});
_vue.default.filter('translateBioface', function (value) {
  if (value) {
    var verify = _constants.LIST_BIOFACE.find(function (i) {
      return i.status === value;
    });
    if (verify) {
      return "".concat(value, "(").concat(verify.score, ")");
    }
    return value;
  }
  return '-';
});
_vue.default.filter('gender', function (value) {
  if (value && value === 1) {
    return 'Masculino';
  }
  return 'Feminino';
});
_vue.default.filter('percentEquivalent', function (value, baseValue) {
  var calc = value * 100 / baseValue;
  return "".concat(calc, "%");
});
_vue.default.filter('translatePersonCredito', function (value) {
  if (value) {
    var verify = _constants.LIST_PERSONS_CREDIT.find(function (i) {
      return i.type === value;
    });
    if (verify) {
      return "".concat(value, " - ").concat(verify.description);
    }
    return value;
  }
  return '-';
});
_vue.default.filter('translateConsumer', function (value) {
  if (value) {
    var consumer = value.replace('resourcehive/', '');
    return consumer;
  }
  return '--';
});
_vue.default.filter('timestampToDate', function (value) {
  if (value) {
    return _moment.default.unix(value / 1000).utc(-3).format('DD/MM/YYYY - HH:mm:ss');
  }
  return '--';
});
_vue.default.filter('translateTypeUser', function (value) {
  if (value) {
    var verify = _constants.LIST_TYPES_USERS.find(function (i) {
      return i.idItem === value;
    });
    return (verify === null || verify === void 0 ? void 0 : verify.name) || '';
  }
  return '--';
});
_vue.default.filter('translateTypeRequest', function (value) {
  if (value) {
    var verify = _constants.LIST_TYPES_REQUESTS.find(function (i) {
      return i.idItem === value;
    });
    return (verify === null || verify === void 0 ? void 0 : verify.name) || '';
  }
  return 'Inicio protocolo';
});
_vue.default.filter('typeTransaction', function (value) {
  if (value) {
    return _constants.TYPES_TRANSACTIONAL_OPERATIONS[value] || '';
  }
  return '--';
});
_vue.default.filter('typeStatusOnboarding', function (value) {
  if (value) {
    return _constants.TYPES_STATUS_ONBOARDING[value] || '';
  }
  return '--';
});
_vue.default.filter('transalateStatuseyes', function (value) {
  if (value) {
    return value === 'OPEN' ? 'Abertos' : 'Fechados';
  }
  return '--';
});
_vue.default.filter('hand', function (value) {
  if (value) {
    if (value.toLowerCase() === 'left') {
      return 'Esquerda';
    }
    return 'Direita';
  }
  return '--';
});
_vue.default.filter('finger', function (value) {
  if (value) {
    var finger = 'Indefinido';
    switch (value.toLowerCase()) {
      case 'index':
        finger = 'Indicador';
        break;
      case 'middle':
        finger = 'Dedo do meio';
        break;
      case 'ring':
        finger = 'Dedo anelar';
        break;
      case 'little':
        finger = 'Dedo mindinho';
        break;
      default:
        finger = 'Indefinido';
        break;
    }
    return finger;
  }
  return '--';
});
_vue.default.filter('documentoscopia', function (value) {
  if (value) {
    var verify = _constants.TYPES_CODES_DOCUMENTOSCOPIA.find(function (i) {
      return Number(i.id) === Number(value);
    });
    return (verify === null || verify === void 0 ? void 0 : verify.description) || 'Em Análise';
  }
  return 'Em Análise';
});
_vue.default.filter('statusBatchProcessing', function (value) {
  if (value) {
    var verify = _constants.STATUS_PROCESS_BATCH_PROCESSING.find(function (i) {
      return i.status === value;
    });
    return (verify === null || verify === void 0 ? void 0 : verify.description) || '--';
  }
  return 'Aguardando processamento';
});
_vue.default.filter('calcDiffDate', function (value) {
  if (value) {
    var date = (0, _moment.default)(value);
    var dateNow = (0, _moment.default)();
    var diffDays = dateNow.diff(date, 'days') + 'd';
    var diffHours = dateNow.diff(date, 'hours') + 'h';
    var diffMinutes = dateNow.diff(date, 'minutes') + 'm';
    return "".concat(diffDays, " ").concat(diffHours, " ").concat(diffMinutes);
  }
  return 'Aguardando processamento';
});
_vue.default.filter('translateStatus', function (value) {
  if (value) {
    var verify = _constants.TYPES_STATUS_TICKETS.find(function (i) {
      return i.status === value;
    });
    return (verify === null || verify === void 0 ? void 0 : verify.description) || '--';
  }
  return '--';
});
_vue.default.filter('translateProcessType', function (value) {
  if (value) {
    var texto = value.join(', ');
    return texto;
  }
  return '--';
});