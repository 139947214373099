"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'ListFundsTable',
  props: {
    funds: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    checkItems: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    action: {
      type: Function,
      default: function _default() {}
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    documentCheck: function documentCheck(document) {
      var verify = this.checkItems.find(function (i) {
        return i.personId === document.personId;
      });
      return !verify;
    },
    addDocumentList: function addDocumentList(document) {
      this.action(document);
    }
  }
};