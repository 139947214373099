"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'ModalWarning',
  props: {
    text: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    idItem: {
      type: [String, Number],
      default: null
    },
    idSecond: {
      type: [String, Number],
      default: null
    },
    action: {
      type: Function,
      default: function _default() {}
    },
    textConfirm: {
      type: String,
      default: 'Excluir'
    },
    type: {
      type: String,
      default: 'is-danger'
    }
  },
  methods: {
    cancel: function cancel() {
      this.$parent.close();
    },
    confirm: function confirm() {
      this.$parent.close();
      this.action(this.idItem, this.idSecond);
    }
  }
};