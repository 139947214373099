"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _clickOuside = _interopRequireDefault(require("../directives/click-ouside.js"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

var GlobalDirectives = {
  install: function install(Vue) {
    Vue.directive('click-outside', _clickOuside.default);
  }
};
var _default = exports.default = GlobalDirectives;