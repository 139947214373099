"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-new-ticket"
  }, [_c("el-card", {
    staticClass: "box-card ticket-infos"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Abrir novo ticket de atendimento")])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "section-title-container"
  }, [_c("h2", {
    staticClass: "mb-3 required"
  }, [_vm._v("Seu ticket é para tratar de um:")])]), _vm._v(" "), _c("section", [_c("b-field", [_c("b-radio", {
    attrs: {
      type: "is-info",
      "native-value": "Protocolo HIVE"
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, [_vm._v("Protocolo HIVE")])], 1), _vm._v(" "), _c("b-field", [_c("b-radio", {
    attrs: {
      type: "is-info",
      "native-value": "Protocolo Cliente"
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, [_vm._v("Protocolo Cliente")])], 1), _vm._v(" "), _c("b-field", [_c("b-radio", {
    attrs: {
      type: "is-info",
      "native-value": "Serviço HIVE"
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, [_vm._v("Serviço HIVE")])], 1), _vm._v(" "), _c("b-field", [_c("b-radio", {
    attrs: {
      type: "is-info",
      "native-value": "Outro"
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, [_vm._v("Outro")])], 1)], 1), _vm._v(" "), _vm.form.subject.startsWith("Protocolo") ? _c("div", {
    staticClass: "item-section"
  }, [_c("div", {
    staticClass: "section-title-container"
  }, [_c("h2", {
    staticClass: "required"
  }, [_vm._v("Número do protocolo:")]), _vm._v(" "), _vm.$v.form.protocolNumber.$error ? _c("span", [_vm._v("Informe o número do protocolo")]) : _vm._e()]), _vm._v(" "), _c("b-input", {
    model: {
      value: _vm.$v.form.protocolNumber.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.protocolNumber, "$model", $$v);
      },
      expression: "$v.form.protocolNumber.$model"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.form.subject === "Serviço HIVE" ? _c("div", {
    staticClass: "item-section"
  }, [_c("div", {
    staticClass: "section-title-container"
  }, [_c("h2", {
    staticClass: "required"
  }, [_vm._v("Para qual serviço deseja atendimento?")]), _vm._v(" "), _vm.showErrorServiceType ? _c("span", [_vm._v("Informe para qual serviço deseja o atendimento")]) : _vm._e()]), _vm._v(" "), _c("section", _vm._l(_vm.ticketsServices, function (service) {
    return _c("b-field", {
      key: service._id
    }, [_c("b-radio", {
      attrs: {
        type: "is-info",
        "native-value": service._id
      },
      model: {
        value: _vm.form.digitalAssetId,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "digitalAssetId", $$v);
        },
        expression: "form.digitalAssetId"
      }
    }, [_vm._v(_vm._s(service.name))])], 1);
  }), 1)]) : _vm._e(), _vm._v(" "), _vm.form.subject !== "" ? _c("div", {
    staticClass: "item-section"
  }, [_c("div", {
    staticClass: "section-title-container"
  }, [_c("h2", {
    staticClass: "required"
  }, [_vm._v("Qual é o assunto no qual podemos te ajudar?")]), _vm._v(" "), _vm.$v.form.ticketTypeId.$error ? _c("span", [_vm._v("Informe o assunto no qual lhe prestaremos ajuda")]) : _vm._e()]), _vm._v(" "), _c("section", _vm._l(_vm.ticketsTypes, function (ticketType) {
    return _c("b-field", {
      key: ticketType._id
    }, [_c("b-radio", {
      attrs: {
        type: "is-info",
        "native-value": ticketType._id
      },
      model: {
        value: _vm.form.ticketTypeId,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "ticketTypeId", $$v);
        },
        expression: "form.ticketTypeId"
      }
    }, [_vm._v(_vm._s(ticketType.name))])], 1);
  }), 1)]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_vm.form.subject !== "" ? _c("div", [_c("div", [_c("div", {
    staticClass: "section-title-container"
  }, [_c("h2", {
    staticClass: "required"
  }, [_vm._v("Descreva sua solicitação")]), _vm._v(" "), _vm.showErrorTicketDescription ? _c("span", [_vm._v("Detalhe o problema/dúvida que está enfrentando.")]) : _vm._e()]), _vm._v(" "), _c("b-input", {
    class: _vm.textAreaHeightClass,
    attrs: {
      maxlength: "1000",
      type: "textarea"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-2"
  }, [_c("div", {
    staticClass: "section-title-container"
  }, [_c("h2", {
    staticClass: "mb-3"
  }, [_vm._v("Anexos (opcional)")])]), _vm._v(" "), _c("b-field", [_c("b-upload", {
    attrs: {
      multiple: "",
      "drag-drop": "",
      expanded: "",
      accept: "image/*,.pdf,.csv"
    },
    model: {
      value: _vm.dropFiles,
      callback: function callback($$v) {
        _vm.dropFiles = $$v;
      },
      expression: "dropFiles"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "upload",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("Selecione o(s) arquivo(s) aqui para enviar")])])])])], 1), _vm._v(" "), _c("div", {
    staticClass: "tags"
  }, _vm._l(_vm.dropFiles, function (file, index) {
    return _c("span", {
      key: index,
      staticClass: "tag is-primary"
    }, [_vm._v("\n                " + _vm._s(file.name) + "\n                "), _c("button", {
      staticClass: "delete is-small",
      attrs: {
        type: "button"
      },
      on: {
        click: function click($event) {
          return _vm.deleteDropFile(index);
        }
      }
    })]);
  }), 0)], 1), _vm._v(" "), _c("b-button", {
    staticClass: "mt-5",
    attrs: {
      type: "is-primary",
      expanded: ""
    },
    on: {
      click: function click($event) {
        return _vm.submitForm();
      }
    }
  }, [_vm._v("Abrir novo ticket")])], 1) : _vm._e()])]), _vm._v(" "), _c("div")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;