"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _pt = _interopRequireDefault(require("@/locales/pt.json"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
_vue.default.use(_vueI18n.default);
function loadLocaleMessages() {
  var messages = {
    pt: _pt.default
  };
  return messages;
}
var _default = exports.default = new _vueI18n.default({
  locale: process.env.VUE_APP_I18N_LOCALE || 'pt',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt',
  messages: loadLocaleMessages()
});