"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card modal-form"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v(_vm._s(_vm.title) + " tipo de serviço")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("close");
      }
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("form", {
    attrs: {
      id: "formNewTypeService"
    },
    on: {
      submit: _vm.checkForm
    }
  }, [_c("app-input", {
    attrs: {
      label: "Nome do serviço",
      placeholder: "Informe o nome do serviço",
      error: _vm.$v.form.name.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", $$v);
      },
      expression: "$v.form.name.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Descrição do serviço",
      placeholder: "Informe a descrição serviço",
      error: _vm.$v.form.description.$error,
      type: "textarea",
      max: "100"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.description, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.description.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.description, "$model", $$v);
      },
      expression: "$v.form.description.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "modal-actions"
  }, [_c("b-button", {
    attrs: {
      label: "Cancelar"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("close");
      }
    }
  }), _vm._v(" "), _c("b-button", {
    attrs: {
      label: "Salvar",
      type: "is-primary",
      "native-type": "submit"
    }
  })], 1)], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;