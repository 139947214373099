"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "component-monitor-status-items"
  }, [_c("button", {
    staticClass: "status-primary nb-transition",
    attrs: {
      status: "primary",
      tabindex: "0"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.healthy) + " healthy\n  ")]), _vm._v(" "), _c("button", {
    staticClass: "status-warning nb-transition",
    attrs: {
      status: "warning",
      tabindex: "1"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.warning) + " warning\n  ")]), _vm._v(" "), _c("button", {
    staticClass: "status-danger nb-transition",
    attrs: {
      status: "danger",
      tabindex: "2"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.critical) + " critical\n  ")])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;