"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header-page-component"
  }, [_c("div", {
    staticClass: "col-title title"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")])]), _vm._v(" "), _c("div", {
    staticClass: "col-action-buttons"
  }, [_c("el-button", {
    staticClass: "btn-new-person",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.action();
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.titleButton) + "\n    ")])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;