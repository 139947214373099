"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-dashboard-details-service"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n          " + _vm._s(_vm.service.name) + "\n        ")])])])]), _vm._v(" "), _c("b-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("b-tab-item", {
    attrs: {
      label: "Monitor"
    }
  }, [_c("tiles", [_c("div", {
    staticClass: "card-filters"
  }, [_c("h3", [_vm._v("Período:")]), _vm._v(" "), _c("div", {
    staticClass: "content-filters"
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    }
  }, [_c("b-datepicker", {
    attrs: {
      locale: "pt-BR",
      placeholder: "Selecione o périodo",
      "mobile-native": false,
      range: ""
    },
    model: {
      value: _vm.dates,
      callback: function callback($$v) {
        _vm.dates = $$v;
      },
      expression: "dates"
    }
  })], 1), _vm._v(" "), _c("b-button", {
    attrs: {
      "icon-right": "magnify"
    },
    on: {
      click: _vm.updateDataItems
    }
  }, [_vm._v("\n              Filtrar\n            ")])], 1)])]), _vm._v(" "), _c("tiles", {
    attrs: {
      "max-per-row": 7
    }
  }, [_c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-primary",
      number: _vm.totalRequests.totalCreatedHive || 0,
      label: "CRIADOS HIVE",
      load: _vm.loadTotalRequests
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-success",
      number: _vm.totalRequests.totalCreatedProvider || 0,
      label: "CRIADOS PROVEDOR",
      load: _vm.loadTotalRequests
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-success",
      number: _vm.totalRequests.totalErrosProvider || 0,
      label: "ERROS PROVEDOR",
      load: _vm.loadTotalRequests
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.totalRequests.totalReceivedHive || 0,
      label: "RECEBIDOS HIVE",
      load: _vm.loadTotalRequests
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.totalRequests.totalSentClient || 0,
      label: "RECEBIDOS CLIENTE",
      load: _vm.loadTotalRequests
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.totalRequests.totalErrosClient || 0,
      label: "ERROS CLIENTE",
      load: _vm.loadTotalRequests
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.totalRequests.evarage || 0,
      label: "TEMPO MÉDIO (ms)",
      load: _vm.loadTotalRequests
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    attrs: {
      title: _vm.titleProtocolsCreatedHive,
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.updateChartCreatedHive
    }
  }, [!_vm.loadChartCreatedHive && _vm.chartDataCreatedHive ? _c("div", {
    staticClass: "chart-area"
  }, [_c("line-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartDataCreatedHive,
      "chart-options": _vm.chartOptions
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.loadChartCreatedHive ? _c("b-skeleton", {
    attrs: {
      height: "200px"
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    attrs: {
      title: _vm.titleProtocolsCreatedProvider,
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.updateChartCreatedProvider
    }
  }, [!_vm.loadChartCreatedProvider && _vm.chartDataCreatedProvider ? _c("div", {
    staticClass: "chart-area"
  }, [_c("line-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartDataCreatedProvider,
      "chart-options": _vm.chartOptions
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.loadChartCreatedProvider ? _c("b-skeleton", {
    attrs: {
      height: "200px"
    }
  }) : _vm._e()], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    attrs: {
      title: _vm.titleProtocolsReceivedHive,
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.updateChartReceivedHive
    }
  }, [!_vm.loadChartReceivedHive && _vm.chartDataReceivedHive ? _c("div", {
    staticClass: "chart-area"
  }, [_c("line-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartDataReceivedHive,
      "chart-options": _vm.chartOptions
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.loadChartReceivedHive ? _c("b-skeleton", {
    attrs: {
      height: "200px"
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-component", {
    attrs: {
      title: _vm.titleProtocolsSendClient,
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.updateChartSendClient
    }
  }, [!_vm.loadChartSendClient && _vm.chartDataSendClient ? _c("div", {
    staticClass: "chart-area"
  }, [_c("line-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartDataSendClient,
      "chart-options": _vm.chartOptions
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.loadChartSendClient ? _c("b-skeleton", {
    attrs: {
      height: "200px"
    }
  }) : _vm._e()], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-half"
  }, [_c("card-component", {
    attrs: {
      title: _vm.titleTimeRequest,
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.updateChartRequestTime
    }
  }, [!_vm.loadChartRequestTime && _vm.chartDataRequestTime ? _c("div", {
    staticClass: "chart-area"
  }, [_c("line-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartDataRequestTime,
      "chart-options": _vm.chartOptions
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.loadChartRequestTime ? _c("b-skeleton", {
    attrs: {
      height: "200px"
    }
  }) : _vm._e()], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "card card-api-request-component p-4 mb-4 table-list-transaction"
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("\n              Listagem das últimas transações\n            ")])])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [!_vm.loadLastRequestsByStep ? _c("table-last-requests-list", {
    attrs: {
      list: _vm.list
    }
  }) : _c("div", [_c("b-skeleton", {
    attrs: {
      height: "120px"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tiles", {
    attrs: {
      "max-per-row": 2
    }
  }, [_c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-primary",
      number: _vm.successRate || 0,
      label: "Taxa de sucesso",
      suffix: "%"
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-primary",
      number: _vm.errorRate || 0,
      label: "Percentual de erros",
      suffix: "%"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-half"
  }, [_c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Lista de erros"
    }
  }, [_c("table-services-inactives-calls-list", {
    attrs: {
      list: _vm.listNotFetchs || [],
      "status-code": true
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column is-half"
  }, [_c("card-component", {
    staticClass: "has-table has-mobile-sort-spaced",
    attrs: {
      title: "Tempo de resposta das requisições"
    }
  }, [_c("b-table", {
    attrs: {
      data: _vm.requestsTimeIntervals,
      "default-sort": "numberToSort",
      "default-sort-direction": "asc",
      striped: "",
      hoverable: ""
    }
  }, [_c("b-table-column", {
    attrs: {
      label: "Tempo de resposta",
      field: "responseTime"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n                " + _vm._s(props.row.responseTime) + "\n              ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Qtd.",
      field: "quantity",
      sortable: "",
      centered: "",
      width: "85"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticStyle: {
            "text-align": "right",
            "padding-right": "5px"
          }
        }, [_vm._v(_vm._s(props.row.quantity))])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "%",
      field: "percentage",
      sortable: "",
      centered: "",
      width: "85"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticStyle: {
            "text-align": "right",
            "padding-right": "5px"
          }
        }, [_vm._v(_vm._s(props.row.percentage))])];
      }
    }])
  }), _vm._v(" "), _c("section", {
    staticClass: "section",
    attrs: {
      slot: "empty"
    },
    slot: "empty"
  }, [_c("div", {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("Sem registros até o momento…")])])])], 1)], 1)], 1)])], 1), _vm._v(" "), _vm.permissionAccess ? _c("b-tab-item", {
    attrs: {
      label: "Analítico"
    }
  }, [_vm.activeTab === 1 ? _c("power-bi", {
    attrs: {
      "digital-asset": _vm.service._id
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;