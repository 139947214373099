"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-users-list-component"
  }, [_c("b-table", {
    attrs: {
      data: _vm.list
    },
    on: {
      click: _vm.actionOpen
    }
  }, [[_c("b-table-column", {
    attrs: {
      label: "NOME"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(_vm._f("toUpperCase")(props.row.personId.name)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "E-mail"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.personId.email) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      centered: "",
      label: "STATUS"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "tag",
          class: {
            "is-success is-light": props.row.activated,
            "is-danger": !props.row.activated
          }
        }, [_vm._v("\n          " + _vm._s(props.row.activated ? "Ativo" : !props.row.activated ? "Paralisada" : "Inativo") + "\n        ")])];
      }
    }])
  })]], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;