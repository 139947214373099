"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$service, _vm$service$digitalAs;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-connection-service-detail"
  }, [_c("b-loading", {
    attrs: {
      "is-full-page": true
    },
    model: {
      value: _vm.isLoading,
      callback: function callback($$v) {
        _vm.isLoading = $$v;
      },
      expression: "isLoading"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v(" "), _vm.service.Logo ? _c("img", {
    attrs: {
      src: _vm.service.Logo,
      alt: "logo"
    }
  }) : _vm._e(), _vm._v("\n          " + _vm._s(_vm._f("toUpperCase")(_vm.service.name)) + "\n        ")]), _vm._v(" "), _c("b-field", {
    staticClass: "filter-month"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Selecione o mês",
      items: _vm.listMonths,
      option: "id",
      "name-item": "name"
    },
    model: {
      value: _vm.selectMonth,
      callback: function callback($$v) {
        _vm.selectMonth = $$v;
      },
      expression: "selectMonth"
    }
  }), _vm._v(" "), _c("app-select", {
    attrs: {
      placeholder: "Selecione o ano",
      items: _vm.listYears,
      option: "id",
      "name-item": "name"
    },
    model: {
      value: _vm.selectYear,
      callback: function callback($$v) {
        _vm.selectYear = $$v;
      },
      expression: "selectYear"
    }
  }), _vm._v(" "), _c("p", {
    staticClass: "control control-btn"
  }, [_c("b-button", {
    attrs: {
      type: "is-primary",
      label: "Filtrar"
    },
    on: {
      click: _vm.getDetails
    }
  })], 1)], 1)], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column col-cards-list"
  }, [_c("div", {
    staticClass: "content-descriptions"
  }, [_c("h3", [_vm._v("Overview")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_vm.isAdmin ? _c("div", {
    staticClass: "column"
  }, [_c("card-service-detail-list", {
    attrs: {
      title: "Provedor",
      service: _vm._f("maskMoney")(_vm.totalProvider),
      month: _vm.month
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openExtract.apply(null, arguments);
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-service-detail-list", {
    attrs: {
      title: "Consumidores",
      service: _vm._f("maskMoney")(_vm.totalConsumers),
      month: _vm.month
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openExtractConsumer.apply(null, arguments);
      }
    }
  })], 1), _vm._v(" "), _vm.isAdmin ? _c("div", {
    staticClass: "column"
  }, [_c("card-service-detail-list", {
    attrs: {
      title: "Resultado",
      service: _vm._f("maskMoney")(_vm.result),
      month: _vm.month
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openExtractResult.apply(null, arguments);
      }
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-service-detail-list", {
    attrs: {
      title: "Requisições Total",
      service: _vm._f("maskNumber")(_vm.totalRequestsHive),
      month: _vm.month
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openExtractRequests.apply(null, arguments);
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-service-detail-list", {
    attrs: {
      title: "Requisições com sucesso",
      service: _vm._f("maskNumber")(_vm.totalRequestProviders),
      month: _vm.month
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openExtractRequests.apply(null, arguments);
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card-service-detail-list", {
    attrs: {
      title: "Erros",
      service: _vm._f("maskNumber")(_vm.errorAverage),
      error: true,
      month: _vm.month
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openExtractRequestsError.apply(null, arguments);
      }
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card-service-detail-list", {
    attrs: {
      title: "Extrato financeiro",
      service: "Mensal",
      month: _vm.month
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openExtractDocuments.apply(null, arguments);
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }), _vm._v(" "), _c("div", {
    staticClass: "column"
  })])])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column col-descriptions"
  }, [_c("div", {
    staticClass: "content-descriptions"
  }, [_c("h3", [_vm._v("Informações")]), _vm._v(" "), _c("p", [_vm._v("\n          " + _vm._s(_vm.service.description) + "\n        ")])])]), _vm._v(" "), _c("div", {
    staticClass: "column is-one-third col-details"
  }, [_c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Nome do serviço")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.service.name))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "service"
    }
  }, [_vm._v("Tipo de serviço")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(((_vm$service = _vm.service) === null || _vm$service === void 0 ? void 0 : (_vm$service$digitalAs = _vm$service.digitalAssetType) === null || _vm$service$digitalAs === void 0 ? void 0 : _vm$service$digitalAs.name) || "--"))])]), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "phone"
    }
  }, [_vm._v("Telefone do suporte")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("maskPhoneComplete")(_vm.service.phone)))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "mail"
    }
  }, [_vm._v("E-mail do suporte")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.service.email || "--"))])])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "dateValid"
    }
  }, [_vm._v("Versão válida até:")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v("11/06/2022")])]);
}];
render._withStripped = true;