"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rolesAllowedAdmin = exports.TYPE_USER_USER = exports.TYPE_USER_ADMIN = exports.TYPE_ITEM_DASHBOARD = exports.TYPES_USERS_IDS = exports.TYPES_USERS_ADMINS = exports.TYPES_USERS = exports.TYPES_TRANSACTIONAL_OPERATIONS = exports.TYPES_STATUS_TICKETS = exports.TYPES_STATUS_ONBOARDING = exports.TYPES_PERSONS = exports.TYPES_CODES_DOCUMENTOSCOPIA = exports.TYPES_BATCH_PROCESSING = exports.STATUS_PROCESS_BATCH_PROCESSING = exports.STATUS_CONSUMERS = exports.STATUS_APURATION = exports.OPTIONS_CHART_DEFAULT = exports.MAX_UPLOAD_FILE = exports.MAX_PRODUCT_NAME = exports.LIST_YEARS = exports.LIST_TYPES_USER_BRB = exports.LIST_TYPES_USERS = exports.LIST_TYPES_STATUS_SERVICES = exports.LIST_TYPES_REQUESTS = exports.LIST_TITLES = exports.LIST_STATUS_ONBOARDING = exports.LIST_PERSONS_CREDIT = exports.LIST_MONTHS = exports.LIST_GENDERS = exports.KEY_USER_LOCAL_STORAGE = exports.KEY_ACCESS_DATA_MENU_USER = exports.KEY_ACCESS_DATA_LOCAL_STORAGE = exports.KEY_ACCESS_DATA_COOKIE_USER = exports.C8_RESPONSE_VALIDATION_ERROR = exports.C8_CACHE_RESPONSE_CLIENT = exports.C7_RESPONSE_NOT_RECEIVED_CLIENT = exports.C6_RESPONSE_ERROR_SENT_CLIENT = exports.C5_RESPONSE_SENT_CLIENT_WEBHOOK = exports.C5_RESPONSE_SENT_CLIENT = exports.C4_RESPONSE_RECEIVED_PROVIDER_WEBHOOK = exports.C4_RESPONSE_RECEIVED_PROVIDER = exports.C3_REQUEST_ERROR_PROVIDER = exports.C2_REQUEST_CREATED_PROVIDER = exports.C1_REQUEST_CREATED_HIVE = void 0;
/**
 * KEY USAGE TO SAVE DATA
 */
var KEY_USER_LOCAL_STORAGE = exports.KEY_USER_LOCAL_STORAGE = 'BACKOFFICE-HIVEPLACE-PORTAL-V2';

/**
 * KEY USAGE TO SAVE DATA
 */
var KEY_ACCESS_DATA_LOCAL_STORAGE = exports.KEY_ACCESS_DATA_LOCAL_STORAGE = 'BACKOFFICE-HIVEPLACE-PORTAL-V2-ACCESS-DATA';

/**
 * KEY USAGE TO SAVE DATA
 */
var KEY_ACCESS_DATA_COOKIE_USER = exports.KEY_ACCESS_DATA_COOKIE_USER = 'dataUserHIVEPlace';

/**
 * KEY USAGE TO SAVE DATA MENU USER
 */
var KEY_ACCESS_DATA_MENU_USER = exports.KEY_ACCESS_DATA_MENU_USER = 'BACKOFFICE-HIVE-MENU-PORTAL';

/**
 * ROLES ADMIN
 */
var rolesAllowedAdmin = exports.rolesAllowedAdmin = ['ADMIN_HIVEPLACE'];

/**
 * Typo User
 */
var TYPE_USER_USER = exports.TYPE_USER_USER = 'USER';
var TYPE_USER_ADMIN = exports.TYPE_USER_ADMIN = 'ADMIN';
var STATUS_APURATION = exports.STATUS_APURATION = {
  UNAPPROVED: 'negado',
  PENDING: 'pendente',
  APPROVED: 'aprovado'
};
var MAX_PRODUCT_NAME = exports.MAX_PRODUCT_NAME = 50;

// block files larger than 2 MB
var MAX_UPLOAD_FILE = exports.MAX_UPLOAD_FILE = 2097152;

// Types persons
var TYPES_PERSONS = exports.TYPES_PERSONS = {
  'F': 'Pessoa física',
  'C': 'Pessoa física conjunta',
  'J': 'Pessoa jurídica',
  'E': 'EFPC',
  'U': 'Fundação',
  'G': 'PF Não Residente',
  'K': 'PJ Não Residente',
  'I': 'Fundo de Investimento',
  'D': 'Desconhecido'
};

// CONSUMERS
var STATUS_CONSUMERS = exports.STATUS_CONSUMERS = {
  'aprove': 1,
  'pending': 2,
  'reject': 3,
  'authorized': 4
};

/**
 * List types users
 */
var TYPES_USERS = exports.TYPES_USERS = {
  TI_HIVE: 'TI Hive',
  USER_ADMIN: 'Usuário Administrativo',
  USER_CLIENT: 'Usuário Cliente',
  USER_FINANCIAL_ADMIN: 'Usuário Financeiro BackOffice',
  USER_FINANCIAL_CLIENT: 'Usuário Financeiro Cliente',
  USER_CONSULT_PERSONAL: 'Consulta Pessoal',
  ONBORDING_GROUP: 'Grupo Onbording',
  PROVA_VIDA: 'Prova de Vida',
  ONBOARDING: 'Usuário Onboarding',
  USER_DIJUR: 'Usuário DIJUR',
  USER_PRESENTATION: 'Apresentação',
  USER_PROVIDER: 'Fornecedor',
  USER_PAINEL: 'Painel',
  USER_ADM_BRB: 'Usuário Administrativo BRB',
  USER_ADM_DIJUR: 'Usuário Administrativo Dijur'
};

/**
 * List types users
 */
var TYPES_USERS_IDS = exports.TYPES_USERS_IDS = {
  TI_HIVE: 'TIHive',
  USER_ADMIN: 'AdminBBTS',
  USER_CLIENT: 'Client',
  USER_FINANCIAL_ADMIN: 'FinanceiroBackOffice',
  USER_FINANCIAL_CLIENT: 'FinanceiroClient',
  USER_CONSULT_PERSONAL: 'PersonAnalise',
  USER_CONSULT_SUSPICIOUS_PROTOCOLS: 'ConsultSuspiciousProtocols',
  ONBORDING_GROUP: 'OnbordGroup',
  PROVA_VIDA: 'ProvaVida',
  ONBOARDING: 'Onboarding',
  USER_DIJUR: 'UserDIJUR',
  USER_PRESENTATION: 'Apresentacao',
  USER_PROVIDER: 'Fornecedor',
  USER_PAINEL: 'Painel',
  USER_ADM_BRB: 'UserAdminBRB',
  USER_ADM_DIJUR: 'UserAdmDIJUR'
};

/**
 * List types users admins
 */
var TYPES_USERS_ADMINS = exports.TYPES_USERS_ADMINS = [TYPES_USERS_IDS.TI_HIVE, TYPES_USERS_IDS.USER_ADMIN];

/**
 * List types users
 */
var LIST_TYPES_USERS = exports.LIST_TYPES_USERS = [{
  id: 1,
  idItem: TYPES_USERS_IDS.TI_HIVE,
  name: TYPES_USERS.TI_HIVE
}, {
  id: 2,
  idItem: TYPES_USERS_IDS.USER_CLIENT,
  name: TYPES_USERS.USER_CLIENT
}, {
  id: 3,
  idItem: TYPES_USERS_IDS.USER_ADMIN,
  name: TYPES_USERS.USER_ADMIN
}, {
  id: 4,
  idItem: TYPES_USERS_IDS.USER_FINANCIAL_ADMIN,
  name: TYPES_USERS.USER_FINANCIAL_ADMIN
}, {
  id: 6,
  idItem: TYPES_USERS_IDS.USER_FINANCIAL_CLIENT,
  name: TYPES_USERS.USER_FINANCIAL_CLIENT
}, {
  id: 7,
  idItem: TYPES_USERS_IDS.USER_CONSULT_PERSONAL,
  name: TYPES_USERS.USER_CONSULT_PERSONAL
}, {
  id: 8,
  idItem: TYPES_USERS_IDS.USER_CONSULT_SUSPICIOUS_PROTOCOLS,
  name: TYPES_USERS.USER_CONSULT_SUSPICIOUS_PROTOCOLS
}, {
  id: 9,
  idItem: TYPES_USERS_IDS.ONBORDING_GROUP,
  name: TYPES_USERS.ONBORDING_GROUP
}, {
  id: 10,
  idItem: TYPES_USERS_IDS.PROVA_VIDA,
  name: TYPES_USERS.PROVA_VIDA
}, {
  id: 11,
  idItem: TYPES_USERS_IDS.ONBOARDING,
  name: TYPES_USERS.ONBOARDING
}, {
  id: 12,
  idItem: TYPES_USERS_IDS.USER_DIJUR,
  name: TYPES_USERS.USER_DIJUR
}, {
  id: 13,
  idItem: TYPES_USERS_IDS.USER_PRESENTATION,
  name: TYPES_USERS.USER_PRESENTATION
}, {
  id: 14,
  idItem: TYPES_USERS_IDS.USER_PROVIDER,
  name: TYPES_USERS.USER_PROVIDER
}, {
  id: 16,
  idItem: TYPES_USERS_IDS.USER_PAINEL,
  name: TYPES_USERS.USER_PAINEL
}, {
  id: 17,
  idItem: TYPES_USERS_IDS.USER_ADM_BRB,
  name: TYPES_USERS.USER_ADM_BRB
}, {
  id: 18,
  idItem: TYPES_USERS_IDS.USER_ADM_DIJUR,
  name: TYPES_USERS.USER_ADM_DIJUR
}];
var LIST_TYPES_USER_BRB = exports.LIST_TYPES_USER_BRB = [{
  id: 2,
  idItem: TYPES_USERS_IDS.USER_CLIENT,
  name: TYPES_USERS.USER_CLIENT
}, {
  id: 10,
  idItem: TYPES_USERS_IDS.PROVA_VIDA,
  name: TYPES_USERS.PROVA_VIDA
}, {
  id: 17,
  idItem: TYPES_USERS_IDS.USER_ADM_BRB,
  name: TYPES_USERS.USER_ADM_BRB
}];

/**
 * List types SERVICES
 */
var LIST_TYPES_STATUS_SERVICES = exports.LIST_TYPES_STATUS_SERVICES = [{
  id: 1,
  name: 'Disponível'
}, {
  id: 2,
  name: 'Solicitado'
}, {
  id: 3,
  name: 'Autorizado'
}, {
  id: 4,
  name: 'Rejeitado'
}];

/**
 * List types requests
 */
var LIST_TYPES_REQUESTS = exports.LIST_TYPES_REQUESTS = [
// { id: 1, idItem: '.resp', name: TYPES_USERS.TI_HIVE },
{
  id: 3,
  idItem: '.resp',
  name: 'Abertura Provedor'
}, {
  id: 2,
  idItem: '.get',
  name: 'GET'
}];

/**
 * List MONTHS
 */
var LIST_MONTHS = exports.LIST_MONTHS = [{
  id: '01',
  name: 'Janeiro'
}, {
  id: '02',
  name: 'Fevereiro'
}, {
  id: '03',
  name: 'Março'
}, {
  id: '04',
  name: 'Abril'
}, {
  id: '05',
  name: 'Maio'
}, {
  id: '06',
  name: 'Junho'
}, {
  id: '07',
  name: 'Julho'
}, {
  id: '08',
  name: 'Agosto'
}, {
  id: '09',
  name: 'Setembro'
}, {
  id: '10',
  name: 'Outubro'
}, {
  id: '11',
  name: 'Novembro'
}, {
  id: '12',
  name: 'Dezembro'
}];

/**
 * List Years
 */
var LIST_YEARS = exports.LIST_YEARS = [{
  id: '2021',
  name: '2021'
}, {
  id: '2022',
  name: '2022'
}, {
  id: '2023',
  name: '2023'
}, {
  id: '2024',
  name: '2024'
}, {
  id: '2025',
  name: '2025'
}];

/**
 * List Gender
 */
var LIST_GENDERS = exports.LIST_GENDERS = [{
  id: 'F',
  name: 'Feminino'
}, {
  id: 'M',
  name: 'Masculino'
}, {
  id: 'I',
  name: 'Outro'
}];

/**
 * OPTIONS CHART
 */
var OPTIONS_CHART_DEFAULT = exports.OPTIONS_CHART_DEFAULT = {
  responsive: true,
  maintainAspectRatio: true,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false
  },
  scales: {
    y: {
      display: true,
      title: {
        display: true
      }
    },
    x: {
      display: true
    }
  },
  plugins: {
    legend: {
      display: true,
      usePointStyle: true,
      position: 'bottom',
      align: 'start',
      fill: true,
      labels: {
        font: {
          size: 12,
          family: 'Arial'
        },
        padding: 20,
        usePointStyle: true,
        pointStyle: 'rectRounded'
      }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    tooltip: {
      usePointStyle: true,
      mode: 'nearest',
      intersect: true,
      callbacks: {}
    }
  },
  elements: {
    point: {
      radius: 0
    }
  }
};

/**
 * LIST TITLES
 */
var LIST_TITLES = exports.LIST_TITLES = [{
  host: 'localhost',
  title: 'HivePlace - Localhost'
}, {
  host: 'dev.client.hiveplace.com.br',
  title: 'HivePlace - Client'
}, {
  host: 'hom.client.hiveplace.com.br',
  title: 'HivePlace - Client'
}, {
  host: 'preprod.client.hiveplace.com.br',
  title: 'HivePlace - Client'
}, {
  host: 'client.hiveplace.com.br',
  title: 'HivePlace - Client'
}, {
  host: 'dev.backoffice.hiveplace.com.br',
  title: 'HivePlace - BackOffice'
}, {
  host: 'hom.BackOffice.hiveplace.com.br',
  title: 'HivePlace - BackOffice'
}, {
  host: 'preprod.BackOffice.hiveplace.com.br',
  title: 'HivePlace - BackOffice'
}, {
  host: 'BackOffice.hiveplace.com.br',
  title: 'HivePlace - BackOffice'
}];

/**
 * LIST_PERSONS_CREDIT
 */
var LIST_PERSONS_CREDIT = exports.LIST_PERSONS_CREDIT = [{
  type: 'QUEM SOU EU',
  description: 'Pessoas sem histórico de crédito, que possuem somente um histórico demográfico.'
}, {
  type: 'O BEM AMADO',
  description: 'É o público mais assediado pelas empresas, devido seu histórico de crédito impecável.'
}, {
  type: 'FUJAM DE MIM',
  description: 'A sub-classificação mais delicada, que possuem um histórico ruim devido aos inúmeros compromissos sem pagamento.'
}, {
  type: 'PAGO QUANDO PUDER',
  description: 'Pessoas cujo histórico de crédito denunciam uma instabilidade no cumprimento de suas obrigações.'
}, {
  type: 'SEMPRE PRESENTE',
  description: 'Apesar de bom é uma sub-classificação vulnerável às interpretações de crédito do mercado.'
}, {
  type: 'NOVOS ENTRANTES',
  description: 'Agrupa jovens que não possuem histórico de crédito ou adultos que estão tirando cpf recentemente e que por conta disso, não podem ser classificadas.'
}];

/**
 * List types operation transactions
 */
var TYPES_TRANSACTIONAL_OPERATIONS = exports.TYPES_TRANSACTIONAL_OPERATIONS = {
  C1_REQUEST_CREATED_HIVE: 'Criado no Hive',
  C2_REQUEST_CREATED_PROVIDER: 'Requisição criada no provedor',
  C3_REQUEST_ERROR_PROVIDER: 'Erro na requisição ao provedor',
  C4_RESPONSE_RECEIVED_PROVIDER: 'Resposta recebida do provedor',
  C5_RESPONSE_SENT_CLIENT: 'Resposta enviada ao cliente',
  C6_RESPONSE_ERROR_SENT_CLIENT: 'Erro enviado ao cliente',
  C7_RESPONSE_NOT_RECEIVED_CLIENT: 'Erro ao enviar para o cliente',
  C8_CACHE_RESPONSE_CLIENT: 'GET Cache',
  C8_RESPONSE_VALIDATION_ERROR: 'Smart GET - ERRO',
  C4_RESPONSE_RECEIVED_PROVIDER_WEBHOOK: 'Resposta recebida do provedor (Webhook)',
  C5_RESPONSE_SENT_CLIENT_WEBHOOK: 'Resposta enviada ao cliente (Webhook)'
};

// Status Protocols
var C1_REQUEST_CREATED_HIVE = exports.C1_REQUEST_CREATED_HIVE = 'C1_REQUEST_CREATED_HIVE';
var C2_REQUEST_CREATED_PROVIDER = exports.C2_REQUEST_CREATED_PROVIDER = 'C2_REQUEST_CREATED_PROVIDER';
var C3_REQUEST_ERROR_PROVIDER = exports.C3_REQUEST_ERROR_PROVIDER = 'C3_REQUEST_ERROR_PROVIDER';
var C4_RESPONSE_RECEIVED_PROVIDER = exports.C4_RESPONSE_RECEIVED_PROVIDER = 'C4_RESPONSE_RECEIVED_PROVIDER';
var C5_RESPONSE_SENT_CLIENT = exports.C5_RESPONSE_SENT_CLIENT = 'C5_RESPONSE_SENT_CLIENT';
var C6_RESPONSE_ERROR_SENT_CLIENT = exports.C6_RESPONSE_ERROR_SENT_CLIENT = 'C6_RESPONSE_ERROR_SENT_CLIENT';
var C7_RESPONSE_NOT_RECEIVED_CLIENT = exports.C7_RESPONSE_NOT_RECEIVED_CLIENT = 'C7_RESPONSE_NOT_RECEIVED_CLIENT';
var C8_CACHE_RESPONSE_CLIENT = exports.C8_CACHE_RESPONSE_CLIENT = 'C8_CACHE_RESPONSE_CLIENT';
var C8_RESPONSE_VALIDATION_ERROR = exports.C8_RESPONSE_VALIDATION_ERROR = 'C8_RESPONSE_VALIDATION_ERROR';
var C4_RESPONSE_RECEIVED_PROVIDER_WEBHOOK = exports.C4_RESPONSE_RECEIVED_PROVIDER_WEBHOOK = 'C4_RESPONSE_RECEIVED_PROVIDER_WEBHOOK';
var C5_RESPONSE_SENT_CLIENT_WEBHOOK = exports.C5_RESPONSE_SENT_CLIENT_WEBHOOK = 'C5_RESPONSE_SENT_CLIENT_WEBHOOK';

/**
 * List types status onboarding
 */
var TYPES_STATUS_ONBOARDING = exports.TYPES_STATUS_ONBOARDING = {
  'started': 'PROCESSO INICIADO',
  'completed': 'PROCESSO COMPLETO',
  'canceled': 'PROCESSO CANCELADO',
  'downloaded': 'DOWNLOAD CONCLUÍDO'
};

/**
 * List types status onboarding
 */
var LIST_STATUS_ONBOARDING = exports.LIST_STATUS_ONBOARDING = [{
  id: 'started',
  name: 'PROCESSO INICIADO'
}, {
  id: 'completed',
  name: 'PROCESSO COMPLETO'
}, {
  id: 'canceled',
  name: 'PROCESSO CANCELADO'
}, {
  id: 'downloaded',
  name: 'DOWNLOAD CONCLUÍDO'
}];

/**
 * List types codes documentoscopia
 */
var TYPES_CODES_DOCUMENTOSCOPIA = exports.TYPES_CODES_DOCUMENTOSCOPIA = [{
  id: 0,
  description: 'SEM RISCO NO DOCUMENTO'
}, {
  id: 1,
  description: 'SEM RISCO NO DOCUMENTO - CPF POSSUI HISTÓRICO DE VITIMA DE FRAUDE'
}, {
  id: 2,
  description: 'COM RISCO NO DOCUMENTO - FRAUDE'
}, {
  id: 3,
  description: 'DOCUMENTO NÃO PASSÍVEL DE ANÁLISE - CPF QUE ESTÁ NO DOCUMENTO ESTÁ DIVERGENTE DO INFORMADO PELO CLIENTE'
}, {
  id: 5,
  description: 'DOCUMENTO NÃO PASSÍVEL DE ANÁLISE - DOCUMENTO DE IDENTIFICAÇÃO AUSENTE'
}, {
  id: 7,
  description: 'DOCUMENTO NÃO PASSÍVEL DE ANÁLISE - DOCUMENTO DE IDENTIFICAÇÃO É UMA FOTOCOPIA/XEROX'
}, {
  id: 8,
  description: 'DOCUMENTO NÃO PASSÍVEL DE ANÁLISE - ANÁLISE DO DOCUMENTO INCONCLUSIVO'
}, {
  id: 10,
  description: 'DOCUMENTO NÃO PASSÍVEL DE ANÁLISE - DOCUMENTO ESTÁ INCOMPLETO OU ILEGÍVEL'
}, {
  id: 11,
  description: 'SEM RISCO DOCUMENTO  - VALIDAR APONTAMENTO CPF'
}, {
  id: 14,
  description: 'SEM RISCO - ANALFABETO'
}, {
  id: 12,
  description: 'INVESTIGAR'
}, {
  id: 99,
  description: 'ERRO DE PROCESSAMENTO'
}];

/**
 * List types batch processing
 */
var TYPES_BATCH_PROCESSING = exports.TYPES_BATCH_PROCESSING = [{
  id: 1,
  name: 'Enriquecimento N: PF ou PJ'
}, {
  id: 2,
  name: 'Enriquecimento P: PF Básico'
}, {
  id: 3,
  name: 'Enriquecimento P: PF Plus'
}, {
  id: 4,
  name: 'Enriquecimento P: PF Master'
}, {
  id: 5,
  name: 'Enriquecimento P: PJ Básico'
}, {
  id: 6,
  name: 'Enriquecimento P: PJ Master'
}, {
  id: 7,
  name: 'Enriquecimento Q: PF'
}, {
  id: 8,
  name: 'Enriquecimento Q: PJ'
}, {
  id: 9,
  name: 'Score Collection Q + Persona de Cobrança Q'
}, {
  id: 10,
  name: 'Indicador de Negócio Q: PF'
}, {
  id: 11,
  name: 'Indicador de Negócio Q: PJ'
}, {
  id: 12,
  name: 'Credito Bureau Cadastro Sintético S'
}, {
  id: 13,
  name: 'Score com Positivo S'
}, {
  id: 14,
  name: 'Capacidade de Pagamento S'
}, {
  id: 15,
  name: 'Pontualidade de Pagamento S'
}, {
  id: 16,
  name: 'Indicador de Restrições e Pagamentos S'
}, {
  id: 17,
  name: 'Indicador Histórico de Atraso e Faturas em Aberto S'
}, {
  id: 18,
  name: 'Tendência de Uso de Cartão de Credito S'
}, {
  id: 19,
  name: 'Collection Scoring 2.0 S'
}, {
  id: 20,
  name: 'Relato Sintético S'
}, {
  id: 21,
  name: 'Score Empresas 6M Positivo S'
}, {
  id: 22,
  name: 'Collection Scoring PJ S'
}, {
  id: 23,
  name: 'Faturamento Estimado com Positivo'
}, {
  id: 24,
  name: 'Comportamento Pagamento Setor S Rec Relato S'
}, {
  id: 25,
  name: 'Quadro Social e Administracao - Via Relato S'
}, {
  id: 26,
  name: 'Participações - Via Relato S'
}, {
  id: 27,
  name: 'Cadastro e Categorização (Dados Básicos) B'
}, {
  id: 28,
  name: 'Precificação B'
}, {
  id: 29,
  name: 'Disponibilidade B'
}, {
  id: 30,
  name: 'Reviews e Opiniões B'
}, {
  id: 31,
  name: 'Recomendações e Produtos Relacionados B'
}, {
  id: 32,
  name: 'Detalhamentos Técnicos B'
}, {
  id: 33,
  name: 'Imagens B'
}, {
  id: 34,
  name: 'Condições de Entrega B'
}];

/**
 * STATUS_PROCESS_BATCH_PROCESSING
 */
var STATUS_PROCESS_BATCH_PROCESSING = exports.STATUS_PROCESS_BATCH_PROCESSING = [{
  status: 'awaiting_process',
  description: 'Aguardando processamento'
}, {
  status: 'file_send',
  description: 'Arquivo enviado'
}, {
  status: 'file_processing',
  description: 'Em processamento'
}, {
  status: 'processed_file',
  description: 'Arquivo processado'
}, {
  status: 'error_file',
  description: 'Erro no arquivo'
}, {
  status: 'error_process',
  description: 'Erro no processo'
}, {
  status: 'file_conference',
  description: 'Conferência'
}, {
  status: 'upload_fail',
  description: 'Falha no upload do arquivo'
}];

/**
 * TYPES_STATUS_TICKETS
 */
var TYPES_STATUS_TICKETS = exports.TYPES_STATUS_TICKETS = [{
  status: 'OPEN',
  description: 'Aberto'
}, {
  status: 'IN_PROGRESS',
  description: 'Em progresso'
}, {
  status: 'CLOSED',
  description: 'Finalizado'
}, {
  status: 'RESOLVED',
  description: 'Resolvido'
}];
var TYPE_ITEM_DASHBOARD = exports.TYPE_ITEM_DASHBOARD = {
  RECEPTIONIST: 'receptionist',
  MONITORING: 'monitoring',
  TRANSACTIONS: 'transactions'
};