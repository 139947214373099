"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-service-detail"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-xxl-3"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v(" "), _c("img", {
    attrs: {
      src: require("@/assets/images/logos/logo-default.png"),
      alt: "logo"
    }
  }), _vm._v("\n              " + _vm._s(_vm.detail.name) + "\n              "), _vm.detail.Version ? _c("span", {
    staticClass: "detail-version"
  }, [_vm._v("v2.3")]) : _vm._e()])])])])])]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 col-lg-7 col-descriptions"
  }, [_c("div", {
    staticClass: "content-descriptions"
  }, [_c("h3", [_vm._v("Informações")]), _vm._v(" "), _c("p", [_vm._v("\n          " + _vm._s(_vm.detail.description) + "\n        ")])]), _vm._v(" "), _c("div", {
    staticClass: "content-certificates"
  }, [_c("h3", [_vm._v("Certificados necessários")]), _vm._v(" "), _c("div", {
    staticClass: "list-certs"
  }, [_c("card-certificate", {
    attrs: {
      title: "BSS",
      logo: require("@/assets/images/certificates/certificate-star.png")
    }
  }), _vm._v(" "), _c("card-certificate", {
    attrs: {
      title: "GDPR",
      logo: require("@/assets/images/certificates/certificate-exin.png")
    }
  }), _vm._v(" "), _c("card-certificate", {
    attrs: {
      title: "PCI",
      logo: require("@/assets/images/certificates/certificate-pci.png")
    }
  }), _vm._v(" "), _c("card-certificate", {
    attrs: {
      title: "LGPD",
      logo: require("@/assets/images/certificates/certificate-dpo.png")
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "col-md-12 col-lg-5 col-details"
  }, [_c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component card-sandbox"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("SLA: " + _vm._s(_vm.detail.Sla))]), _vm._v(" "), _c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Custo: R$ " + _vm._s(_vm.detail.Coast || "--") + " / Requisição")]), _vm._v(" "), _c("a", {
    staticClass: "detail",
    attrs: {
      href: "#",
      target: "_blank",
      rel: "noopener noreferrer"
    }
  }, [_vm._v("\n            Testar em sandbox\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Nome do serviço")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.detail.Name))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "service"
    }
  }, [_vm._v("Tipo de serviço")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.detail.Type || "--"))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "dateValid"
    }
  }, [_vm._v("Versão válida até:")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.detail.ValidDate || "--"))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "phone"
    }
  }, [_vm._v("Telefone do suporte")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("maskPhone")(_vm.detail.Phone)))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "mail"
    }
  }, [_vm._v("E-mail do suporte")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.detail.Email || "--"))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "mail"
    }
  }, [_vm._v("Criado em " + _vm._s(_vm.detail.CreateDate || "--"))])])])]), _vm._v(" "), _c("div", {
    staticClass: "col-12 col-apis-list"
  }, [_c("h3", [_vm._v("\n        APIs complementares\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-4"
  }, [_c("card-monitor", {
    attrs: {
      title: "Service name",
      logo: "logo-default.png",
      service: "TED-Ivox 2.3",
      cost: "2,00"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openDetail(1);
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "col-md-4"
  }, [_c("card-monitor", {
    attrs: {
      title: "Service name",
      logo: "logo-default.png",
      service: "TED-Ivox 2.3",
      cost: "2,00"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.openDetail(2);
      }
    }
  })], 1)])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;