"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-list-services-types-component"
  }, [_vm.list && _vm.list.length > 0 ? _c("div", [_c("div", {
    staticClass: "list-itens-informations"
  }, [_vm._m(0), _vm._v(" "), _vm._l(_vm.list, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "body",
      on: {
        click: function click($event) {
          return _vm.actionItem(item);
        }
      }
    }, [_c("div", {
      staticClass: "one"
    }, [_vm._v(_vm._s(item.name))]), _vm._v(" "), _c("div", {
      staticClass: "two"
    }, [_vm._v(_vm._s(item.description || "--"))])]);
  })], 2)]) : _vm._e(), _vm._v(" "), !_vm.list || !_vm.list.length ? _c("div", [_c("h1", {
    staticClass: "empty-title"
  }, [_vm._v("\n      Sem dados no momento\n    ")])]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "one"
  }, [_vm._v("Nome")]), _vm._v(" "), _c("div", {
    staticClass: "two"
  }, [_vm._v("Descrição")])]);
}];
render._withStripped = true;