"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("@/components");
var _auth = require("@/utils/auth");
var _authGuard = require("@/auth/authGuard");
var _default2 = exports.default = {
  name: 'TableListBatchProcessing',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    actionOpen: {
      type: Function,
      default: function _default() {}
    },
    service: {
      type: String,
      default: ''
    },
    getFiles: {
      type: Function,
      default: function _default() {}
    }
  },
  computed: {
    user: function user() {
      return (0, _auth.getUser)().user;
    },
    isAdmin: function isAdmin() {
      return (0, _authGuard.isUserAdmin)();
    }
  },
  methods: {
    openModalAction: function openModalAction(data) {
      this.$buefy.modal.open({
        parent: this,
        component: _components.ModalEditBatchProcessing,
        hasModalCard: true,
        customClass: 'modal-edit-batch-processing-component',
        trapFocus: true,
        canCancel: ['escape', 'outside'],
        props: {
          typeFile: 'text/csv,.tar.gz,image/*,.pdf,.zip,.rar,.7zip',
          dataFile: data,
          reload: this.getFiles
        }
      });
    }
  }
};