"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DataForm = _interopRequireDefault(require("./components/DataForm"));
var _ServicesAuthorized = _interopRequireDefault(require("./components/ServicesAuthorized"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  name: 'ClientForm',
  bodyClass: 'business-unit-form-page',
  components: {
    DataForm: _DataForm.default,
    ServicesAuthorized: _ServicesAuthorized.default
  },
  data: function data() {
    return {
      activeStep: 0,
      finished: false,
      steps: [{
        label: 'Dados'
      }, {
        label: 'Serviços autorizados'
      }]
    };
  },
  methods: {
    setTab: function setTab(index) {
      this.activeStep = index;
    },
    next: function next(form) {
      if (this.activeStep++ > 4) this.activeStep = 0;
    },
    goBack: function goBack() {
      this.$router.back();
    }
  }
};