"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'ListMessages',
  components: {},
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      messages: []
    };
  },
  created: function created() {},
  methods: {}
};