"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Register',
  bodyClass: 'gravame-register-dashboard-page',
  components: {},
  data: function data() {
    return {
      url: 'https://app.stage.single.tec.br/veiculo/#/contratos/cadastrar',
      idService: null,
      service: {}
    };
  },
  methods: {
    goBack: function goBack() {
      this.$router.back();
    }
  }
};