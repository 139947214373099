"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CardComponent = _interopRequireDefault(require("@/components/CardComponent"));
var _GrowingNumber = _interopRequireDefault(require("@/components/GrowingNumber"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  name: 'CardWidget',
  components: {
    GrowingNumber: _GrowingNumber.default,
    CardComponent: _CardComponent.default
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    number: {
      type: [Number, String],
      default: 0
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    isReal: {
      type: Boolean,
      default: false
    },
    errorPercent: {
      type: [String, Number],
      default: null
    },
    load: {
      type: Boolean,
      default: false
    }
  }
};