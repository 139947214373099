"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'CardMonitor',
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    milliseconds: {
      type: [String, Number],
      default: 0
    },
    request: {
      type: [String, Number],
      default: 0
    },
    logo: {
      type: String,
      default: ''
    },
    service: {
      type: [String, Number],
      default: ''
    },
    cost: {
      type: [String, Number],
      default: ''
    },
    active: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    noClick: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openDetail: function openDetail() {
      this.$emit('action');
    }
  }
};