"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card modal-warning-component"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Deseja executar está ação?")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancel
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("div", [_vm._v("\n      " + _vm._s(_vm.text) + " "), _vm.name ? _c("b", [_vm._v(" " + _vm._s(_vm.name || "") + " ")]) : _vm._e(), _vm._v("?\n    ")]), _vm._v(" "), _c("div", {
    staticClass: "content-btns"
  }, [_c("b-button", {
    attrs: {
      type: _vm.type,
      rounded: ""
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v("\n        " + _vm._s(_vm.textConfirm) + "\n      ")]), _vm._v(" "), _c("b-button", {
    attrs: {
      type: "is-info",
      rounded: ""
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("\n        Cancelar\n      ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;