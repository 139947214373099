"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestCodeInfo = requestCodeInfo;
function requestCodeInfo(code) {
  var response = {};
  switch (code) {
    case '200':
      response = {
        code: code,
        error: false,
        message: 'Ok'
      };
      return response;
    case '201':
      response = {
        code: code,
        error: false,
        message: 'Created'
      };
      return response;
    case '400':
      response = {
        code: code,
        error: true,
        message: 'Bad Request'
      };
      return response;
    case '401':
      response = {
        code: code,
        error: true,
        message: 'Unauthorized'
      };
      return response;
    case '402':
      response = {
        code: code,
        error: true,
        message: 'Payment Required'
      };
      return response;
    case '403':
      response = {
        code: code,
        error: true,
        message: 'Forbidden'
      };
      return response;
    case '404':
      response = {
        code: code,
        error: true,
        message: 'Not Found'
      };
      return response;
    case '422':
      response = {
        code: code,
        error: true,
        message: 'Unprocessable Content'
      };
      return response;
    case '423':
      response = {
        code: code,
        error: true,
        message: 'Locked'
      };
      return response;
    case '429':
      response = {
        code: code,
        error: true,
        message: 'To Many Requests'
      };
      return response;
    case '500':
      response = {
        code: code,
        error: true,
        message: 'Internal Server Error'
      };
      return response;
    case '502':
      response = {
        code: code,
        error: true,
        message: 'Bad Gateway'
      };
      return response;
    case '504':
      response = {
        code: code,
        error: true,
        message: 'Getway Timeout'
      };
      return response;
    case '529':
      response = {
        code: code,
        error: true,
        message: 'Overloaded Service'
      };
      return response;
  }
}