"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "modal-card"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Notificações")]), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_vm.listAlert.length === 0 && !_vm.isLoading ? _c("not-found", {
    attrs: {
      text: "Nenhuma notificação encontrada"
    }
  }) : _vm._e(), _vm._v(" "), _vm.isLoading ? _c("p", [_vm._v("Carregando....")]) : _c("div", _vm._l(_vm.listAlert, function (alert) {
    return _c("b-notification", {
      key: alert.id,
      attrs: {
        closable: false,
        "aria-close-label": "Close notification"
      },
      on: {
        click: function click($event) {
          return _vm.openDialogNotificationBody(alert);
        }
      }
    }, [_c("p", [_vm._v(_vm._s(alert.subject))])]);
  }), 1)], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;