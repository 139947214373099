"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-funds-table-components"
  }, [_c("div", {
    staticClass: "list-doc list-header"
  }, [_c("div", {
    staticClass: "col--one"
  }, [_vm._v("Fundo")]), _vm._v(" "), _c("div", {
    staticClass: "col--two"
  }, [_vm._v("CNPJ")]), _vm._v(" "), !_vm.hideActions ? _c("div", {
    staticClass: "col--three"
  }, [_vm._v("Ações")]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "list-content"
  }, _vm._l(_vm.funds, function (document, idx) {
    return _c("div", {
      key: idx,
      staticClass: "list-doc list-item"
    }, [_c("div", {
      staticClass: "col--one"
    }, [_c("h3", [_vm._v(_vm._s(document.person.personName))])]), _vm._v(" "), _c("div", {
      staticClass: "col--two"
    }, [_c("h4", [_vm._v(_vm._s(document.person.cnpjCpf))])]), _vm._v(" "), !_vm.hideActions ? _c("div", {
      staticClass: "col--three"
    }, [_c("b-button", {
      class: {
        checked: !_vm.documentCheck(document)
      },
      attrs: {
        rounded: ""
      },
      on: {
        click: function click($event) {
          return _vm.addDocumentList(document);
        }
      }
    }, [_vm._v("\n          " + _vm._s(_vm.documentCheck(document) ? "Adicionar" : "Selecionado") + "\n        ")])], 1) : _vm._e()]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;