"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-users-list"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Protocolos suspeitos")]), _vm._v(" "), _c("div", [_c("b-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("b-tab-item", {
    attrs: {
      label: "Protocolos novos"
    }
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "flex-container space-between"
  }, [_c("div", {
    staticClass: "card-table-title"
  }, [_vm.suspiciousProtocols.notDownloaded.allItens.length === 0 ? [_vm._v("\n                  Nenhum protocolo novo\n                ")] : _vm.suspiciousProtocols.notDownloaded.allItens.length === 1 ? [_vm._v("\n                  1 protocolo novo\n                ")] : _vm._e(), _vm._v(" "), [_vm._v("\n                  " + _vm._s(_vm.suspiciousProtocols.notDownloaded.allItens.length) + " protocolos novos\n                ")]], 2), _vm._v(" "), _c("div", [_c("div", {
    staticClass: "flex-container"
  }, [_c("div", [_vm.suspiciousProtocols.notDownloaded.checkedItens.length ? _c("b-button", {
    attrs: {
      type: "is-success",
      "icon-right": "download"
    },
    on: {
      click: function click($event) {
        return _vm.downloadProtocols({
          type: "notDownloaded"
        });
      }
    }
  }, [_vm._v("\n                      Baixar " + _vm._s(_vm.suspiciousProtocols.notDownloaded.checkedItens.length) + " " + _vm._s(_vm.suspiciousProtocols.notDownloaded.checkedItens.length > 1 ? "protocolos" : "protocolo") + "\n                    ")]) : _vm._e()], 1), _vm._v(" "), _c("div", [_vm.suspiciousProtocols.notDownloaded.checkedItens.length === 0 && _vm.suspiciousProtocols.notDownloaded.allItens.length > 0 ? _c("b-button", {
    attrs: {
      type: "is-success",
      "icon-right": "download"
    },
    on: {
      click: function click($event) {
        return _vm.downloadProtocols({
          type: "notDownloaded",
          all: true
        });
      }
    }
  }, [_vm._v("\n                      Baixar Todos\n                    ")]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "ml-4"
  }, [_c("b-select", {
    model: {
      value: _vm.suspiciousProtocols.notDownloaded.qtyPerPage,
      callback: function callback($$v) {
        _vm.$set(_vm.suspiciousProtocols.notDownloaded, "qtyPerPage", $$v);
      },
      expression: "suspiciousProtocols.notDownloaded.qtyPerPage"
    }
  }, [_c("option", {
    attrs: {
      value: "10"
    }
  }, [_vm._v("10 por página")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "20"
    }
  }, [_vm._v("20 por página")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "50"
    }
  }, [_vm._v("50 por página")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "100"
    }
  }, [_vm._v("100 por página")])])], 1)])])])]), _vm._v(" "), _vm.suspiciousProtocols.notDownloaded.allItens.length ? _c("b-table", {
    ref: "tblSuspiciousProtocolsNotDownloaded",
    attrs: {
      striped: "",
      loading: _vm.suspiciousProtocols.notDownloaded.isLoading,
      "sort-icon": _vm.sortIcon,
      "sort-icon-size": _vm.sortIconSize,
      data: _vm.suspiciousProtocols.notDownloaded.allItens,
      paginated: "",
      "aria-page-label": "Página",
      "aria-next-label": "Próxima página",
      "aria-current-label": "Página atual",
      "aria-previous-label": "Página anterior",
      "per-page": _vm.suspiciousProtocols.notDownloaded.qtyPerPage,
      checkable: "",
      "checkbox-type": "is-info",
      "checkbox-position": "left",
      "checked-rows": _vm.suspiciousProtocols.notDownloaded.checkedItens,
      detailed: "",
      "detail-key": "key",
      "detail-transition": "fade",
      "show-detail-icon": true
    },
    on: {
      "update:data": function updateData($event) {
        return _vm.$set(_vm.suspiciousProtocols.notDownloaded, "allItens", $event);
      },
      "update:checkedRows": function updateCheckedRows($event) {
        return _vm.$set(_vm.suspiciousProtocols.notDownloaded, "checkedItens", $event);
      },
      "update:checked-rows": function updateCheckedRows($event) {
        return _vm.$set(_vm.suspiciousProtocols.notDownloaded, "checkedItens", $event);
      },
      "details-open": _vm.showProtocolDetails
    },
    scopedSlots: _vm._u([{
      key: "detail",
      fn: function fn(props) {
        return [_vm.suspiciousProtocols.detailed.hasOwnProperty(props.row.key) ? [_c("div", {
          staticClass: "columns"
        }, [_c("div", {
          staticClass: "column"
        }, [_c("strong", [_vm._v("Informações do protocolo")]), _c("br"), _vm._v(" "), _c("strong", [_vm._v("Data da requisição:")]), _vm._v(" " + _vm._s(_vm._f("dateBrazil")(_vm.suspiciousProtocols.detailed[props.row.key].requestDate))), _c("br"), _vm._v(" "), _c("strong", [_vm._v("Protocolo HivePlace:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].hiveProtocol)), _c("br"), _vm._v(" "), _c("strong", [_vm._v("Protocolo Cliente:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].clientProtocol) + "\n                  ")]), _vm._v(" "), _c("div", {
          staticClass: "column"
        }, [_c("br"), _vm._v(" "), _c("strong", [_vm._v("Serviço:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].service)), _c("br"), _vm._v(" "), _c("strong", [_vm._v("Path request:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].pathRequest)), _c("br"), _vm._v(" "), _c("strong", [_vm._v("Origem:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].consumer) + "\n                  ")])]), _vm._v(" "), _c("hr"), _vm._v(" "), _c("div", {
          staticClass: "columns"
        }, [_c("div", {
          staticClass: "column"
        }, [_vm.suspiciousProtocols.detailed[props.row.key].image ? _c("img", {
          staticClass: "user-img",
          attrs: {
            src: "data:image/jpeg;base64,".concat(_vm.suspiciousProtocols.detailed[props.row.key].image)
          }
        }) : _vm._e()])])] : [_c("div", {
          staticClass: "flex-container flex-start"
        }, [_c("div", {
          staticClass: "mr-5",
          staticStyle: {
            width: "580px"
          }
        }, [_c("b-skeleton", {
          attrs: {
            animated: "",
            width: "20%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: "",
            width: "80%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: "",
            width: "40%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: ""
          }
        })], 1), _vm._v(" "), _c("div", {
          staticStyle: {
            width: "380px"
          }
        }, [_c("b-skeleton", {
          attrs: {
            animated: "",
            width: "50%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: "",
            width: "40%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: "",
            width: "65%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: ""
          }
        })], 1)]), _vm._v(" "), _c("p", {
          staticClass: "mt-2 pt-2",
          staticStyle: {
            "border-top": "1px solid #dcdcdc",
            "font-style": "italic"
          }
        }, [_c("b-skeleton", {
          attrs: {
            animated: "",
            width: "50%"
          }
        })], 1)]];
      }
    }], null, false, 2595464746)
  }, [_c("b-table-column", {
    attrs: {
      sortable: "",
      width: "310",
      field: "date",
      label: "Data"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", [_vm._v(_vm._s(_vm._f("dateBrazil")(props.row.date)))])];
      }
    }], null, false, 3432072721)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      sortable: "",
      field: "service",
      label: "Serviço"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "protocol-number-in-table"
        }, [_vm._v(_vm._s(_vm._f("toUpperCase")(props.row.service)))])];
      }
    }], null, false, 321429288)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      width: "470",
      field: "key",
      label: "Protocolo Hive"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "protocol-number-in-table"
        }, [_vm._v(_vm._s(props.row.key))])];
      }
    }], null, false, 1743367734)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      width: "470",
      field: "transaction-id-client",
      label: "Protocolo Cliente"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "protocol-number-in-table"
        }, [_vm._v(_vm._s(props.row["transaction-id-client"]))])];
      }
    }], null, false, 533832473)
  })], 1) : _c("el-empty", {
    attrs: {
      description: "Nenhum protocolo suspeito até o momento"
    }
  })], 1)], 1), _vm._v(" "), _c("b-tab-item", {
    attrs: {
      label: "Protocolos baixados"
    }
  }, [_c("el-card", {
    staticClass: "box-card",
    attrs: {
      id: "card-downloaded-protocols"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "flex-container space-between"
  }, [_c("div", {
    staticClass: "card-table-title"
  }, [_vm.suspiciousProtocols.downloaded.allItens.length === 0 ? [_vm._v("\n                  Nenhum protocolo baixado\n                ")] : _vm.suspiciousProtocols.downloaded.allItens.length === 1 ? [_vm._v("\n                  1 protocolo baixado\n                ")] : _vm._e(), _vm._v(" "), [_vm._v("\n                  " + _vm._s(_vm.suspiciousProtocols.downloaded.allItens.length) + " protocolos baixados\n                ")]], 2), _vm._v(" "), _c("div", [_c("div", {
    staticClass: "flex-container"
  }, [_c("div", [_vm.suspiciousProtocols.downloaded.checkedItens.length ? _c("b-button", {
    attrs: {
      type: "is-success",
      "icon-right": "download"
    },
    on: {
      click: function click($event) {
        return _vm.downloadProtocols({
          type: "downloaded"
        });
      }
    }
  }, [_vm._v("\n                      Baixar " + _vm._s(_vm.suspiciousProtocols.downloaded.checkedItens.length) + " " + _vm._s(_vm.suspiciousProtocols.downloaded.checkedItens.length > 1 ? "protocolos" : "protocolo") + "\n                    ")]) : _vm._e()], 1), _vm._v(" "), _c("div", [_vm.suspiciousProtocols.downloaded.checkedItens.length === 0 && _vm.suspiciousProtocols.downloaded.allItens.length > 0 ? _c("b-button", {
    attrs: {
      type: "is-success",
      "icon-right": "download"
    },
    on: {
      click: function click($event) {
        return _vm.downloadProtocols({
          type: "downloaded",
          all: true
        });
      }
    }
  }, [_vm._v("\n                      Baixar Todos\n                    ")]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "ml-4"
  }, [_c("b-select", {
    model: {
      value: _vm.suspiciousProtocols.downloaded.qtyPerPage,
      callback: function callback($$v) {
        _vm.$set(_vm.suspiciousProtocols.downloaded, "qtyPerPage", $$v);
      },
      expression: "suspiciousProtocols.downloaded.qtyPerPage"
    }
  }, [_c("option", {
    attrs: {
      value: "10"
    }
  }, [_vm._v("10 por página")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "20"
    }
  }, [_vm._v("20 por página")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "50"
    }
  }, [_vm._v("50 por página")]), _vm._v(" "), _c("option", {
    attrs: {
      value: "100"
    }
  }, [_vm._v("100 por página")])])], 1)])])])]), _vm._v(" "), _c("b-table", {
    ref: "tblSuspiciousProtocolsDownloaded",
    attrs: {
      striped: "",
      data: _vm.suspiciousProtocols.downloaded.allItens,
      loading: _vm.suspiciousProtocols.downloaded.isLoading,
      paginated: "",
      "aria-next-label": "Próxima página",
      "aria-previous-label": "Página anterior",
      "aria-page-label": "Página",
      "aria-current-label": "Página atual",
      "per-page": _vm.suspiciousProtocols.downloaded.qtyPerPage,
      checkable: "",
      "checkbox-position": "left",
      "checkbox-type": "is-info",
      "checked-rows": _vm.suspiciousProtocols.downloaded.checkedItens,
      detailed: "",
      "detail-key": "key",
      "detail-transition": "fade",
      "show-detail-icon": true
    },
    on: {
      "update:data": function updateData($event) {
        return _vm.$set(_vm.suspiciousProtocols.downloaded, "allItens", $event);
      },
      "update:checkedRows": function updateCheckedRows($event) {
        return _vm.$set(_vm.suspiciousProtocols.downloaded, "checkedItens", $event);
      },
      "update:checked-rows": function updateCheckedRows($event) {
        return _vm.$set(_vm.suspiciousProtocols.downloaded, "checkedItens", $event);
      },
      "details-open": _vm.showProtocolDetails
    },
    scopedSlots: _vm._u([{
      key: "detail",
      fn: function fn(props) {
        return [_vm.suspiciousProtocols.detailed.hasOwnProperty(props.row.key) ? [_c("strong", [_vm._v("Informações do protocolo:")]), _c("br"), _vm._v(" "), _c("div", {
          staticClass: "columns"
        }, [_c("div", {
          staticClass: "column"
        }, [_c("strong", [_vm._v("Data da requisição:")]), _vm._v(" " + _vm._s(_vm._f("dateBrazil")(_vm.suspiciousProtocols.detailed[props.row.key].requestDate))), _c("br"), _vm._v(" "), _c("strong", [_vm._v("Protocolo HivePlace:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].hiveProtocol)), _c("br"), _vm._v(" "), _c("strong", [_vm._v("Protocolo Cliente:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].clientProtocol) + "\n                  ")]), _vm._v(" "), _c("div", {
          staticClass: "column"
        }, [_c("strong", [_vm._v("Serviço:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].service)), _c("br"), _vm._v(" "), _c("strong", [_vm._v("Path request:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].pathRequest)), _c("br"), _vm._v(" "), _c("strong", [_vm._v("Origem:")]), _vm._v(" " + _vm._s(_vm.suspiciousProtocols.detailed[props.row.key].consumer) + "\n                  ")])]), _vm._v(" "), _c("hr"), _vm._v(" "), _c("div", {
          staticClass: "columns"
        }, [_c("div", {
          staticClass: "column"
        }, [_vm.suspiciousProtocols.detailed[props.row.key].image ? _c("img", {
          staticClass: "user-img",
          attrs: {
            src: "data:image/jpeg;base64,".concat(_vm.suspiciousProtocols.detailed[props.row.key].image)
          }
        }) : _vm._e()])])] : [_c("div", {
          staticClass: "flex-container flex-start"
        }, [_c("div", {
          staticClass: "mr-5",
          staticStyle: {
            width: "580px"
          }
        }, [_c("b-skeleton", {
          attrs: {
            animated: "",
            width: "20%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: "",
            width: "80%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: "",
            width: "40%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: ""
          }
        })], 1), _vm._v(" "), _c("div", {
          staticStyle: {
            width: "380px"
          }
        }, [_c("b-skeleton", {
          attrs: {
            animated: "",
            width: "50%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: "",
            width: "40%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: "",
            width: "65%"
          }
        }), _vm._v(" "), _c("b-skeleton", {
          attrs: {
            animated: ""
          }
        })], 1)]), _vm._v(" "), _c("p", {
          staticClass: "mt-2 pt-2",
          staticStyle: {
            "border-top": "1px solid #dcdcdc",
            "font-style": "italic"
          }
        }, [_c("b-skeleton", {
          attrs: {
            animated: "",
            width: "50%"
          }
        })], 1)]];
      }
    }])
  }, [_c("b-table-column", {
    attrs: {
      sortable: "",
      width: "280",
      field: "date",
      label: "Data"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", [_vm._v(_vm._s(_vm._f("dateBrazil")(props.row.date)))])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      sortable: "",
      field: "service",
      label: "Serviço"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "protocol-number-in-table"
        }, [_vm._v(_vm._s(_vm._f("toUpperCase")(props.row.service)))])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "key",
      width: "470",
      label: "Protocolo Hive"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "protocol-number-in-table"
        }, [_vm._v(_vm._s(props.row.key))])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      width: "470",
      field: "transaction-id-client",
      label: "Protocolo Cliente"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("span", {
          staticClass: "protocol-number-in-table"
        }, [_vm._v(_vm._s(props.row["transaction-id-client"]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;