"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'DropDown',
  props: {
    direction: {
      type: String,
      default: 'down'
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    hideArrow: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'li'
    }
  },
  data: function data() {
    return {
      isOpen: false
    };
  },
  provide: function provide() {
    return {
      closeDropDown: this.closeDropDown
    };
  },
  methods: {
    toggleDropDown: function toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);
    },
    closeDropDown: function closeDropDown() {
      this.isOpen = false;
      this.$emit('change', this.isOpen);
    }
  }
};