"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'CardDashboardMonitor',
  props: {
    type: {
      type: String,
      default: ''
    },
    milliseconds: {
      type: [String, Number],
      default: 0
    },
    request: {
      type: [String, Number],
      default: 0
    },
    logo: {
      type: String,
      default: ''
    },
    total: {
      type: [String, Number],
      default: 0
    },
    lastUpdate: {
      type: String,
      default: ''
    },
    totalSuccess: {
      type: [String, Number],
      default: 0
    },
    totalError: {
      type: [String, Number],
      default: 0
    },
    total3Number: {
      type: [String, Number],
      default: 0
    },
    errorLabel: {
      type: String,
      default: 'com erro'
    },
    total3Label: {
      type: String,
      default: 'em processamento'
    },
    total3Color: {
      type: String,
      default: 'is-info'
    },
    load: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Requisições'
    }
  }
};