"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'NSwitch',
  props: {
    value: {
      type: [Array, Boolean],
      default: function _default() {
        return [];
      }
    },
    onText: {
      type: String,
      default: ''
    },
    offText: {
      type: String,
      default: ''
    }
  },
  computed: {
    switchClass: function switchClass() {
      var base = 'bootstrap-switch-';
      var state = this.model ? 'on' : 'off';
      var classes = base + state;
      return classes;
    },
    model: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    triggerToggle: function triggerToggle() {
      this.model = !this.model;
    }
  }
};