"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-extract-documents-finances-detail"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-xxl-3"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n              " + _vm._s(_vm.service.name) + "\n            ")])])])])])]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 col-lg-12 col-details"
  }, [_c("div", {
    staticClass: "list-items-details"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "section-generate-extract"
  }, [_vm.isAdmin ? _c("b-button", {
    attrs: {
      type: "is-link"
    },
    on: {
      click: _vm.generateExtract
    }
  }, [_vm._v("\n            Extrato Digital\n          ")]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "section-content-actions"
  }, [_c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewDocument
    }
  })], 1), _vm._v(" "), _vm.listDocuments.length ? _c("div", {
    staticClass: "list-cards-documents"
  }, [_c("div", {
    staticClass: "columns cols-cards-info is-multiline"
  }, _vm._l(_vm.listDocuments, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column is-one-fifth content-centered"
    }, [_c("card-certificate", {
      attrs: {
        title: item.name,
        logo: require("@/assets/images/icons/document.png"),
        link: item.link
      },
      nativeOn: {
        click: function click($event) {
          return _vm.downloadFile(item);
        }
      }
    })], 1);
  }), 0)]) : _c("div", [_c("h4", {
    staticClass: "text-not-found"
  }, [_vm._v("Sem dados suficientes para o período selecionado.")])])])])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header-title"
  }, [_c("h2", [_vm._v("Relatórios financeiros")])]);
}];
render._withStripped = true;