"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  methods: {
    redirect: function redirect() {
      this.$router.push({
        path: '/connection-monitor'
      });
    }
  }
};