"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-component-embedded",
    attrs: {
      id: "card-embedded"
    }
  }, [_vm.token && _vm.reportSuccess ? _c("div", {
    staticClass: "report-container",
    attrs: {
      id: "reportContainer",
      "powerbi-type": "report",
      "powerbi-embed-url": _vm.link,
      "powerbi-access-token": _vm.token,
      width: _vm.window.width,
      height: _vm.window.height,
      getEmbeddedComponent: _vm.embeddedSetting
    }
  }) : _vm._e(), _vm._v(" "), !_vm.reportSuccess ? _c("NotFound", {
    attrs: {
      text: "Este serviço não possui analítico."
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;