"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Editar serviço autorizado")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("form", {
    attrs: {
      id: "formNewTypeService"
    }
  }, [_c("app-input", {
    attrs: {
      label: "Serviço",
      disabled: ""
    },
    model: {
      value: _vm.serviceName,
      callback: function callback($$v) {
        _vm.serviceName = $$v;
      },
      expression: "serviceName"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      label: "Tipo de seleção do processamento",
      placeholder: "Informe o campo de seleção de processamento",
      error: _vm.$v.form.typeFieldProcessing.$error,
      items: _vm.listTypeFieldProcessing,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.typeFieldProcessing, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.typeFieldProcessing.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.typeFieldProcessing, "$model", $$v);
      },
      expression: "$v.form.typeFieldProcessing.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "Tipos de processamento"
    }
  }, [_c("b-taginput", {
    attrs: {
      ellipsis: "",
      icon: "label",
      placeholder: "Informe os tipos de processamento",
      "aria-close-label": "Apagar os tipos de processamento"
    },
    model: {
      value: _vm.$v.form.typesProcessing.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.typesProcessing, "$model", $$v);
      },
      expression: "$v.form.typesProcessing.$model"
    }
  })], 1)], 1)])], 1)]), _vm._v(" "), _c("b-button", {
    attrs: {
      slot: "footer",
      type: "is-info"
    },
    on: {
      click: _vm.checkForm
    },
    slot: "footer"
  }, [_vm._v("\n    Salvar\n  ")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;