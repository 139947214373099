"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue2Transitions = require("vue2-transitions");
var _default = exports.default = {
  name: 'Modal',
  components: {
    SlideYUpTransition: _vue2Transitions.SlideYUpTransition
  },
  props: {
    show: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: '',
      validator: function validator(value) {
        var acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    modalClasses: {
      type: [Object, String],
      default: null
    },
    headerClasses: {
      type: [Object, String],
      default: null
    },
    bodyClasses: {
      type: [Object, String],
      default: null
    },
    footerClasses: {
      type: [Object, String],
      default: null
    },
    animationDuration: {
      type: Number,
      default: 500
    }
  },
  watch: {
    show: function show(val) {
      var documentClasses = document.body.classList;
      if (val) {
        documentClasses.add('modal-open');
      } else {
        documentClasses.remove('modal-open');
      }
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    }
  }
};