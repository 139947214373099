"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-messages-tickets-components"
  }, [_c("div", {
    staticClass: "chat-container"
  }, [_c("div", {
    staticClass: "chat-history"
  }, _vm._l(_vm.list, function (item, idx) {
    var _item$user, _item$user$personId, _item$user2, _item$user2$personId;
    return _c("div", {
      key: idx,
      staticClass: "columns"
    }, [item.type !== "REQUEST" ? _c("div", {
      staticClass: "column is-hidden-mobile"
    }, [_vm._v(" ")]) : _vm._e(), _vm._v(" "), _c("div", {
      staticClass: "column is-11"
    }, [_c("el-card", {
      class: {
        "client-msg": item.type === "REQUEST",
        "hive-msg": item.type === "RESPONSE"
      }
    }, [_c("el-avatar", {
      attrs: {
        size: "small",
        src: (_item$user = item.user) === null || _item$user === void 0 ? void 0 : (_item$user$personId = _item$user.personId) === null || _item$user$personId === void 0 ? void 0 : _item$user$personId.imageUrl
      }
    }), _vm._v(" "), _c("small", [_vm._v(_vm._s((_item$user2 = item.user) === null || _item$user2 === void 0 ? void 0 : (_item$user2$personId = _item$user2.personId) === null || _item$user2$personId === void 0 ? void 0 : _item$user2$personId.name) + " | " + _vm._s(_vm._f("dateBrazil")(item.createdAt)))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(item.message))])], 1)], 1), _vm._v(" "), item.type === "REQUEST" ? _c("div", {
      staticClass: "column is-hidden-mobile"
    }, [_vm._v(" ")]) : _vm._e()]);
  }), 0)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;