"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.errorResponseHelper = void 0;
var _router = _interopRequireDefault(require("@/router"));
var _helpers = require("@/helpers");
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
// eslint-disable-next-line no-undef
var display = function display() {
  var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var errorKey = 'default';
  if (typeof error === 'string') {
    errorKey = error;
  } else {
    var response = error.response || undefined;
    if (!response) {
      response = error.data ? error : undefined;
    }
    if (response) {
      if (_typeof(response) === 'object' && response.data) {
        var errorDetail = response.data.details;
        if (Array.isArray(errorDetail) && errorDetail.length > 0) {
          var msg = errorDetail[0];
          response = {
            data: {
              message: msg ? msg.toLowerCase().split(' ').join('_') : ''
            }
          };
        }
      }
      var messageError = _lodash.default.get(response, 'data.message') || 'error_default';
      var messageTransforme = messageError.replace(/\s/g, '_').toLowerCase();
      var messageTranslate = _router.default.app.$t("errors.".concat(messageTransforme));
      if (messageTranslate.includes('errors.')) {
        messageTranslate = _router.default.app.$t("errors.error_default");
      }
      return _helpers.messageHelper.error(messageTranslate);
    }
  }
  var message = _router.default.app.$t("errors.".concat(errorKey));
  if (error.message) {
    message = error.message;
  } else {
    if (message.includes('errors.')) {
      message = _router.default.app.$t("errors.default");
    }
  }
  _helpers.messageHelper.error(message);
};
var errorResponseHelper = exports.errorResponseHelper = {
  display: display
};