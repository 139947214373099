"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "time-line-protocol-life-component"
  }, [_c("h1", {
    staticClass: "project-name"
  }, [_vm._v("TIMELINE DO PROTOCOLO")]), _vm._v(" "), _c("div", {
    attrs: {
      id: "timeline"
    }
  }, _vm._l(_vm.list, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "timeline-item"
    }, [_vm._m(0, true), _vm._v(" "), _c("div", {
      staticClass: "timeline-content",
      class: item.position
    }, [_c("h2", [_vm._v(_vm._s(item.type))]), _vm._v(" "), _c("p", [_vm._v("Data: " + _vm._s(_vm._f("dateCompleteWithMiliseconds")(item.date)))]), _vm._v(" "), _c("p", [_vm._v("Consumer: " + _vm._s(item.consumer))]), _vm._v(" "), _c("p", [_vm._v("Path: " + _vm._s(item.path))]), _vm._v(" "), _c("p", [_vm._v("HTTP Status: " + _vm._s(item.httpStatus))]), _vm._v(" "), _c("p", [_vm._v("Chave: " + _vm._s(item.key))]), _vm._v(" "), _c("p", [_vm._v("Chave do Cliente: " + _vm._s(item.idClient))]), _vm._v(" "), _c("b-collapse", {
      attrs: {
        open: false,
        "aria-id": "contentIdForA11y1"
      },
      scopedSlots: _vm._u([{
        key: "trigger",
        fn: function fn(props) {
          return [_c("b-button", {
            attrs: {
              label: "Body",
              type: "is-primary",
              "aria-controls": "contentIdForA11y1",
              "aria-expanded": props.open
            }
          })];
        }
      }], null, true)
    }, [_vm._v(" "), _c("div", {
      staticClass: "notification"
    }, [_c("div", {
      staticClass: "content"
    }, [_c("code", [_vm._v(_vm._s(JSON.stringify(item.payload, null, 4)))])])])])], 1)]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "timeline-icon"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icons/protocol-send-client.png")
    }
  })]);
}];
render._withStripped = true;