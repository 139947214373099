"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-client-list"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Clientes")]), _vm._v(" "), _c("b-button", {
    staticClass: "btn-new-client",
    attrs: {
      type: "is-primary"
    },
    on: {
      click: _vm.addNewClient
    }
  }, [_vm._v("\n    Novo cliente\n  ")]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 ml-auto col-xl-12"
  }, [_c("field-search-input", {
    attrs: {
      placeholder: "Pesquise por nome do cliente ou CNPJ"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "content-list-table"
  }, [_c("table-list-clients", {
    attrs: {
      list: _vm.listClients,
      "action-item": _vm.openDetail
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;