"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card"
  }, [_vm._m(0), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("p", [_vm._v("Este site utiliza cookies para melhorar sua experiência. Aceita utilizar cookies? ")]), _vm._v(" "), _c("div", {
    staticClass: "button-container"
  }, [_c("b-button", {
    attrs: {
      slot: "footer",
      type: "is-danger"
    },
    on: {
      click: _vm.declineCookies
    },
    slot: "footer"
  }, [_vm._v("\n        Recusar\n      ")]), _vm._v(" "), _c("b-button", {
    attrs: {
      slot: "footer",
      type: "is-info"
    },
    on: {
      click: _vm.acceptCookies
    },
    slot: "footer"
  }, [_vm._v("\n        Aceitar\n      ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", [_vm._v("Permissão para uso de Cookies")])]);
}];
render._withStripped = true;