"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-contri-redem-list"
  }, [_c("b-table", {
    attrs: {
      data: _vm.display
    }
  }, [[_c("b-table-column", {
    attrs: {
      label: "FUNDO"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticClass: "first-column"
        }, [_vm._v("\n          " + _vm._s(props.row.fund.person.personName || "") + "\n        ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "CONTA"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.fund.account || "") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "PRIMEIRA APLICAÇÃO"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(new Date(props.row.fund.startDate).toLocaleDateString() || "") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "VALOR APLICADO"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(_vm._f("maskMoney")(props.row.fund.minimumMovementValue)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          attrs: {
            rounded: ""
          },
          on: {
            click: function click($event) {
              return _vm.actionOpen(props.row);
            }
          }
        }, [_vm._v("Aportar ou resgatar")])];
      }
    }])
  })]], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;