"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card modal-finisher-component"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Deseja finalizar esse Ticket?")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.cancel
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("div", [_vm._v("\n      Você deseja finalizar o atendimento? Por favor, nos informe se o problema ou dúvida foi solucionado.\n    ")]), _vm._v(" "), _c("b-field", [_c("b-radio-button", {
    attrs: {
      "native-value": "not",
      type: "is-danger is-light is-outlined"
    },
    model: {
      value: _vm.solved,
      callback: function callback($$v) {
        _vm.solved = $$v;
      },
      expression: "solved"
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "close"
    }
  }), _vm._v(" "), _c("span", [_vm._v("Não")])], 1), _vm._v(" "), _c("b-radio-button", {
    attrs: {
      "native-value": "yes",
      type: "is-success is-light is-outlined"
    },
    model: {
      value: _vm.solved,
      callback: function callback($$v) {
        _vm.solved = $$v;
      },
      expression: "solved"
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "check"
    }
  }), _vm._v(" "), _c("span", [_vm._v("Sim")])], 1)], 1), _vm._v(" "), _vm.solved === "not" ? _c("b-field", [_c("b-input", {
    attrs: {
      placeholder: "Descreva o motivo",
      maxlength: "500",
      type: "textarea"
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "content-btns"
  }, [_vm.isFinish ? _c("b-button", {
    attrs: {
      type: "is-success",
      rounded: ""
    },
    on: {
      click: _vm.confirm
    }
  }, [_vm._v("\n        Encerrar o atendimento\n      ")]) : _vm._e(), _vm._v(" "), _c("b-button", {
    attrs: {
      type: "is-info",
      rounded: ""
    },
    on: {
      click: _vm.cancel
    }
  }, [_vm._v("\n        Cancelar\n      ")])], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;