"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "table-list-invites-facet-hive-component"
  }, [_c("b-table", {
    attrs: {
      data: _vm.listItems
    },
    scopedSlots: _vm._u([!_vm.isCustom ? {
      key: "footer",
      fn: function fn() {
        return [_c("div", {
          staticClass: "has-text-right"
        }, [_vm._v("Footer")])];
      },
      proxy: true
    } : {
      key: "footer",
      fn: function fn() {
        return [_c("th", {
          staticClass: "is-hidden-mobile",
          staticStyle: {
            width: "40px"
          }
        }, [_c("div", {
          staticClass: "th-wrap is-numeric"
        }, [_vm._v("ID")])]), _vm._v(" "), _c("th", {
          staticClass: "is-hidden-mobile"
        }, [_c("div", {
          staticClass: "th-wrap"
        }, [_vm._v("First Name")])]), _vm._v(" "), _c("th", {
          staticClass: "is-hidden-mobile"
        }, [_c("div", {
          staticClass: "th-wrap"
        }, [_vm._v("Last Name")])]), _vm._v(" "), _c("th", {
          staticClass: "is-hidden-mobile"
        }, [_c("div", {
          staticClass: "th-wrap is-centered"
        }, [_vm._v("Date")])]), _vm._v(" "), _c("th", {
          staticClass: "is-hidden-mobile"
        }, [_c("div", {
          staticClass: "th-wrap"
        }, [_vm._v("Gender")])])];
      },
      proxy: true
    }], null, true)
  }, [_c("b-table-column", {
    attrs: {
      label: "Data"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n      " + _vm._s(props.row.hoje) + "\n    ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Semana"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n      " + _vm._s(props.row.semana) + "\n    ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Mês",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n      " + _vm._s(props.row.mes) + "\n    ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Ano"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n      " + _vm._s(props.row.ano) + "\n    ")];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;