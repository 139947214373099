"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'PayrollLoans',
  bodyClass: 'payroll-loans-page',
  components: {},
  data: function data() {
    return {
      uriPortal: 'https://faciltecnologia.com.br/consigfacil/apresentacao-hive/'
    };
  },
  created: function created() {
    console.log('this.uriPortal: ', this.uriPortal);
  },
  methods: {
    goBack: function goBack() {
      this.$router.back();
    }
  }
};