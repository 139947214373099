"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'TableListClients',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    actionDelete: {
      type: Function,
      default: function _default() {}
    },
    showDelete: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    action: function action(item) {
      this.actionDelete(item);
    }
  }
};