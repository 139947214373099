"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-client-data-form-documents"
  }, [_c("form", {
    attrs: {
      id: "clientDocumentForm"
    },
    on: {
      submit: _vm.saveDocuments
    }
  }, [_c("card", {
    staticClass: "card-customer-finished-form-prospect-component"
  }, [_c("h1", [_vm._v("Lista de Documentos")]), _vm._v(" "), _vm.idClient ? _c("div", {
    staticClass: "list-documents-requests"
  }, [_c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewDocument
    }
  }), _vm._v(" "), !_vm.listADocumentsClient.length ? _c("div", [_c("h4", {
    staticClass: "text-not-found"
  }, [_vm._v("Sem documentos cadastrados")])]) : _vm._e(), _vm._v(" "), _vm._l(_vm.listADocumentsClient, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "field"
    }, [_c("div", {
      staticClass: "field-body"
    }, [_c("div", {
      staticClass: "field is-grouped"
    }, [_c("label", {
      staticClass: "upload control"
    }, [_c("div", {
      staticClass: "upload-draggable is-primary"
    }, [_c("section", {
      staticClass: "section",
      on: {
        click: function click($event) {
          return _vm.openLink(item);
        }
      }
    }, [_c("div", {
      staticClass: "content has-text-centered"
    }, [_c("span", {
      staticClass: "icon is-large"
    }, [_c("i", {
      staticClass: "mdi mdi-file-document mdi-48px"
    })]), _vm._v(" "), _c("p", [_vm._v(_vm._s(item.Name))])])])])]), _vm._v(" "), _c("b-button", {
      staticClass: "remove-item",
      attrs: {
        "icon-right": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.confirmDelete(item);
        }
      }
    })], 1)])]);
  })], 2) : _vm._e()])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;