"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_vm.canAproved ? _c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Motivo de Aprovação")]) : _vm._e(), _vm._v(" "), !_vm.canAproved ? _c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Motivo de Reprovação")]) : _vm._e(), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("form", {
    attrs: {
      id: "formNewTypeService"
    }
  }, [_c("app-input", {
    attrs: {
      label: "Descrição do Motivo",
      placeholder: "Motivo...",
      error: _vm.$v.form.description.$error,
      type: "textarea",
      max: "250"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.description, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.description.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.description, "$model", $$v);
      },
      expression: "$v.form.description.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "content-btns is-flex is-justify-content-flex-end is-align-content-flex-end mt-6"
  }, [_c("b-button", {
    staticClass: "mr-2",
    attrs: {
      type: "is-info"
    },
    on: {
      click: function click($event) {
        return _vm.checkForm($event, _vm.canAproved ? "manually_approved" : "manually_rejected");
      }
    }
  }, [_vm._v("\n        Confirmar\n      ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;