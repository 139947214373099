"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card modal-upload-batch-processing"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Editar status do Arquivo")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("form", {
    attrs: {
      id: "form-upload-batch-processing-file"
    }
  }, [_vm.listStatus.length ? _c("app-select", {
    attrs: {
      label: "Status do processamento",
      placeholder: "Informe qual o status",
      items: _vm.listStatus,
      option: "status",
      "name-item": "description"
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }) : _vm._e(), _vm._v(" "), _vm.form.status === "processed_file" ? _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column mt-5"
  }, [_c("app-input", {
    attrs: {
      label: "Número de itens processados",
      type: "number",
      error: _vm.$v.form.qtdProcess.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.qtdProcess, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.qtdProcess.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.qtdProcess, "$model", $$v);
      },
      expression: "$v.form.qtdProcess.$model"
    }
  }), _vm._v(" "), _c("b-field", {
    class: _vm.$v.form.file.$error ? "is-danger" : "",
    attrs: {
      label: "Arquivo Processado"
    }
  }, [_c("b-upload", {
    attrs: {
      "drag-drop": "",
      accept: _vm.typeFile
    },
    model: {
      value: _vm.fileLogo,
      callback: function callback($$v) {
        _vm.fileLogo = $$v;
      },
      expression: "fileLogo"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.fileLogo ? _c("p", [_vm._v("Busque ou arraste o arquivo")]) : _c("p", [_vm._v(_vm._s(_vm.fileLogo.name || "Arquivo selecionado"))])], 1)])])], 1)], 1)]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "content-btns"
  }, [_c("b-button", {
    attrs: {
      type: "is-info",
      disabled: _vm.$v.form.$invalid || _vm.isLoading,
      loading: _vm.isLoading
    },
    on: {
      click: _vm.checkForm
    }
  }, [_vm._v("\n        Salvar\n      ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;