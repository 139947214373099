"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$documentoIdentifi, _vm$documentoOutros;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cols-form tab-card-validation-documents-component"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [!_vm.documentoIdentificacao.documents ? _c("not-found") : _vm._e(), _vm._v(" "), _vm.documentoIdentificacao.documents ? _c("card", {
    staticClass: "card-detail-image"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_vm.documentoscopia ? _c("h2", [_c("b", [_vm._v("Análise dos Documentos:")]), _vm._v(" "), _c("br"), _vm._v(_vm._s(_vm._f("documentoscopia")(_vm.validationDocumentoscopia(_vm.validationItem.documentoscopia).score)))]) : _vm._e()])]), _vm._v(" "), _c("div", {
    staticClass: "content-files"
  }, [_c("h4", [_vm._v("Documentos de identificação:")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, _vm._l(((_vm$documentoIdentifi = _vm.documentoIdentificacao) === null || _vm$documentoIdentifi === void 0 ? void 0 : _vm$documentoIdentifi.documents) || [], function (itemValidation, idx) {
    return _c("div", {
      key: idx * 2,
      staticClass: "column col-img"
    }, [_c("div", [_c("img", {
      attrs: {
        src: itemValidation
      },
      on: {
        click: function click($event) {
          return _vm.openModal(itemValidation);
        }
      }
    })]), _vm._v(" "), _c("h2", [_vm._v("Tipo: " + _vm._s(_vm.documentoIdentificacao.documentType[idx] || "Outros"))]), _vm._v(" "), _c("h2", [_vm._v("Score: " + _vm._s((itemValidation === null || itemValidation === void 0 ? void 0 : itemValidation.score) || "--"))])]);
  }), 0)]), _vm._v(" "), _c("hr"), _vm._v(" "), _c("div", [_c("h4", [_vm._v("Declaração:")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, _vm._l(((_vm$documentoOutros = _vm.documentoOutros) === null || _vm$documentoOutros === void 0 ? void 0 : _vm$documentoOutros.documents) || [], function (itemValidation, idx) {
    return _c("div", {
      key: idx * 2,
      staticClass: "column col-img"
    }, [_c("div", [_c("img", {
      attrs: {
        src: itemValidation
      },
      on: {
        click: function click($event) {
          return _vm.openModal(itemValidation);
        }
      }
    })]), _vm._v(" "), _c("h2", [_vm._v("Tipo: " + _vm._s(_vm.documentoOutros.documentType[idx] || "Outros"))]), _vm._v(" "), _c("h2", [_vm._v("Score: " + _vm._s((itemValidation === null || itemValidation === void 0 ? void 0 : itemValidation.score) || "--"))])]);
  }), 0)])]) : _vm._e()], 1)]), _vm._v(" "), _c("b-modal", {
    model: {
      value: _vm.isImageModalActive,
      callback: function callback($$v) {
        _vm.isImageModalActive = $$v;
      },
      expression: "isImageModalActive"
    }
  }, [_c("p", {
    staticClass: "image is-4by3"
  }, [_c("img", {
    attrs: {
      src: _vm.imgSelected
    }
  })])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;