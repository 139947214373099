"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BatchProcessingLogfiles = _interopRequireDefault(require("./BatchProcessingLogfiles.vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  name: 'AuditLogfiles',
  bodyClass: 'audit-monitoring-reports-page',
  components: {
    BatchProcessingLogfiles: _BatchProcessingLogfiles.default
  },
  data: function data() {
    return {
      tab: 0
    };
  }
};