"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue2Editor = require("vue2-editor");
var _quillImageDropModule = require("quill-image-drop-module");
_vue2Editor.Quill.register('modules/imageDrop', _quillImageDropModule.ImageDrop);
var _default = exports.default = {
  name: 'DocumentationServiceEdit',
  bodyClass: 'documentation-service-edit-page',
  components: {
    VueEditor: _vue2Editor.VueEditor
  },
  data: function data() {
    return {
      content: '<h1>Initial Content</h1>',
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      }
    };
  },
  mounted: function mounted() {},
  deactivated: function deactivated() {},
  beforeDestroy: function beforeDestroy() {},
  methods: {}
};