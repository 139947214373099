"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vMoney = require("v-money");
var _default2 = exports.default = {
  name: 'AppInput',
  components: {
    Money: _vMoney.Money
  },
  props: {
    input: {
      type: Function,
      default: function _default() {}
    },
    classField: {
      type: String,
      default: ''
    },
    classInput: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    complete: {
      type: String,
      default: 'on'
    },
    placeholder: {
      type: String,
      default: ''
    },
    mask: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    included: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: {
      type: [Number, String],
      default: ''
    },
    max: {
      type: [Number, String],
      default: ''
    },
    expanded: {
      type: [Boolean, String],
      default: false
    },
    masked: {
      type: Boolean,
      default: false
    },
    typeInput: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: ''
    },
    readonly: {
      type: String,
      default: ''
    },
    rows: {
      type: [String, Number],
      default: ''
    },
    money: {
      type: Object,
      default: function _default() {
        return {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
        };
      }
    }
  },
  data: function data() {
    return {
      newValue: this.value
    };
  },
  computed: {
    computedValue: {
      get: function get() {
        return this.newValue;
      },
      set: function set(value) {
        this.newValue = value;
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function value(_value) {
      this.newValue = _value;
    }
  }
};