"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-tickets-index"
  }, [_c("div", {
    staticClass: "is-flex is-justify-content-space-between"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Tickets de atendimento")]), _vm._v(" "), _c("el-input", {
    staticStyle: {
      width: "350px",
      position: "relative",
      top: "-8px",
      "margin-right": "50px"
    },
    attrs: {
      placeholder: "Filtrar tickets por",
      "prefix-icon": "el-icon-search"
    },
    model: {
      value: _vm.tableGlobalFilter,
      callback: function callback($$v) {
        _vm.tableGlobalFilter = $$v;
      },
      expression: "tableGlobalFilter"
    }
  }), _vm._v(" "), _c("b-button", {
    staticStyle: {
      position: "relative",
      top: "-12px"
    },
    attrs: {
      type: "is-primary"
    },
    on: {
      click: function click($event) {
        return _vm.abrirNovoTicket();
      }
    }
  }, [_vm._v("\n      Abrir novo ticket\n    ")])], 1), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.filteredData,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "ticketNumber",
      label: "Número",
      sortable: "",
      width: "170",
      align: "center"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "businessUnit",
      label: "Unidade",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(scope.row.businessUnit) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "subject",
      label: "Tipo",
      sortable: "",
      width: "180",
      "column-key": "type",
      filters: [{
        text: "Dúvida",
        value: "Dúvida"
      }, {
        text: "Contestação resultado",
        value: "Contestação resultado"
      }, {
        text: "Contestação SLA",
        value: "Contestação SLA"
      }],
      "filter-method": _vm.filterHandler
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            "disable-transitions": "",
            type: scope.row.type === "Dúvida" ? "primary" : scope.row.type === "Contestação resultado" ? "success" : "info"
          }
        }, [_vm._v(_vm._s(scope.row.subject))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "ticketType",
      label: "Motivo",
      sortable: "",
      width: "180",
      "column-key": "type",
      filters: [{
        text: "Dúvida",
        value: "Dúvida"
      }, {
        text: "Contestação resultado",
        value: "Contestação resultado"
      }, {
        text: "Contestação SLA",
        value: "Contestação SLA"
      }],
      "filter-method": _vm.filterHandler
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            "disable-transitions": "",
            type: scope.row.ticketType === "Dúvida" ? "primary" : scope.row.ticketType === "Contestação resultado" ? "success" : "info"
          }
        }, [_vm._v(_vm._s(scope.row.ticketType))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "digitalAsset",
      label: "Serviço",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(scope.row.digitalAsset || " -- ") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "createdAt",
      label: "Data de Abertura",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm._f("dateBrazil")(scope.row.createdAt)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "STATUS",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm._f("translateStatus")(scope.row.status)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _vm.isAdmin ? _c("el-table-column", {
    attrs: {
      label: "Informações do atendimento"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "user",
      label: "Usuário",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "userSupport",
      label: "Atendimento",
      sortable: ""
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "95",
      align: "center",
      label: "Detalhar"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("svg", {
          staticStyle: {
            cursor: "pointer"
          },
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "24",
            height: "24",
            viewBox: "0 0 32 32"
          },
          on: {
            click: function click($event) {
              return _vm.detalharAtendimento(scope.row._id);
            }
          }
        }, [_c("path", {
          attrs: {
            fill: "currentColor",
            d: "M5 5a3 3 0 0 1 3-3h10.172a3 3 0 0 1 2.12.879l5.83 5.828A3 3 0 0 1 27 10.828V27a3 3 0 0 1-3 3h-6.05a2.5 2.5 0 0 0-.45-2H24a1 1 0 0 0 1-1V12h-5a3 3 0 0 1-3-3V4H8a1 1 0 0 0-1 1v9.566a7.447 7.447 0 0 0-2 .558zm15 5h4.586L19 4.414V9a1 1 0 0 0 1 1M8 28c1.296 0 2.496-.41 3.476-1.11l3.317 3.317a1 1 0 0 0 1.414-1.414l-3.316-3.316A6 6 0 1 0 8 28m0-2a4 4 0 1 1 0-8a4 4 0 0 1 0 8"
          }
        })])];
      }
    }])
  })], 1), _vm._v(" "), _vm.totalRegisters > _vm.limit ? _c("div", {
    staticStyle: {
      "text-align": "center",
      padding: "10px 0",
      "background-color": "white"
    }
  }, [_c("el-pagination", {
    attrs: {
      background: "",
      "current-page": _vm.page,
      "page-size": _vm.limit,
      layout: "total, sizes, prev, pager, next, jumper",
      total: _vm.totalRegisters
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.page = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.page = $event;
      },
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;