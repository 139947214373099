"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("card-component", {
    staticClass: "card-login"
  }, [!_vm.error ? _c("div", [_c("div", {
    staticClass: "logo-container"
  }, [_c("h1", {
    staticClass: "title"
  }, [_vm._v("HIVEPlace")]), _vm._v(" "), _c("p", {
    staticClass: "sub-title"
  }, [_vm._v("Por favor, crie uma nova senha para poder acessar a plataforma novamente:")])]), _vm._v(" "), !_vm.updateSuccess ? _c("form", {
    attrs: {
      id: "app-login"
    },
    on: {
      submit: _vm.checkForm
    }
  }, [_c("app-input", {
    attrs: {
      error: _vm.$v.form.Password.$error,
      label: "Senha",
      placeholder: "Crie uma senha",
      type: "password",
      name: "password-reset",
      "password-reveal": ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.Password, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.Password.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.Password, "$model", $$v);
      },
      expression: "$v.form.Password.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      error: _vm.$v.form.ConfirmPassword.$error,
      label: "Confirme sua senha",
      placeholder: "Confirme sua senha",
      type: "password",
      name: "confirm-password-reset",
      "password-reveal": ""
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.ConfirmPassword, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.ConfirmPassword.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.ConfirmPassword, "$model", $$v);
      },
      expression: "$v.form.ConfirmPassword.$model"
    }
  }), _vm._v(" "), _c("check-password", {
    attrs: {
      pass: _vm.form.Password,
      "confirm-pass": _vm.form.ConfirmPassword
    },
    on: {
      valid: _vm.validPass
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "card-footer"
  }, [!_vm.isMobile ? _c("div", {
    staticClass: "column"
  }, [_c("router-link", {
    staticClass: "link footer-link",
    attrs: {
      tag: "a",
      to: "/login",
      exact: ""
    }
  }, [_vm._v("Retornar para o login")])], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-button", {
    attrs: {
      rounded: "",
      disabled: _vm.$v.form.$invalid,
      "native-type": "submit",
      type: "is-primary"
    }
  }, [_vm._v("Salvar")])], 1)])], 1) : _c("div", {
    staticClass: "wrap-auth register-success"
  }, [_c("h2", {
    staticClass: "login-form-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.reset_password.text_new_password")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "description-success"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("pages.reset_password.text_password_success")) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "container-auth-form-btn"
  }, [_c("div", {
    staticClass: "wrap-auth-form-btn"
  }, [_c("b-button", {
    attrs: {
      rounded: ""
    },
    on: {
      click: function click($event) {
        return _vm.redirect("auth/login");
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("pages.reset_password.go_login")) + "\n          ")])], 1)])])]) : _c("div", [_c("div", {
    staticClass: "logo-container"
  }, [_c("h1", {
    staticClass: "title"
  }, [_vm._v("HIVEPlace")]), _vm._v(" "), _c("p", {
    staticClass: "sub-title"
  }, [_vm._v(_vm._s(_vm.$t("pages.reset_password.alerts.error_invalid_token")))])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;