"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-pro-det"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n      Análise de Protocolo\n    ")])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column col-details"
  }, [_c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Data da requisição:")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("dateBrazil")(_vm.personDetail.date)))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "service"
    }
  }, [_vm._v("Protocolo HIVEPlace:")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("toUpperCase")(_vm.personDetail.key)))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "service"
    }
  }, [_vm._v("Protocolo Cliente:")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("toUpperCase")(_vm.personDetail["transaction-id-client"])))])])])]), _vm._v(" "), _c("div", {
    staticClass: "column col-details"
  }, [_c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "phone"
    }
  }, [_vm._v("Serviço:")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("toUpperCase")(_vm.personDetail.service)))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "dateValid"
    }
  }, [_vm._v("Path Request:")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.personDetail.path || "--"))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "phone"
    }
  }, [_vm._v("Consumidor:")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.personDetail.consumer || "--"))])])])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "Imagem enviada:"
    }
  }, [_vm.userImg ? _c("img", {
    staticClass: "user-img",
    attrs: {
      src: _vm.userImg
    }
  }) : _vm._e()])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;