"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _legacy = require("vue-chartjs/legacy");
var _chart = require("chart.js");
_chart.Chart.register(_chart.Title, _chart.Tooltip, _chart.Legend, _chart.BarElement, _chart.CategoryScale, _chart.LinearScale);
var _default2 = exports.default = {
  name: 'BarChart',
  components: {
    Bar: _legacy.Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    chartData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      chartOptions: {
        indexAxis: this.horizontal ? 'y' : 'x',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            onClick: function onClick(e, legendItem, legend) {
              var index = legendItem.datasetIndex;
              var ci = legend.chart;
              var meta = ci.getDatasetMeta(index);
              meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
              ci.update();
            }
          }
        }
      }
    };
  }
};