"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "content-service-detail"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v(" "), _vm.service.logo ? _c("img", {
    attrs: {
      src: _vm.service.logo,
      alt: "logo"
    }
  }) : _vm._e(), _vm._v("\n            " + _vm._s(_vm.service.name) + "\n          ")]), _vm._v(" "), _c("div", {
    staticClass: "add-new-item"
  }, [_c("b-tooltip", {
    attrs: {
      label: "Adicionar novos arquivos"
    }
  }, [_c("b-button", {
    staticClass: "btn-add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewDocument
    }
  })], 1)], 1)])])])]), _vm._v(" "), _c("div", {
    staticClass: "section-content"
  }, [_c("div", {
    staticClass: "content-certificates"
  }, [_c("h3", [_vm._v("Arquivos enviados")]), _vm._v(" "), _c("div", {
    staticClass: "list-certs"
  }, [_vm.list.length ? _c("div", {
    staticClass: "columns is-multiline"
  }, [_c("table-list-batch-processing", {
    attrs: {
      list: _vm.list,
      "action-open": _vm.download,
      "business-unit": _vm.businessUnitActive,
      service: _vm.idService,
      "get-files": _vm.getFiles
    }
  })], 1) : _c("not-found", {
    attrs: {
      text: "Sem arquivos no momento."
    }
  })], 1)])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;