"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-client-data-form-businessunit"
  }, [_c("form", {
    attrs: {
      id: "clientDocumentForm"
    },
    on: {
      submit: _vm.saveDocuments
    }
  }, [_c("card", {
    staticClass: "card-customer-finished-form-prospect-component"
  }, [_c("h1", [_vm._v("Serviços autorizados")]), _vm._v(" "), _vm.businessUnit ? _c("div", {
    staticClass: "list-services-authorizeds-requests"
  }, [_vm.isAdmin ? _c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.openModalAddItem
    }
  }) : _vm._e(), _vm._v(" "), _c("b-table", {
    attrs: {
      data: !_vm.listServices.length ? [] : _vm.listServices,
      hoverable: true,
      focusable: true,
      "mobile-cards": true
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function fn() {
        return [_c("div", {
          staticClass: "has-text-centered"
        }, [_vm._v("Não há registros")])];
      },
      proxy: true
    }], null, false, 1302487956)
  }, [_c("b-table-column", {
    attrs: {
      field: "digitalAsset.name",
      label: "Serviço"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.digitalAsset.name) + "\n          ")];
      }
    }], null, false, 587540542)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "digitalAsset.email",
      label: "E-mail"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n            " + _vm._s(props.row.digitalAsset.email) + "\n          ")];
      }
    }], null, false, 3838361653)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "Organization",
      label: "Proprietário"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        var _props$row$digitalAss, _props$row$digitalAss2;
        return [_vm._v("\n            " + _vm._s(((_props$row$digitalAss = props.row.digitalAsset) === null || _props$row$digitalAss === void 0 ? void 0 : (_props$row$digitalAss2 = _props$row$digitalAss.organizationId) === null || _props$row$digitalAss2 === void 0 ? void 0 : _props$row$digitalAss2.name) || "--") + "\n          ")];
      }
    }], null, false, 1010092774)
  }), _vm._v(" "), _vm.isAdmin ? _c("b-table-column", {
    attrs: {
      label: "Editar",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          staticClass: "btn-edit",
          attrs: {
            "icon-right": "pencil"
          },
          on: {
            click: function click($event) {
              return _vm.openModalEditItem(props.row);
            }
          }
        })];
      }
    }], null, false, 1812007929)
  }) : _vm._e(), _vm._v(" "), _vm.isAdmin ? _c("b-table-column", {
    attrs: {
      label: "Excluir",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          staticClass: "btn-delete",
          attrs: {
            "icon-right": "delete"
          },
          on: {
            click: function click($event) {
              return _vm.confirmDelete(props.row);
            }
          }
        })];
      }
    }], null, false, 1892283845)
  }) : _vm._e()], 1)], 1) : _vm._e()])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;