"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm._v(_vm._s(_vm.prefix) + _vm._s(_vm.newValueFormatted) + _vm._s(_vm.suffix))]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;