"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-list-batch-processing-component"
  }, [_c("b-table", {
    attrs: {
      data: _vm.list
    }
  }, [[_c("b-table-column", {
    staticClass: "img-rounded-td",
    attrs: {
      centered: ""
    }
  }, [_c("div", {
    staticClass: "img-display"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icons/google-docs.png")
    }
  })])]), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Data de upload"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(_vm._f("dateTimeWithToday")(props.row.createdAt)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Unidade de négocio"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        var _props$row, _props$row$businessUn;
        return [_vm._v("\n        " + _vm._s(((_props$row = props.row) === null || _props$row === void 0 ? void 0 : (_props$row$businessUn = _props$row.businessUnit) === null || _props$row$businessUn === void 0 ? void 0 : _props$row$businessUn.name) || "--") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Serviço"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        var _props$row2, _props$row2$digitalAs;
        return [_vm._v("\n        " + _vm._s(((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$digitalAs = _props$row2.digitalAsset) === null || _props$row2$digitalAs === void 0 ? void 0 : _props$row2$digitalAs.name) || "--") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Tipo de processamento"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(_vm._f("translateProcessType")(props.row.processType)) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Usuário"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        var _props$row3, _props$row3$user, _props$row3$user$pers;
        return [_vm._v("\n        " + _vm._s(((_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : (_props$row3$user = _props$row3.user) === null || _props$row3$user === void 0 ? void 0 : (_props$row3$user$pers = _props$row3$user.personId) === null || _props$row3$user$pers === void 0 ? void 0 : _props$row3$user$pers.name) || "") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          class: {
            "text-danger": props.row.status === "awaiting_process",
            "text-warning": props.row.status === "file_processing",
            "text-success": props.row.status === "processed_file"
          }
        }, [_vm._v("\n          " + _vm._s(_vm._f("statusBatchProcessing")(props.row.status)) + "\n        ")])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Arquivo Original",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          attrs: {
            "icon-right": "download",
            disabled: props.row.status === "upload_fail"
          },
          on: {
            click: function click($event) {
              return _vm.actionOpen(props.row._id, "arquivo-original");
            }
          }
        })];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Qtd. Processados",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.processItems || "--") + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Arquivo Processado",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [props.row.status === "processed_file" && props.row.urlProcessedFile ? _c("b-button", {
          attrs: {
            "icon-right": "download"
          },
          on: {
            click: function click($event) {
              return _vm.actionOpen(props.row._id, "arquivo-processado");
            }
          }
        }) : _vm._e(), _vm._v(" "), props.row.status === "error_process" ? _c("b-tooltip", {
          attrs: {
            label: "Erro ao processar o arquivo",
            type: "is-light"
          }
        }, [_c("b-button", {
          attrs: {
            "icon-right": "alert-circle-outline"
          }
        })], 1) : _vm._e(), _vm._v(" "), props.row.status === "error_file" ? _c("b-tooltip", {
          attrs: {
            label: "Arquivo inválido ou mal formatado",
            type: "is-light"
          }
        }, [_c("b-button", {
          attrs: {
            "icon-right": "alert-circle-outline"
          }
        })], 1) : _vm._e(), _vm._v(" "), props.row.status === "upload_fail" ? _c("b-tooltip", {
          attrs: {
            label: "Falha no upload do arquivo. Realize uma nova tentativa",
            type: "is-light"
          }
        }, [_c("b-button", {
          attrs: {
            "icon-right": "alert-circle-outline"
          }
        })], 1) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _vm.isAdmin ? _c("b-table-column", {
    attrs: {
      label: "Ações",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-button", {
          attrs: {
            "icon-right": "dots-vertical"
          },
          on: {
            click: function click($event) {
              return _vm.openModalAction(props.row);
            }
          }
        })];
      }
    }], null, false, 3319290057)
  }) : _vm._e()]], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;