"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cleanArray = cleanArray;
exports.convertDate = convertDate;
exports.convertDateBR = convertDateBR;
exports.convertDateBirthBR = convertDateBirthBR;
exports.createBase64Image = createBase64Image;
exports.dateMonthYearFormat = dateMonthYearFormat;
exports.deepClone = deepClone;
exports.fixedActivities = fixedActivities;
exports.formatCPF = formatCPF;
exports.generateRandomName = generateRandomName;
exports.getFormData = getFormData;
exports.param = param;
exports.translateStatusProtocolHive = translateStatusProtocolHive;
exports.validStatusApuration = validStatusApuration;
var _validate = require("./validate");
var _moment = _interopRequireDefault(require("moment"));
var _constants = require("@/config/constants");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * @param {Array} actual
 * @returns {Array}
 */
function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}
var typesActivities = {
  PROCESSING: 'AWAITING',
  READY_TO_PROCESS: 'PROCESSING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR'
};

/**
 * @param {Array} array
 * @returns {Array}
 */
function fixedActivities(array) {
  return array && array.map(function (item) {
    var files = {
      file: _objectSpread(_objectSpread({}, item), {}, {
        sentDate: item.importedAt,
        generatedName: item.generatedName + '.' + item.extension,
        processingEndDate: item.completedAt,
        status: typesActivities[item.processingStatus],
        size: item.fileSize
      }),
      campaign: _objectSpread({}, item.campaign)
    };
    if (item.campaign) {
      files = _objectSpread(_objectSpread({}, files), {}, {
        campaign: _objectSpread({}, item.campaign)
      });
    }
    return files;
  });
}

/**
 * copy lodash _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
function deepClone(source) {
  if (!source || _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = {};
  targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * Format CPF mask
 * @param {string} str
 * @returns {Object}
 */
function formatCPF(str) {
  var cpf = str.toString();
  var strCPF = (0, _validate.replaceAll)(cpf, '.', '');
  strCPF = (0, _validate.replaceAll)(strCPF, '-', '');
  return strCPF;
}

/**
 * Conver Json to form-data
 * @param {string} str
 * @returns {Object}
 */
function getFormData(val) {
  var formData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new FormData();
  var namespace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return new Promise(function (resolve, reject) {
    if (typeof val !== 'undefined' && val !== null) {
      if (val instanceof Date) {
        formData.append(namespace, val.toISOString());
      } else if (val instanceof Array) {
        for (var i = 0; i < val.length; i++) {
          var element = val[i];
          getFormData(element, formData, namespace + "[".concat(i, "]"));
        }
      } else if (_typeof(val) === 'object' && !(val instanceof File)) {
        for (var propertyName in val) {
          if (val.hasOwnProperty(propertyName)) {
            getFormData(val[propertyName], formData, namespace ? namespace + '.' + propertyName : propertyName);
          }
        }
      } else if (val instanceof File) {
        formData.append(namespace, val);
      } else {
        formData.append(namespace, val.toString());
      }
    }
    return resolve(formData);
  });
}
function dateMonthYearFormat(value) {
  var date = (0, _moment.default)(String(value));
  return date.format('MM/YYYY');
}
function validStatusApuration(val) {
  var status = '';
  switch (val) {
    case 0:
      status = _constants.STATUS_APURATION.UNAPPROVED;
      break;
    case 1:
      status = _constants.STATUS_APURATION.PENDING;
      break;
    case 2:
      status = _constants.STATUS_APURATION.APPROVED;
      break;
    default:
      status = null;
      break;
  }
  return status;
}

/**
 * replace all strings
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
function convertDate(date) {
  if (date) {
    var d = date.split('/');
    var dataFormat = "".concat(d[2], "-").concat(d[1], "-").concat(d[0]);
    return (0, _moment.default)(dataFormat).toISOString();
  }
  return null;
}

/**
 * convert Date BR
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
function convertDateBR(date) {
  if (date) {
    return (0, _moment.default)(date).format('DD/MM/YYYY');
  }
  return '';
}
function convertDateBirthBR(date) {
  if (date) {
    return _moment.default.parseZone(date).format('DD/MM/YYYY');
  }
  return '';
}

/**
 * convert Date BR
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
function generateRandomName(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * convert file to base64
 * @param {string} date
 * @param {string} format
 * @returns {string}
 */
function createBase64Image(inputFile) {
  var temporaryFileReader = new FileReader();
  return new Promise(function (resolve, reject) {
    temporaryFileReader.onerror = function () {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };
    temporaryFileReader.onload = function () {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
}
function translateStatusProtocolHive(status, isWebhook) {
  var translate = '--';
  switch (status) {
    case _constants.C1_REQUEST_CREATED_HIVE:
      translate = !isWebhook ? _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C1_REQUEST_CREATED_HIVE] : _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C4_RESPONSE_RECEIVED_PROVIDER];
      break;
    case _constants.C2_REQUEST_CREATED_PROVIDER:
      translate = !isWebhook ? _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C2_REQUEST_CREATED_PROVIDER] : _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C5_RESPONSE_SENT_CLIENT];
      break;
    case _constants.C3_REQUEST_ERROR_PROVIDER:
      translate = !isWebhook ? _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C3_REQUEST_ERROR_PROVIDER] : _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C7_RESPONSE_NOT_RECEIVED_CLIENT];
      break;
    case _constants.C4_RESPONSE_RECEIVED_PROVIDER:
      translate = _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C4_RESPONSE_RECEIVED_PROVIDER];
      break;
    case _constants.C5_RESPONSE_SENT_CLIENT:
      translate = _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C5_RESPONSE_SENT_CLIENT];
      break;
    case _constants.C6_RESPONSE_ERROR_SENT_CLIENT:
      translate = _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C6_RESPONSE_ERROR_SENT_CLIENT];
      break;
    case _constants.C7_RESPONSE_NOT_RECEIVED_CLIENT:
      translate = _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C7_RESPONSE_NOT_RECEIVED_CLIENT];
      break;
    case _constants.C8_CACHE_RESPONSE_CLIENT:
      translate = _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C8_CACHE_RESPONSE_CLIENT];
      break;
    case _constants.C8_RESPONSE_VALIDATION_ERROR:
      translate = _constants.TYPES_TRANSACTIONAL_OPERATIONS[_constants.C8_RESPONSE_VALIDATION_ERROR];
      break;
  }
  return translate;
}