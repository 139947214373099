"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "columns is-mobile"
  }, [_c("div", {
    staticClass: "column"
  }, [!_vm.showWarning ? _c("div", [_c("div", {
    staticClass: "title"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v(_vm._s(_vm.titlePage))])]), _vm._v(" "), _c("div", {
    staticClass: "header-tab-options"
  }, _vm._l(_vm.steps, function (step, index) {
    return _c("div", {
      key: index,
      class: {
        active: _vm.activeStep === index
      }
    }, [_c("b-button", {
      on: {
        click: function click($event) {
          return _vm.setTab(index);
        }
      }
    }, [_vm._v("\n                " + _vm._s(step.label) + "\n              ")])], 1);
  }), 0), _vm._v(" "), _vm.activeStep === 0 ? _c("form-new-service", {
    attrs: {
      "show-warning": _vm.showWarning
    },
    on: {
      "update:showWarning": function updateShowWarning($event) {
        _vm.showWarning = $event;
      },
      "update:show-warning": function updateShowWarning($event) {
        _vm.showWarning = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.activeStep === 1 ? _c("form-document-service") : _vm._e(), _vm._v(" "), _vm.activeStep === 2 ? _c("form-documentation-description-api") : _vm._e(), _vm._v(" "), _vm.activeStep === 3 ? _c("form-documentation-faq-api") : _vm._e()], 1) : _c("div", [_c("warnings-text")], 1)])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;