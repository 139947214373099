"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("card", {
    staticClass: "form-faq"
  }, [_c("form", {
    attrs: {
      id: "customerDataForm"
    },
    on: {
      submit: _vm.checkForm
    }
  }, [_c("b-field", {
    attrs: {
      label: "Perguntas Frequentes"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "list-rates-requests"
  }, [_c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewQuestion
    }
  }), _vm._v(" "), _vm._l(_vm.listPerguntasRespostas, function (item, idx) {
    return _c("b-field", {
      key: idx,
      attrs: {
        grouped: ""
      }
    }, [_c("app-input", {
      attrs: {
        expanded: "",
        label: "Título - " + (idx + 1)
      },
      model: {
        value: item.title,
        callback: function callback($$v) {
          _vm.$set(item, "title", $$v);
        },
        expression: "item.title"
      }
    }), _vm._v(" "), _c("app-input", {
      attrs: {
        expanded: "",
        label: "Descrição - " + (idx + 1)
      },
      model: {
        value: item.description,
        callback: function callback($$v) {
          _vm.$set(item, "description", $$v);
        },
        expression: "item.description"
      }
    }), _vm._v(" "), _c("b-button", {
      staticClass: "remove-item",
      attrs: {
        "icon-right": "delete"
      },
      on: {
        click: function click($event) {
          return _vm.confirmDelete(idx, item);
        }
      }
    })], 1);
  })], 2), _vm._v(" "), _c("b-field", {
    staticClass: "field-buttons"
  }, [_c("b-button", {
    staticClass: "btn-save-button",
    attrs: {
      "native-type": "submit"
    }
  }, [_vm._v("\n        Salvar\n      ")])], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;