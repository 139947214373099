"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-table", {
    attrs: {
      data: _vm.list,
      "default-sort": "amount",
      "default-sort-direction": "desc",
      striped: "",
      hoverable: ""
    }
  }, [_c("b-table-column", {
    attrs: {
      label: "Serviço",
      field: "service"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n    " + _vm._s(props.row.service || "NÃO IDENTIFICADO") + "\n  ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Consumidor",
      field: "consumer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n    " + _vm._s(props.row.consumer || "--") + "\n  ")];
      }
    }])
  }), _vm._v(" "), _vm.http ? _c("b-table-column", {
    attrs: {
      label: "HTTP Status",
      field: "status",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n    " + _vm._s(props.row.status || "--") + "\n  ")];
      }
    }], null, false, 1169805897)
  }) : _vm._e(), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "QTD.",
      field: "amount",
      sortable: "",
      centered: "",
      width: "85"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticStyle: {
            "text-align": "right",
            "padding-right": "5px"
          }
        }, [_vm._v(_vm._s(_vm._f("maskNumber")(props.row.amount)))])];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "%",
      field: "percent",
      sortable: "",
      centered: "",
      width: "85"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("div", {
          staticStyle: {
            "text-align": "right",
            "padding-right": "5px"
          }
        }, [_vm._v("\n      " + _vm._s(props.row.percent) + "\n    ")])];
      }
    }])
  }), _vm._v(" "), _c("section", {
    staticClass: "section",
    attrs: {
      slot: "empty"
    },
    slot: "empty"
  }, [_c("div", {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("Sem registros até o momento…")])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;