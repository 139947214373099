"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _FormNewService = _interopRequireDefault(require("./components/FormNewService"));
var _FormDocumentService = _interopRequireDefault(require("./components/FormDocumentService"));
var _FormNewDocumentationDescriptionApi = _interopRequireDefault(require("./components/FormNewDocumentationDescriptionApi"));
var _FormNewDocumentationFaqApi = _interopRequireDefault(require("./components/FormNewDocumentationFaqApi"));
var _WarningsText = _interopRequireDefault(require("./components/WarningsText"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _default = exports.default = {
  name: 'NewServiceForm',
  bodyClass: 'new-service-update-page',
  components: {
    FormNewService: _FormNewService.default,
    WarningsText: _WarningsText.default,
    FormDocumentService: _FormDocumentService.default,
    FormDocumentationDescriptionApi: _FormNewDocumentationDescriptionApi.default,
    FormDocumentationFaqApi: _FormNewDocumentationFaqApi.default
  },
  data: function data() {
    return {
      showWarning: false,
      idService: null,
      activeStep: 0,
      steps: [{
        label: 'Dados do serviço'
      }]
    };
  },
  computed: {
    titlePage: function titlePage() {
      if (this.idService) return 'Atualizar serviço';
      return 'Novo serviço';
    }
  },
  mounted: function mounted() {
    var id = this.$route.params.id;
    if (id) {
      this.idService = id;
      this.steps = [{
        label: 'Dados do serviço'
      }, {
        label: 'Documentos'
      }, {
        label: 'Documentação API'
      }, {
        label: 'FAQ Documentação API'
      }];
    }
    this.fetchAllClients();
    this.fetchServiceTypes();
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)(['fetchAllClients', 'fetchServiceTypes'])), {}, {
    handleClick: function handleClick(val) {
      this.$router.push({
        path: "/founds"
      });
    },
    setTab: function setTab(index) {
      this.activeStep = index;
    }
  })
};