"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header-filter-components"
  }, [_c("app-input", {
    attrs: {
      label: _vm.label,
      placeholder: _vm.placeholder,
      expanded: ""
    },
    model: {
      value: _vm.computedValue,
      callback: function callback($$v) {
        _vm.computedValue = $$v;
      },
      expression: "computedValue"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;