"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "pagination",
    class: _vm.paginationClass
  }, [_c("li", {
    staticClass: "page-item prev-page",
    class: [{
      disabled: _vm.value === 1
    }, _vm.prevItemClasses]
  }, [_c("a", {
    staticClass: "page-link",
    attrs: {
      "aria-label": "Previous"
    },
    on: {
      click: _vm.prevPage
    }
  }, [_vm._t("prev", function () {
    return [_vm._v("»")];
  })], 2)]), _vm._v(" "), _vm._l(_vm.range(_vm.minPage, _vm.maxPage), function (item) {
    return _c("li", {
      key: item,
      staticClass: "page-item",
      class: [{
        active: _vm.value === item
      }, _vm.itemClasses]
    }, [_c("a", {
      staticClass: "page-link",
      on: {
        click: function click($event) {
          return _vm.changePage(item);
        }
      }
    }, [_vm._v(_vm._s(item))])]);
  }), _vm._v(" "), _c("li", {
    staticClass: "page-item page-pre next-page",
    class: [{
      disabled: _vm.value === _vm.totalPages
    }, _vm.nextItemClasses]
  }, [_c("a", {
    staticClass: "page-link",
    attrs: {
      "aria-label": "Next"
    },
    on: {
      click: _vm.nextPage
    }
  }, [_vm._t("next", function () {
    return [_vm._v("»")];
  })], 2)])], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;