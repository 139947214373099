"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "content"
  }, [_c("iframe", {
    staticClass: "template-portal",
    attrs: {
      src: _vm.uriPortal
    }
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;