"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-service-detail"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n          " + _vm._s(_vm.serviceType.name || "") + "\n        ")])])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column col-descriptions"
  }, [_c("div", {
    staticClass: "content-descriptions"
  }, [_c("h3", [_vm._v("Informações")]), _vm._v(" "), _c("p", [_vm._v("\n          " + _vm._s(_vm.serviceType.description || "") + "\n        ")])]), _vm._v(" "), _vm.detail.list ? _c("div", {
    staticClass: "content-certificates"
  }, [_c("h3", [_vm._v("Serviços vinculados")]), _vm._v(" "), _c("div", {
    staticClass: "list-certs"
  }, [_c("div", {
    staticClass: "columns is-multiline"
  }, _vm._l(_vm.list, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column is-one-quarter"
    }, [_c("card-certificate", {
      attrs: {
        title: item.name,
        logo: item.logo
      }
    })], 1);
  }), 0)])]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "column col-details"
  }, [_c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component card-sandbox"
  }, [_c("b-button", {
    staticClass: "detail",
    on: {
      click: _vm.openDetail
    }
  }, [_vm._v("\n            Editar\n          ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Nome do tipo de serviço")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.serviceType.name || ""))])])])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h3", [_vm._v("\n        Organização fornecedora\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "columns is-multiline"
  }, _vm._l(_vm.list, function (item, idx) {
    var _item$organizationId, _item$organizationId2;
    return _c("div", {
      key: idx * 4,
      staticClass: "column is-one-third"
    }, [_c("card-monitor", {
      attrs: {
        service: item === null || item === void 0 ? void 0 : (_item$organizationId = item.organizationId) === null || _item$organizationId === void 0 ? void 0 : _item$organizationId.name,
        logo: item === null || item === void 0 ? void 0 : (_item$organizationId2 = item.organizationId) === null || _item$organizationId2 === void 0 ? void 0 : _item$organizationId2.logo
      }
    })], 1);
  }), 0)])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;