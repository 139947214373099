"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-api-request-component"
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")])])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [!_vm.rows.length && !_vm.isLoading ? _c("div", {
    staticClass: "content-text-message"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon-checked-grenn.png")
    }
  }), _vm._v(" "), _c("h1", [_vm._v("\n        Sem requisições até o momento\n      ")])]) : _c("b-table", {
    ref: "multiSortTable",
    attrs: {
      data: _vm.rows,
      paginated: "",
      "backend-pagination": "",
      total: _vm.total,
      "per-page": _vm.perPage,
      selected: _vm.selected,
      "sort-icon": _vm.sortIcon,
      "sort-icon-size": _vm.sortIconSize,
      loading: _vm.isLoading,
      "aria-next-label": "Next page",
      "aria-previous-label": "Previous page",
      "aria-page-label": "Page",
      "aria-current-label": "Current page"
    },
    on: {
      "update:selected": function updateSelected($event) {
        _vm.selected = $event;
      },
      "page-change": _vm.onPageChange
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "service",
      label: "Serviço",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(_vm._f("toUpperCase")(props.row.routerName)) + "\n        ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "consumer_group",
      label: "Consumidor",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(props.row.consumer_group || "--") + "\n        ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "dateRequest",
      label: "Horário",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm.typeSelected === "transactions" ? _c("span", [_vm._v("\n            " + _vm._s(_vm._f("timestampToDate")(props.row.date_start)) + "\n          ")]) : _c("span", [_vm._v("\n            " + _vm._s(_vm._f("dateComplete")(props.row.date_start)) + "\n          ")])];
      }
    }])
  }), _vm._v(" "), _vm.typeSelected === "transactions" ? _c("b-table-column", {
    attrs: {
      field: "provider",
      label: "Provedor",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(props.row.provider || "--") + "\n        ")];
      }
    }], null, false, 384519516)
  }) : _vm._e(), _vm._v(" "), _vm.typeSelected === "transactions" ? _c("b-table-column", {
    attrs: {
      field: "latencie",
      label: "Latência",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s("".concat(props.row.latencie, " ms")) + "\n        ")];
      }
    }], null, false, 1030013628)
  }) : _c("b-table-column", {
    attrs: {
      field: "type_request",
      label: "Tipo de requisição"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n          " + _vm._s(_vm._f("typeTransaction")(props.row.http_status)) + "\n        ")];
      }
    }])
  }), _vm._v(" "), _vm.typeSelected === "transactions" ? _c("b-table-column", {
    attrs: {
      field: "http_status",
      label: "Status",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("small", {
          staticClass: "type-call success",
          class: {
            error: props.row.http_status >= 400,
            success: props.row.http_status < 300
          },
          attrs: {
            title: props.row.http_status
          }
        }, [_vm._v(_vm._s(props.row.http_status || 200))])];
      }
    }], null, false, 1003186442)
  }) : _vm._e()]], 2)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;