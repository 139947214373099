"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _MonitoringReportGeneral = _interopRequireDefault(require("./MonitoringReportGeneral.vue"));
var _MonitoringReportFinancial = _interopRequireDefault(require("./MonitoringReportFinancial"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  name: 'MonitoringReport',
  bodyClass: 'monitoring-reports-page',
  components: {
    MonitoringReportGeneral: _MonitoringReportGeneral.default,
    MonitoringReportFinancial: _MonitoringReportFinancial.default
  },
  data: function data() {
    return {
      tab: 0
    };
  }
};