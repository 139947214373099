"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.consumersService = void 0;
var _http = require("@/config/http");
var fetchAllLink = function fetchAllLink(status) {
  var params = null;
  if (status) {
    params = {
      Status: status
    };
  }
  return (0, _http.http)({
    url: "/businessUnits/assets/all",
    method: 'GET',
    params: params
  });
};
var fetchAllNotLink = function fetchAllNotLink(status) {
  var params = null;
  if (status) {
    params = {
      Status: status
    };
  }
  return (0, _http.http)({
    url: "/businessUnits/assets/available",
    method: 'GET',
    params: params
  });
};
var fetchConsumersAuthorizations = function fetchConsumersAuthorizations(status) {
  var params = null;
  if (status) {
    params = {
      Status: status
    };
  }
  return (0, _http.http)({
    url: "/businessUnitDigitalAsset/all",
    method: 'GET',
    params: params
  });
};
var update = function update(payload, id) {
  return (0, _http.http)({
    url: "/client/businessUnitDigitalAsset/".concat(id),
    method: 'PUT',
    data: payload
  });
};
var consumersService = exports.consumersService = {
  fetchAllNotLink: fetchAllNotLink,
  fetchAllLink: fetchAllLink,
  fetchConsumersAuthorizations: fetchConsumersAuthorizations,
  update: update
};