"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-service-detail"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v(" "), _vm.service.logo ? _c("img", {
    attrs: {
      src: _vm.service.logo,
      alt: "logo"
    }
  }) : _vm._e(), _vm._v("\n          " + _vm._s(_vm.service.name) + "\n        ")])])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column col-descriptions"
  }, [_c("div", {
    staticClass: "content-descriptions"
  }, [_c("h3", [_vm._v("Informações")]), _vm._v(" "), _c("p", [_vm._v("\n          " + _vm._s(_vm.service.description) + "\n        ")])]), _vm._v(" "), _vm.documents.length ? _c("div", {
    staticClass: "content-certificates"
  }, [_c("h3", [_vm._v("Documentos")]), _vm._v(" "), _c("div", {
    staticClass: "list-certs"
  }, _vm._l(_vm.documents, function (item, idx) {
    return _c("card-certificate", {
      key: idx,
      attrs: {
        title: item.name,
        logo: require("@/assets/images/icons/document.png"),
        link: item.link
      }
    });
  }), 1)]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "column is-two-fifths col-details"
  }, [_c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component card-sandbox"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v(_vm._s(_vm.service.name))]), _vm._v(" "), _vm.service.serviceIntegration || _vm.service.swagger ? _c("a", {
    staticClass: "detail",
    attrs: {
      rel: "noopener noreferrer"
    },
    on: {
      click: _vm.openSandbox
    }
  }, [_vm._v("\n            Testar em sandbox\n          ")]) : _vm._e(), _vm._v(" "), _vm.isAdmin ? _c("a", {
    staticClass: "detail",
    attrs: {
      rel: "noopener noreferrer"
    },
    on: {
      click: _vm.editService
    }
  }, [_vm._v("\n            Editar serviço\n          ")]) : _vm._e(), _vm._v(" "), _vm.showDocumentation && _vm.service.serviceIntegration ? _c("a", {
    staticClass: "detail",
    attrs: {
      rel: "noopener noreferrer"
    },
    on: {
      click: _vm.opendDocumentation
    }
  }, [_vm._v("\n            Documentação\n          ")]) : _vm._e(), _vm._v(" "), _c("a", {
    staticClass: "detail",
    attrs: {
      rel: "noopener noreferrer"
    },
    on: {
      click: _vm.opendBatchProcessing
    }
  }, [_vm._v("\n            Processamento em lote\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Nome do serviço")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.service.name))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "service"
    }
  }, [_vm._v("Tipo de serviço")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.typeService.name || "--"))])]), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "phone"
    }
  }, [_vm._v("Telefone do suporte")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("maskPhoneComplete")(_vm.service.phone)))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "mail"
    }
  }, [_vm._v("E-mail do suporte")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.service.email || "--"))])])])])]), _vm._v(" "), _vm._m(1)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "dateValid"
    }
  }, [_vm._v("Versão válida até:")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v("11/06/2022")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column col-support"
  }, [_c("h3", [_vm._v("\n        Suporte técnico\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "list-support"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "one"
  }, [_vm._v("Nome")]), _vm._v(" "), _c("div", {
    staticClass: "two"
  }, [_vm._v("Cargo")]), _vm._v(" "), _c("div", {
    staticClass: "three"
  }, [_vm._v("E-mail")]), _vm._v(" "), _c("div", {
    staticClass: "four"
  }, [_vm._v("Telefone")])]), _vm._v(" "), _c("div", {
    staticClass: "body"
  }, [_c("div", {
    staticClass: "row-item"
  }, [_c("div", {
    staticClass: "one"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/avatar-default.png"),
      alt: ""
    }
  }), _vm._v("\n              Pedro Paulo Albuquerque\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "two"
  }, [_vm._v("Gerente")]), _vm._v(" "), _c("div", {
    staticClass: "three"
  }, [_vm._v("pp.albuquerque@hiveplace.com.br")]), _vm._v(" "), _c("div", {
    staticClass: "four"
  }, [_vm._v("+55 61 99973-3676")])]), _vm._v(" "), _c("div", {
    staticClass: "row-item"
  }, [_c("div", {
    staticClass: "one"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/avatar-default.png"),
      alt: ""
    }
  }), _vm._v("\n              Ricardo Henrique Marinho O Reilly\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "two"
  }, [_vm._v("Gerente de Divisão")]), _vm._v(" "), _c("div", {
    staticClass: "three"
  }, [_vm._v("ricardo.henrique@hiveplace.com.br")]), _vm._v(" "), _c("div", {
    staticClass: "four"
  }, [_vm._v("+55 61 98115-9454")])])])])])]);
}];
render._withStripped = true;