"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-documents-table-components"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "list-content"
  }, _vm._l(_vm.documents, function (document, idx) {
    return _c("div", {
      key: idx,
      staticClass: "list-doc list-item"
    }, [_c("div", {
      staticClass: "col--one"
    }, [_c("h4", [_vm._v(_vm._s(document.send || "Falta o envio do documento"))]), _vm._v(" "), _c("h3", [_vm._v(_vm._s(document.baseDocumentName))])]), _vm._v(" "), _c("div", {
      staticClass: "col--two"
    }, [_c("span", {
      staticClass: "destaque-item",
      class: {
        ok: document.file,
        warning: !document.file
      }
    }, [_vm._v("\n          " + _vm._s(document.file ? "Tudo certo" : "Aguardando envio") + " "), _c("i", {
      class: "fas ".concat(document.file ? "fa-check" : "fa-times")
    })])]), _vm._v(" "), _c("div", {
      staticClass: "col--three"
    }, [_c("b-upload", {
      staticClass: "file-label",
      nativeOn: {
        input: function input($event) {
          return _vm.validUploadDocument($event, document);
        }
      },
      model: {
        value: document.file,
        callback: function callback($$v) {
          _vm.$set(document, "file", $$v);
        },
        expression: "document.file"
      }
    }, [_c("span", {
      staticClass: "file-cta"
    }, [_c("i", {
      staticClass: "fas fa-cloud-upload-alt"
    }), _vm._v(" "), _c("span", {
      staticClass: "file-label"
    }, [_vm._v("Inserir arquivo")])]), _vm._v(" "), document.file ? _c("span", {
      staticClass: "file-name"
    }, [_vm._v("\n            " + _vm._s(document.file.name) + "\n          ")]) : _vm._e()])], 1)]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-doc list-header"
  }, [_c("div", {
    staticClass: "col--one"
  }, [_vm._v("Documento")]), _vm._v(" "), _c("div", {
    staticClass: "col--two"
  }, [_vm._v("Status")]), _vm._v(" "), _c("div", {
    staticClass: "col--three"
  }, [_vm._v("Ações")])]);
}];
render._withStripped = true;