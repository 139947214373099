"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("card-component", [_c("div", {
    staticClass: "level is-mobile"
  }, [_c("div", {
    staticClass: "is-align-items-center is-flex content-card-widget"
  }, [_c("div", {
    staticClass: "is-widget-label"
  }, [!_vm.load ? _c("h3", {
    staticClass: "subtitle is-spaced has-text-centered"
  }, [_vm._v("\n          " + _vm._s(_vm.label) + "\n        ")]) : _c("h3", {
    staticClass: "subtitle is-spaced"
  }, [_c("b-skeleton", {
    attrs: {
      size: "is-large",
      active: _vm.load
    }
  })], 1), _vm._v(" "), !_vm.load ? _c("h1", {
    staticClass: "title"
  }, [_c("growing-number", {
    attrs: {
      value: _vm.number,
      prefix: _vm.prefix,
      suffix: _vm.suffix,
      "is-real": _vm.isReal
    }
  }), _vm._v(" "), _vm.errorPercent ? _c("span", {
    class: {
      danger: _vm.errorPercent > 5,
      success: _vm.errorPercent <= 5
    }
  }, [_vm._v("\n            | % " + _vm._s(_vm.errorPercent || 0) + "\n          ")]) : _vm._e()], 1) : _c("h1", {
    staticClass: "title"
  }, [_c("b-skeleton", {
    attrs: {
      active: _vm.load,
      size: "is-large",
      height: "60px",
      width: "60px",
      circle: ""
    }
  })], 1)])]), _vm._v(" "), _vm.icon ? _c("div", {
    staticClass: "level-item has-widget-icon is-centered"
  }, [_c("div", {
    staticClass: "is-widget-icon"
  }, [_c("b-icon", {
    attrs: {
      icon: _vm.icon,
      size: "is-large",
      type: _vm.type
    }
  })], 1)]) : _vm._e()])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;