"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n        " + _vm._s(_vm.namePage) + "\n      ")])]), _vm._v(" "), _vm.idUser ? _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card", {
    staticClass: "card-detail-image"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col col-img"
  }, [_vm.photoValid && !_vm.filePhoto ? _c("img", {
    staticClass: "img-raised",
    attrs: {
      src: _vm.form.imageUrl
    }
  }) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "col col-details-text"
  }, [_c("h2", [_vm._v(_vm._s(_vm.form.name))]), _vm._v(" "), _c("h4", [_vm._v(_vm._s(_vm.descriptionType))])])])])], 1)]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "columns cols-form"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("card", {
    staticClass: "card-form-profile p-4"
  }, [_c("h1", [_vm._v("DADOS PESSOAIS")]), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "CPF",
      placeholder: "Informe o seu CPF",
      mask: ["###.###.###-##"],
      error: _vm.$v.form.document.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.document, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.document.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.document, "$model", $$v);
      },
      expression: "$v.form.document.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Nome",
      placeholder: "Informe o nome",
      error: _vm.$v.form.name.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", $$v);
      },
      expression: "$v.form.name.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Data de nascimento",
      placeholder: "DD/MM/AAAA",
      mask: ["##/##/####"],
      masked: true,
      error: _vm.$v.form.birthdate.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.birthdate, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.birthdate.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.birthdate, "$model", $$v);
      },
      expression: "$v.form.birthdate.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Telefone",
      placeholder: "(00) 0 0000-0000",
      mask: ["(##) # ####-####"],
      error: _vm.$v.form.phone.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.phone, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.phone.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.phone, "$model", $$v);
      },
      expression: "$v.form.phone.$model"
    }
  }), _vm._v(" "), _c("app-select", {
    attrs: {
      placeholder: "Tipo de usuário",
      label: "Tipo de usuário",
      error: _vm.$v.form.role.$error,
      items: _vm.listTypes,
      option: "idItem",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.role, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.role.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.role, "$model", $$v);
      },
      expression: "$v.form.role.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Altera foto"
    }
  }, [_c("b-upload", {
    attrs: {
      "drag-drop": "",
      accept: "image/*"
    },
    model: {
      value: _vm.filePhoto,
      callback: function callback($$v) {
        _vm.filePhoto = $$v;
      },
      expression: "filePhoto"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.filePhoto ? _c("p", [_vm._v("Busque ou arraste o arquivo de imagem aqui")]) : _c("p", [_vm._v(_vm._s(_vm.filePhoto.name || "Arquivo selecionado"))])], 1)])])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("card", {
    staticClass: "card-form-profile p-4"
  }, [_c("h1", [_vm._v("DADOS DE ACESSO")]), _vm._v(" "), _c("app-input", {
    attrs: {
      expanded: "",
      label: "E-mail",
      placeholder: "Informe o e-mail",
      error: _vm.$v.form.email.$error,
      type: "email",
      name: "e-mail-user",
      complete: "new-password"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.email, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.email.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.email, "$model", $$v);
      },
      expression: "$v.form.email.$model"
    }
  }), _vm._v(" "), !_vm.idUser ? _c("app-input", {
    attrs: {
      expanded: "",
      label: "Senha",
      placeholder: "*******",
      error: _vm.$v.form.password.$error,
      type: "password",
      complete: "new-password"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.password, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.password.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.password, "$model", $$v);
      },
      expression: "$v.form.password.$model"
    }
  }) : _vm._e(), _vm._v(" "), _c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Status",
      error: _vm.$v.form.activated.$error,
      items: _vm.listStatus,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.activated, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.activated.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.activated, "$model", $$v);
      },
      expression: "$v.form.activated.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _vm.idUser ? _c("div", {
    staticClass: "buttons"
  }, [_c("b-button", {
    attrs: {
      type: "is-primary",
      expanded: ""
    },
    on: {
      click: _vm.openModalPass
    }
  }, [_vm._v("Alterar senha")])], 1) : _vm._e()], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "cols-btns-list"
  }, [_c("b-button", {
    staticClass: "button is-info",
    on: {
      click: _vm.saveData
    }
  }, [_vm._v("Salvar")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;