"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-users-list"
  }, [_c("div", [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h4", [_vm._v("Total de consultas: " + _vm._s(_vm.total))])])]), _vm._v(" "), _vm.listBond.length ? _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-table", {
    ref: "multiSortTable",
    attrs: {
      data: _vm.listBond,
      paginated: "",
      "backend-pagination": "",
      total: _vm.total,
      "per-page": _vm.perPage,
      selected: _vm.selected,
      "sort-icon": _vm.sortIcon,
      "sort-icon-size": _vm.sortIconSize,
      loading: _vm.isLoading,
      "pagination-rounded": true,
      "aria-next-label": "Próxima página",
      "aria-previous-label": "Página anterior",
      "aria-page-label": "Página",
      "aria-current-label": "Página atual"
    },
    on: {
      "update:selected": function updateSelected($event) {
        _vm.selected = $event;
      },
      "page-change": _vm.onPageChange
    }
  }, [[_c("b-table-column", {
    attrs: {
      field: "service",
      label: "CPF",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n              " + _vm._s(props.row.document) + "\n            ")];
      }
    }], null, false, 4236805298)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "service",
      label: "Data da Consulta",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n              " + _vm._s(_vm._f("dateBrazil")(props.row.createdAt)) + "\n            ")];
      }
    }], null, false, 2097724523)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "name",
      label: "Processado",
      sortable: "",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n              " + _vm._s(props.row.processed ? "Sim" : "Não") + "\n            ")];
      }
    }], null, false, 3940750375)
  }), _vm._v(" "), _c("b-table-column", {
    attrs: {
      field: "name",
      label: "Tipo de Consulta",
      sortable: "",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n              " + _vm._s(props.row.type) + "\n            ")];
      }
    }], null, false, 3062963749)
  })]], 2)], 1)]) : _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 ml-auto col-xl-12 mr-auto"
  }, [_c("card", [_c("div", {
    staticClass: "content-list"
  }, [_c("h4", {
    staticClass: "text-not-found"
  }, [_vm._v("\n              Não temos usuários registrados no o momento.\n            ")])])])], 1)])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;