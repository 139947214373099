"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$personalData;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n      Força de Vínculo\n    ")])]), _vm._v(" "), _c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "header-personal-infos"
  }, [_c("div", [_vm._v("CPF: "), _c("strong", [_vm._v(_vm._s(_vm.cpf))])]), _vm._v(" "), _c("div", [_vm._v("Celular: "), _c("strong", [_vm._v(_vm._s(_vm.phone))])]), _vm._v(" "), _c("div", [_vm._v("CEP: "), _c("strong", [_vm._v(_vm._s(_vm.zipCode))])]), _vm._v(" "), _c("div", [_vm._v("Email: "), _c("strong", [_vm._v(_vm._s((_vm$personalData = _vm.personalData) === null || _vm$personalData === void 0 ? void 0 : _vm$personalData.email))])])])]), _vm._v(" "), _c("div", {
    staticClass: "personal-infos"
  }, [_c("div", {
    staticStyle: {
      width: "390px"
    }
  }, [_c("div", {
    staticClass: "diamond-container"
  }, [_c("canvas", {
    attrs: {
      id: "diamond-canvas",
      width: "343",
      height: "330"
    }
  })])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;