"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Collapse',
  props: {
    animationDuration: {
      type: Number,
      default: 250
    },
    multipleActive: {
      type: Boolean,
      default: true
    },
    activeIndex: {
      type: Number,
      default: -1
    }
  },
  provide: function provide() {
    return {
      animationDuration: this.animationDuration,
      multipleActive: this.multipleActive,
      addItem: this.addItem,
      removeItem: this.removeItem,
      deactivateAll: this.deactivateAll
    };
  },
  data: function data() {
    return {
      items: []
    };
  },
  watch: {
    activeIndex: function activeIndex() {
      this.activateItem();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.activateItem();
    });
  },
  methods: {
    addItem: function addItem(item) {
      var index = this.$slots.default.indexOf(item.$vnode);
      if (index !== -1) {
        this.items.splice(index, 0, item);
      }
    },
    removeItem: function removeItem(item) {
      var items = this.items;
      var index = items.indexOf(item);
      if (index > -1) {
        items.splice(index, 1);
      }
    },
    deactivateAll: function deactivateAll() {
      this.items.forEach(function (item) {
        item.active = false;
      });
    },
    activateItem: function activateItem() {
      if (this.activeIndex !== -1) {
        this.items[this.activeIndex].active = true;
      }
    }
  }
};