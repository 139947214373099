"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CardDossie", {
  enumerable: true,
  get: function get() {
    return _CardDossie.default;
  }
});
Object.defineProperty(exports, "CardPersonalData", {
  enumerable: true,
  get: function get() {
    return _CardPersonalData.default;
  }
});
Object.defineProperty(exports, "CardValidaDocuments", {
  enumerable: true,
  get: function get() {
    return _CardValidaDocuments.default;
  }
});
Object.defineProperty(exports, "CardValidaFace", {
  enumerable: true,
  get: function get() {
    return _CardValidaFace.default;
  }
});
Object.defineProperty(exports, "CardValidaFinger", {
  enumerable: true,
  get: function get() {
    return _CardValidaFinger.default;
  }
});
var _CardPersonalData = _interopRequireDefault(require("./CardPersonalData"));
var _CardValidaFace = _interopRequireDefault(require("./CardValidaFace"));
var _CardValidaFinger = _interopRequireDefault(require("./CardValidaFinger"));
var _CardValidaDocuments = _interopRequireDefault(require("./CardValidaDocuments"));
var _CardDossie = _interopRequireDefault(require("./CardDossie"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }