"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-task-components card"
  }, [_c("p", [_vm._v("Lista de tarefas atribuidas")]), _vm._v(" "), _c("div", {
    staticClass: "col-md-10 col-xs-10"
  }, [_c("div", {
    staticClass: "menu d-flex justify-content-start"
  }, [_c("ul", {
    attrs: {
      id: "menu"
    }
  }, _vm._l(_vm.steps, function (step, index) {
    return _c("li", {
      key: index,
      class: {
        selected: _vm.current === step.id
      },
      on: {
        click: function click($event) {
          return _vm.setCurrent(step.id);
        }
      }
    }, [_c("b", [_vm._v(_vm._s(step.label))])]);
  }), 0)])]), _vm._v(" "), _c("b-table", {
    attrs: {
      data: _vm.display
    },
    on: {
      click: _vm.redirectTaskDetail
    }
  }, [[_c("b-table-column", {
    staticClass: "b-table-column",
    attrs: {
      field: "identificador",
      label: "Tarefa",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.tarefa) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    staticClass: "b-table-column",
    attrs: {
      field: "date_add",
      label: "Processo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.processo) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    staticClass: "b-table-column",
    attrs: {
      field: "description",
      label: "Solicitante",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("b-icon", {
          attrs: {
            icon: "account-circle-outline"
          }
        })];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    staticClass: "b-table-column",
    attrs: {
      field: "date_add",
      label: "Estágio"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.estagio) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    staticClass: "b-table-column",
    attrs: {
      field: "situacao",
      label: "Modificação de estágio",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.modificacao) + "\n      ")];
      }
    }])
  }), _vm._v(" "), _c("b-table-column", {
    staticClass: "b-table-column",
    attrs: {
      field: "situacao",
      label: "Responsável",
      centered: ""
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "account-circle-outline"
    }
  })], 1), _vm._v(" "), _c("b-table-column", {
    staticClass: "b-table-column",
    attrs: {
      label: "Status",
      centered: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n        " + _vm._s(props.row.status) + "\n      ")];
      }
    }])
  })]], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;