"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-warnning-texted"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon-checked-grenn.png")
    }
  }), _vm._v(" "), _c("h2", [_vm._v("\n    Serviço criado com sucesso!\n  ")]), _vm._v(" "), _c("b-button", {
    staticClass: "btn-save-button",
    on: {
      click: _vm.redirect
    }
  }, [_vm._v("\n    Ir para o monitor de conexões\n  ")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;