"use strict";

var _vue = _interopRequireDefault(require("vue"));
var _store = _interopRequireDefault(require("@/store"));
var _validate = require("@/utils/validate");
var _settings = _interopRequireDefault(require("@/settings"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// you can set in settings.js
var needErrorLog = _settings.default.errorLog;
function checkNeed() {
  var env = process.env.NODE_ENV;
  if ((0, _validate.isString)(needErrorLog)) {
    return env === needErrorLog;
  }
  if ((0, _validate.isArray)(needErrorLog)) {
    return needErrorLog.includes(env);
  }
  return false;
}
if (checkNeed()) {
  _vue.default.config.errorHandler = function (err, vm, info, a) {
    _vue.default.nextTick(function () {
      _store.default.dispatch('errorLog/addErrorLog', {
        err: err,
        vm: vm,
        info: info,
        url: window.location.href
      });
      console.error(err, info);
    });
  };
}