"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'ModalFinisher',
  props: {
    text: {
      type: String,
      default: ''
    },
    idItem: {
      type: [String, Number],
      default: null
    },
    action: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      solved: '',
      description: ''
    };
  },
  computed: {
    isFinish: function isFinish() {
      if (!this.solved) {
        return false;
      }
      if (this.solved === 'not' && !this.description) {
        return false;
      }
      return true;
    }
  },
  methods: {
    cancel: function cancel() {
      this.$parent.close();
    },
    confirm: function confirm() {
      this.$parent.close();
      this.action({
        description: this.description,
        solved: this.solved
      });
    }
  }
};