"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _errorResponse = require("./error-response.helper");
Object.keys(_errorResponse).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _errorResponse[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _errorResponse[key];
    }
  });
});
var _message = require("./message.helper");
Object.keys(_message).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _message[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _message[key];
    }
  });
});