"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _nouislider = _interopRequireDefault(require("nouislider"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _default2 = exports.default = {
  name: 'Slider',
  props: {
    value: {
      type: [String, Array, Number],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    start: {
      type: [Number, Array],
      default: 0
    },
    connect: {
      type: [Boolean, Array],
      default: function _default() {
        return [true, false];
      }
    },
    range: {
      type: Object,
      default: function _default() {
        return {
          min: 0,
          max: 100
        };
      }
    },
    type: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      slider: null
    };
  },
  watch: {
    value: function value(newValue, oldValue) {
      var slider = this.$el.noUiSlider;
      var sliderValue = slider.get();
      if (newValue !== oldValue && sliderValue !== newValue) {
        if (Array.isArray(sliderValue) && Array.isArray(newValue)) {
          if (oldValue.length === newValue.length && oldValue.every(function (v, i) {
            return v === newValue[i];
          })) {
            slider.set(newValue);
          }
        } else {
          slider.set(newValue);
        }
      }
    }
  },
  mounted: function mounted() {
    this.createSlider();
  },
  methods: {
    createSlider: function createSlider() {
      var _this = this;
      _nouislider.default.create(this.$el, _objectSpread({
        start: this.value || this.start,
        connect: this.connect,
        range: this.range
      }, this.options));
      var slider = this.$el.noUiSlider;
      slider.on('slide', function () {
        var value = slider.get();
        if (value !== _this.value) {
          _this.$emit('input', value);
        }
      });
    }
  }
};