"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("@/components");
var _vuex = require("vuex");
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _default = exports.default = {
  name: 'ServicesList',
  bodyClass: 'services-list-page',
  components: {
    CardMonitor: _components.CardMonitor,
    FieldSearchInput: _components.FieldSearchInput
  },
  data: function data() {
    return {
      filter: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['listServices'])), {}, {
    listServicesAll: function listServicesAll() {
      return _lodash.default.orderBy(this.listServices, ['name']);
    },
    list: function list() {
      var _this = this;
      if (this.filter.length) {
        return this.listServicesAll.filter(function (p) {
          return p.name.toUpperCase().indexOf(_this.filter.toUpperCase()) >= 0;
        });
      }
      return this.listServicesAll;
    }
  }),
  created: function created() {
    this.fetchAllServices();
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)(['fetchAllServices'])), {}, {
    getMillisecongs: function getMillisecongs() {
      return Math.floor(Math.random() * 10);
    },
    openDetailService: function openDetailService(item) {
      this.$router.push({
        path: "/connection-service-detail/".concat(item._id)
      });
    }
  })
};