"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "modal-card"
  }, [_c("header", {
    staticClass: "modal-card-head"
  }, [_c("p", {
    staticClass: "modal-card-title"
  }, [_vm._v("Configuração de regra")]), _vm._v(" "), _c("button", {
    staticClass: "delete",
    attrs: {
      type: "button"
    },
    on: {
      click: _vm.close
    }
  })]), _vm._v(" "), _c("section", {
    staticClass: "modal-card-body"
  }, [_c("form", {
    attrs: {
      id: "formRegras"
    }
  }, [_c("div", {
    staticClass: "content"
  }, [_c("table", [_vm._m(0), _vm._v(" "), _c("tbody", [_c("tr", [_c("td", [_c("app-input", {
    attrs: {
      label: "Regra de aprovação (Maior que)",
      placeholder: "Maior que...",
      error: _vm.$v.form.facematch.approve.$error,
      type: "number"
    },
    model: {
      value: _vm.$v.form.facematch.approve.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.facematch.approve, "$model", $$v);
      },
      expression: "$v.form.facematch.approve.$model"
    }
  })], 1), _vm._v(" "), _c("td", [_c("app-input", {
    attrs: {
      label: "Regra de reprovação (Menor que)",
      placeholder: "Menor que...",
      error: _vm.$v.form.facematch.disapprove.$error,
      type: "number"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.facematch.disapprove, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.facematch.disapprove.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.facematch.disapprove, "$model", $$v);
      },
      expression: "$v.form.facematch.disapprove.$model"
    }
  })], 1)])])]), _vm._v(" "), _c("table", [_vm._m(1), _vm._v(" "), _c("tbody", [_c("tr", [_c("td", [_c("app-input", {
    attrs: {
      label: "Regra de aprovação (Maior que)",
      placeholder: "Maior que...",
      error: _vm.$v.form.documentoscopia.approve.$error,
      type: "number"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.documentoscopia.approve, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.documentoscopia.approve.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.documentoscopia.approve, "$model", $$v);
      },
      expression: "$v.form.documentoscopia.approve.$model"
    }
  })], 1), _vm._v(" "), _c("td", [_c("app-input", {
    attrs: {
      label: "Regra de reprovação (Menor que)",
      placeholder: "Menor que...",
      error: _vm.$v.form.documentoscopia.disapprove.$error,
      type: "number"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.documentoscopia.disapprove, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.documentoscopia.disapprove.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.documentoscopia.disapprove, "$model", $$v);
      },
      expression: "$v.form.documentoscopia.disapprove.$model"
    }
  })], 1)])])]), _vm._v(" "), _c("table", [_vm._m(2), _vm._v(" "), _c("tbody", [_c("tr", [_c("td", [_c("app-input", {
    attrs: {
      label: "Regra de aprovação (Maior que)",
      placeholder: "Maior que...",
      error: _vm.$v.form.liveness.approve.$error,
      type: "number"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.liveness.approve, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.liveness.approve.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.liveness.approve, "$model", $$v);
      },
      expression: "$v.form.liveness.approve.$model"
    }
  })], 1), _vm._v(" "), _c("td", [_c("app-input", {
    attrs: {
      label: "Regra de reprovação (Menor que)",
      placeholder: "Menor que...",
      error: _vm.$v.form.liveness.disapprove.$error,
      type: "number"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.liveness.disapprove, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.liveness.disapprove.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.liveness.disapprove, "$model", $$v);
      },
      expression: "$v.form.liveness.disapprove.$model"
    }
  })], 1)])])]), _vm._v(" "), _c("table", [_vm._m(3), _vm._v(" "), _c("tbody", [_c("tr", [_c("td", [_c("app-input", {
    attrs: {
      label: "Regra de aprovação (Maior que)",
      placeholder: "Maior que...",
      error: _vm.$v.form.score.approve.$error,
      type: "number"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.score.approve, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.score.approve.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.score.approve, "$model", $$v);
      },
      expression: "$v.form.score.approve.$model"
    }
  })], 1), _vm._v(" "), _c("td", [_c("app-input", {
    attrs: {
      label: "Regra de reprovação (Menor que)",
      placeholder: "Menor que...",
      error: _vm.$v.form.score.disapprove.$error,
      type: "number"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.score.disapprove, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.score.disapprove.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.score.disapprove, "$model", $$v);
      },
      expression: "$v.form.score.disapprove.$model"
    }
  })], 1)])])])])]), _vm._v(" "), _c("div", {
    staticClass: "content-btns is-flex is-justify-content-flex-end is-align-content-flex-end mt-6"
  }, [_c("b-button", {
    attrs: {
      type: "is-success"
    },
    on: {
      click: _vm.checkForm
    }
  }, [_vm._v("\n        SALVAR\n      ")])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("thead", [_c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("FACEMATCH")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("thead", [_c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("DOCUMENTOSCOPIA")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("thead", [_c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("LIVENESS")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("thead", [_c("tr", [_c("th", {
    attrs: {
      colspan: "2"
    }
  }, [_vm._v("SCORE")])])]);
}];
render._withStripped = true;