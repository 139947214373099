"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = exports.default = {
  name: 'GrowingNumber',
  props: {
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    value: {
      type: [Number, String],
      default: 0
    },
    duration: {
      type: Number,
      default: 500
    },
    isReal: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      newValue: 0
    };
  },
  computed: {
    newValueFormatted: function newValueFormatted() {
      if (this.isReal) return _vue.default.filter('maskMoney')(this.newValue);
      return this.$options.filters.maskNumber(this.newValue);
    }
  },
  watch: {
    value: function value() {
      this.growInit();
    }
  },
  mounted: function mounted() {
    this.growInit();
  },
  methods: {
    grow: function grow(m) {
      var _this = this;
      var v = Math.ceil(this.newValue + m);
      if (v > this.value) {
        this.newValue = this.value;
        return false;
      }
      this.newValue = v;
      setTimeout(function () {
        _this.grow(m);
      }, 25);
    },
    growInit: function growInit() {
      this.grow(this.value / (this.duration / 25));
    }
  }
};