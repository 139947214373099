"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-customer-data-form-client"
  }, [_c("form", {
    attrs: {
      id: "customerDataForm"
    },
    on: {
      submit: _vm.saveClient
    }
  }, [_c("card", {
    staticClass: "card-customer-finished-form-prospect-component"
  }, [_c("h1", [_vm._v("Dados do cliente")]), _vm._v(" "), _c("app-select", {
    attrs: {
      placeholder: "Selecione o tipo de cliente",
      label: "Tipo de cliente",
      error: _vm.$v.form.organizationType.$error,
      items: _vm.listTypes,
      option: "id",
      "name-item": "name",
      disabled: _vm.isBlocked
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.organizationType, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.organizationType.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.organizationType, "$model", $$v);
      },
      expression: "$v.form.organizationType.$model"
    }
  }), _vm._v(" "), _vm.form.organizationType ? _c("div", [_vm.isPF ? _c("app-input", {
    attrs: {
      label: "CPF",
      placeholder: "Informe o CPF do cliente",
      mask: ["###.###.###-##"],
      error: _vm.$v.form.document.$error,
      disabled: _vm.isBlocked
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.document, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.document.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.document, "$model", $$v);
      },
      expression: "$v.form.document.$model"
    }
  }) : _vm._e(), _vm._v(" "), _vm.isPJ ? _c("app-input", {
    attrs: {
      label: "CNPJ",
      placeholder: "Informe o CNPJ do cliente",
      mask: ["##.###.###/####-##"],
      error: _vm.$v.form.document.$error,
      disabled: _vm.isBlocked
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.document, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.document.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.document, "$model", $$v);
      },
      expression: "$v.form.document.$model"
    }
  }) : _vm._e(), _vm._v(" "), _c("app-input", {
    attrs: {
      label: _vm.labelNameClient,
      placeholder: _vm.placeNameClient,
      error: _vm.$v.form.businessName.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.businessName, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.businessName.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.businessName, "$model", $$v);
      },
      expression: "$v.form.businessName.$model"
    }
  }), _vm._v(" "), _vm.isPJ ? _c("app-input", {
    attrs: {
      label: "Nome Fantasia",
      placeholder: "Informe o nome fantasia",
      error: _vm.$v.form.name.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.name, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.name.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.name, "$model", $$v);
      },
      expression: "$v.form.name.$model"
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "E-mail do cliente",
      placeholder: "Informe o e-mail do cliente",
      error: _vm.$v.form.email.$error,
      type: "email"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.email, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.email.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.email, "$model", $$v);
      },
      expression: "$v.form.email.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      label: "Telefone",
      placeholder: "+00 (00) 00000 - 0000",
      mask: ["+## (##) ##### - ####", "+## (##) #### - ####"],
      error: _vm.$v.form.phone.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.phone, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.phone.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.phone, "$model", $$v);
      },
      expression: "$v.form.phone.$model"
    }
  })], 1)]), _vm._v(" "), _c("app-input", {
    attrs: {
      placeholder: "CEP",
      label: "CEP",
      mask: ["##.###-###"],
      error: _vm.$v.form.zipCode.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.zipCode, "$error", $event);
      }
    },
    nativeOn: {
      change: function change($event) {
        return _vm.consultzipcode.apply(null, arguments);
      }
    },
    model: {
      value: _vm.$v.form.zipCode.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.zipCode, "$model", $$v);
      },
      expression: "$v.form.zipCode.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      placeholder: "Número do endereço",
      label: "Número do endereço",
      error: _vm.$v.form.addressNumber.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.addressNumber, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.addressNumber.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.addressNumber, "$model", $$v);
      },
      expression: "$v.form.addressNumber.$model"
    }
  }), _vm._v(" "), _c("app-input", {
    attrs: {
      placeholder: "Logradouro",
      label: "Logradouro",
      error: _vm.$v.form.address.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.address, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.address.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.address, "$model", $$v);
      },
      expression: "$v.form.address.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Bairro",
      placeholder: "Informe o bairro da residência",
      error: _vm.$v.form.district.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.district, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.district.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.district, "$model", $$v);
      },
      expression: "$v.form.district.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Cidade",
      placeholder: "Informe a cidade da residência",
      error: _vm.$v.form.city.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.city, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.city.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.city, "$model", $$v);
      },
      expression: "$v.form.city.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "UF",
      placeholder: "Informe a UF da residência",
      mask: ["AA"],
      error: _vm.$v.form.state.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.state, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.state.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.state, "$model", $$v);
      },
      expression: "$v.form.state.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Selecione o País",
      label: "País",
      error: _vm.$v.form.country.$error,
      items: _vm.listCountries,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.country, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.country.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.country, "$model", $$v);
      },
      expression: "$v.form.country.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Selecione o idioma",
      label: "Idioma",
      error: _vm.$v.form.language.$error,
      items: _vm.listLanguages,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.language, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.language.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.language, "$model", $$v);
      },
      expression: "$v.form.language.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Selecione API validação de documento",
      label: "API validação de documento",
      error: _vm.$v.form.componentValidDocument.$error,
      items: _vm.listAPIValidDocument,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.componentValidDocument, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.componentValidDocument.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.componentValidDocument, "$model", $$v);
      },
      expression: "$v.form.componentValidDocument.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Site da organização",
      placeholder: "Site da organização",
      error: _vm.$v.form.site.$error,
      type: "url"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.site, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.site.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.site, "$model", $$v);
      },
      expression: "$v.form.site.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Setor de atuação",
      placeholder: "Setor de atuação",
      error: _vm.$v.form.setor.$error
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.setor, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.setor.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.setor, "$model", $$v);
      },
      expression: "$v.form.setor.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Quantidade de funcionários",
      placeholder: "Total de funcionários",
      error: _vm.$v.form.size.$error,
      mask: ["#####"]
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.size, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.size.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.size, "$model", $$v);
      },
      expression: "$v.form.size.$model"
    }
  })], 1)]), _vm._v(" "), _c("app-input", {
    attrs: {
      label: "Descrição do cliente",
      placeholder: "Descreva brevemente",
      error: _vm.$v.form.description.$error,
      type: "textarea",
      max: "1500"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.description, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.description.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.description, "$model", $$v);
      },
      expression: "$v .form.description.$model"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _c("b-field", {
    class: _vm.$v.form.logo.$error ? "is-danger" : "",
    attrs: {
      label: "Logomarca"
    }
  }, [_c("b-upload", {
    attrs: {
      "drag-drop": "",
      accept: "image/*"
    },
    model: {
      value: _vm.fileLogo,
      callback: function callback($$v) {
        _vm.fileLogo = $$v;
      },
      expression: "fileLogo"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.fileLogo ? _c("p", [_vm._v("Busque ou arraste o arquivo de imagem aqui")]) : _c("p", [_vm._v(_vm._s(_vm.fileLogo.name || "Arquivo selecionado"))])], 1)]), _vm._v(" "), _vm.logoValid && !_vm.fileLogo ? _c("img", {
    attrs: {
      src: _vm.form.logo,
      alt: "Logotipo"
    }
  }) : _vm._e()])], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-buttons"
  }, [_c("b-button", {
    staticClass: "btn-save-button",
    on: {
      click: _vm.saveClient
    }
  }, [_vm._v("\n        Salvar\n      ")])], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;