"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowFooter,
      expression: "isShowFooter"
    }],
    staticClass: "footer-container",
    attrs: {
      id: "div-footer-component"
    }
  }, [_c("div", {
    staticClass: "footer-left"
  }, [_c("p", {
    staticClass: "subtitle",
    attrs: {
      id: "p-footer-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.$t("layout.footer.subtitle")))]), _vm._v(" "), _c("p", {
    staticClass: "title",
    attrs: {
      id: "p-footer-title"
    }
  }, [_vm._v(_vm._s(_vm.$t("layout.footer.title")))]), _vm._v(" "), _c("p", {
    staticClass: "tag-version"
  }, [_vm._v("V. " + _vm._s(_vm.version))])]), _vm._v(" "), _c("div", {
    staticClass: "footer-right"
  }, [_c("img", {
    attrs: {
      id: "img-footer-logo",
      src: require("@/assets/images/logo.png")
    }
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;