"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.item.hidden ? _c("div", {
    staticClass: "menu-wrapper",
    attrs: {
      id: "div-sidebarItem"
    }
  }, [[_c("app-link", {
    attrs: {
      to: _vm.item.path
    }
  }, [_c("el-menu-item", {
    staticClass: "menu-title-destaque",
    attrs: {
      index: _vm.item.path
    }
  }, [_c("item", {
    attrs: {
      icon: _vm.item.icon,
      title: _vm.item.title,
      "is-mobile": _vm.isMobile,
      collapse: _vm.collapse
    }
  })], 1)], 1)], _vm._v(" "), _c("div", {
    ref: "subMenu",
    staticClass: "menu-wrapper",
    attrs: {
      id: "div-sidebar-menuItem",
      index: _vm.resolvePath(_vm.item.path)
    }
  }, [_vm.item.children && _vm.item.children.length ? _c("li", {
    staticClass: "el-submenu",
    attrs: {
      role: "menuitem"
    }
  }, [_c("ul", {
    staticClass: "el-menu el-menu--inline",
    attrs: {
      role: "menu"
    }
  }, _vm._l(_vm.item.children, function (childItem) {
    return _c("div", {
      key: childItem.path,
      staticClass: "menu-wrapper nest-menu",
      attrs: {
        id: "div-sidebarItem"
      }
    }, [_c("router-link", {
      staticClass: "active router-link-active",
      attrs: {
        to: childItem.path
      }
    }, [_c("li", {
      staticClass: "el-menu-item submenu-destaque",
      attrs: {
        role: "menuitem",
        tabindex: "-1"
      }
    }, [childItem.icon ? _c("span", {
      staticClass: "icon",
      attrs: {
        id: "span-sidebar-menuItem-icon"
      }
    }, [_c("i", {
      class: childItem.icon
    })]) : _vm._e(), _vm._v(" "), _c("span", {
      staticClass: "titlte-text",
      attrs: {
        id: "span-sidebar-menuItem-title"
      }
    }, [_vm._v("\n                " + _vm._s(childItem.title) + "\n              ")])])])], 1);
  }), 0)]) : _vm._e()])], 2) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;