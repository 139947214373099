"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-pro-det"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-xxl-3"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v(" "), _vm.client.imageUrl ? _c("img", {
    attrs: {
      src: _vm.client.imageUrl,
      alt: "logo"
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/images/logos/logo-default.png"),
      alt: "logo"
    }
  }), _vm._v("\n              " + _vm._s(_vm.client.name) + "\n            ")])])])])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column col-descriptions"
  }, [_c("div", {
    staticClass: "content-descriptions"
  }, [_c("h3", [_vm._v("\n          Clientes vinculados\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "list-support"
  }, [_vm.showMore ? _c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewOrganizationPerson
    }
  }) : _vm._e(), _vm._v(" "), _c("table-list-clients-organization", {
    attrs: {
      list: _vm.listOrganizationPersons,
      "action-delete": _vm.confirmDelete,
      "show-delete": _vm.showDelete
    },
    on: {
      "update:list": function updateList($event) {
        _vm.listOrganizationPersons = $event;
      }
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "content-descriptions business-unit-list"
  }, [_c("h3", [_vm._v("\n          Áreas de negócio vinculadas\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "list-support"
  }, [_vm.showMore ? _c("b-button", {
    staticClass: "add-new-item",
    attrs: {
      "icon-right": "plus"
    },
    on: {
      click: _vm.addNewBusinessUnitPerson
    }
  }) : _vm._e(), _vm._v(" "), _c("table-list-business-unit", {
    attrs: {
      list: _vm.listBusinessUnitPersons,
      "action-delete": _vm.confirmDeleteBusinessUnitPersons,
      "show-delete": _vm.showDelete
    },
    on: {
      "update:list": function updateList($event) {
        _vm.listBusinessUnitPersons = $event;
      }
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "content-descriptions business-unit-list"
  }, [_c("h3", [_vm._v("\n          Histórico de Acessos Recentes\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "show-more"
  }, [_c("b-button", {
    staticClass: "button-show-more",
    attrs: {
      type: "is-info",
      outlined: ""
    },
    on: {
      click: function click($event) {
        return _vm.showItens();
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.titleButtonShow) + "\n          ")]), _vm._v(" "), _c("table-list-access-user", {
    attrs: {
      list: _vm.listOfAccessUser
    },
    on: {
      "update:list": function updateList($event) {
        _vm.listOfAccessUser = $event;
      }
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "column is-one-third col-details"
  }, [_c("h3", [_vm._v("Detalhes")]), _vm._v(" "), _c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component card-sandbox"
  }, [_c("a", {
    staticClass: "detail",
    on: {
      click: _vm.openForm
    }
  }, [_vm._v("\n            Editar\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component card-sandbox"
  }, [_c("a", {
    staticClass: "detail",
    on: {
      click: _vm.openModalSendMail
    }
  }, [_vm._v("\n            Enviar senha por e-mail\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Nome")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.client.name || "--"))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("CPF")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("maskHideCPF")(_vm.client.document)))])]), _vm._v(" "), _vm.client.phone ? _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Telefone")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm._f("maskPhoneComplete")(_vm.client.phone)))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("E-mail")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.client.email))])]), _vm._v(" "), _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Tipo de acesso")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.client.role))])])])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;