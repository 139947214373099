"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-customer-data-form-client"
  }, [_c("form", {
    attrs: {
      id: "customerDataForm"
    },
    on: {
      submit: _vm.saveOnboarding
    }
  }, [_c("card", {
    staticClass: "card-customer-finished-form-prospect-component"
  }, [_c("h1", [_vm._v("Cutomização Onboarding")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-flex is-flex-direction-row is-align-items-center"
  }, [_c("app-select", {
    attrs: {
      placeholder: "",
      label: "Selecione a unidade de consumo",
      error: _vm.$v.form.businessUnit.$error,
      items: _vm.businessUnitsOnboarding,
      option: "_id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.businessUnit, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.businessUnit.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.businessUnit, "$model", $$v);
      },
      expression: "$v.form.businessUnit.$model"
    }
  }), _vm._v(" "), _vm.form.businessUnit ? _c("b-field", {
    staticClass: "mt-2 ml-4 field-buttons"
  }, [_c("b-button", {
    staticClass: "btn-save-button",
    on: {
      click: _vm.handleSearchCustom
    }
  }, [_vm._v("\n              Buscar\n            ")])], 1) : _vm._e()], 1)]), _vm._v(" "), _c("h2", {
    staticClass: "mt-6"
  }, [_vm._v("Components e texto")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "",
      label: "Login",
      error: _vm.$v.form.login.$error,
      items: _vm.listStatusAPIValidation,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.login, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.login.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.login, "$model", $$v);
      },
      expression: "$v.form.login.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Informe o título de exibição",
      placeholder: "Texto de exibição",
      error: _vm.$v.form.textOnboarding.$error,
      type: "url"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.textOnboarding, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.textOnboarding.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.textOnboarding, "$model", $$v);
      },
      expression: "$v.form.textOnboarding.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Texto de exibição no botão",
      placeholder: "Texto de exibição no botão",
      error: _vm.$v.form.textButtonOnboarding.$error,
      type: "url"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.textButtonOnboarding, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.textButtonOnboarding.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.textButtonOnboarding, "$model", $$v);
      },
      expression: "$v.form.textButtonOnboarding.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _c("h2", {
    staticClass: "mt-2"
  }, [_vm._v("Validação de Documentos")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Uso da API de validação",
      error: _vm.$v.form.documentActive.$error,
      items: _vm.listStatusAPIValidation,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.documentActive, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.documentActive.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.documentActive, "$model", $$v);
      },
      expression: "$v.form.documentActive.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Selecione o provedor de validação",
      error: _vm.$v.form.documentType.$error,
      items: _vm.listTypeAPIValidationDoc,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.documentType, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.documentType.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.documentType, "$model", $$v);
      },
      expression: "$v.form.documentType.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Informe o endpoint de Validação",
      placeholder: "example/endpoint",
      error: _vm.$v.form.documentEndpoint.$error,
      type: "url"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.documentEndpoint, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.documentEndpoint.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.documentEndpoint, "$model", $$v);
      },
      expression: "$v.form.documentEndpoint.$model"
    }
  })], 1)]), _vm._v(" "), _c("h2", {
    staticClass: "mt-2"
  }, [_vm._v("Validação para outros documentos")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Uso da API de validação",
      error: _vm.$v.form.othersDocumentActive.$error,
      items: _vm.listStatusAPIValidation,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.othersDocumentActive, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.othersDocumentActive.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.othersDocumentActive, "$model", $$v);
      },
      expression: "$v.form.othersDocumentActive.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Selecione o provedor de validação",
      error: _vm.$v.form.othersDocumentType.$error,
      items: _vm.listTypeAPIValidationDoc,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.othersDocumentType, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.othersDocumentType.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.othersDocumentType, "$model", $$v);
      },
      expression: "$v.form.othersDocumentType.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Informe o endpoint de Validação",
      placeholder: "example/endpoint",
      error: _vm.$v.form.othersDocumentEndpoint.$error,
      type: "url"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.othersDocumentEndpoint, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.othersDocumentEndpoint.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.othersDocumentEndpoint, "$model", $$v);
      },
      expression: "$v.form.othersDocumentEndpoint.$model"
    }
  })], 1)]), _vm._v(" "), _c("h2", {
    staticClass: "mt-2"
  }, [_vm._v("Validação Comprovante Residêncial")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Uso da API de validação",
      error: _vm.$v.form.addressDocumentsActive.$error,
      items: _vm.listStatusAPIValidation,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.addressDocumentsActive, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.addressDocumentsActive.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.addressDocumentsActive, "$model", $$v);
      },
      expression: "$v.form.addressDocumentsActive.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Informe o endpoint de Validação",
      placeholder: "example/endpoint",
      error: _vm.$v.form.addressDocumentsEndpoint.$error,
      type: "url"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.addressDocumentsEndpoint, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.addressDocumentsEndpoint.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.addressDocumentsEndpoint, "$model", $$v);
      },
      expression: "$v.form.addressDocumentsEndpoint.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _c("h2", {
    staticClass: "mt-2"
  }, [_vm._v("Validação Face")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Uso da API de validação",
      error: _vm.$v.form.faceActive.$error,
      items: _vm.listStatusAPIValidation,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.faceActive, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.faceActive.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.faceActive, "$model", $$v);
      },
      expression: "$v.form.faceActive.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Selecione o provedor de validação",
      error: _vm.$v.form.faceType.$error,
      items: _vm.listTypeAPIValidationDoc,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.faceType, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.faceType.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.faceType, "$model", $$v);
      },
      expression: "$v.form.faceType.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Informe o endpoint de Validação",
      placeholder: "example/endpoint",
      error: _vm.$v.form.faceEndpoint.$error,
      type: "url"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.faceEndpoint, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.faceEndpoint.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.faceEndpoint, "$model", $$v);
      },
      expression: "$v.form.faceEndpoint.$model"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "divider"
  }), _vm._v(" "), _c("h2", {
    staticClass: "mt-2"
  }, [_vm._v("Validação Digital")]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Uso da API de validação",
      error: _vm.$v.form.fingerActive.$error,
      items: _vm.listStatusAPIValidation,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.fingerActive, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.fingerActive.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.fingerActive, "$model", $$v);
      },
      expression: "$v.form.fingerActive.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-select", {
    attrs: {
      placeholder: "Status",
      label: "Selecione o provedor de validação",
      error: _vm.$v.form.fingerType.$error,
      items: _vm.listTypeAPIValidationDoc,
      option: "id",
      "name-item": "name"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.fingerType, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.fingerType.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.fingerType, "$model", $$v);
      },
      expression: "$v.form.fingerType.$model"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("app-input", {
    attrs: {
      expanded: "",
      label: "Informe o endpoint de Validação",
      placeholder: "example/endpoint",
      error: _vm.$v.form.fingerEndpoint.$error,
      type: "url"
    },
    on: {
      "update:error": function updateError($event) {
        return _vm.$set(_vm.$v.form.fingerEndpoint, "$error", $event);
      }
    },
    model: {
      value: _vm.$v.form.fingerEndpoint.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.form.fingerEndpoint, "$model", $$v);
      },
      expression: "$v.form.fingerEndpoint.$model"
    }
  })], 1)])])], 1), _vm._v(" "), _c("b-field", {
    staticClass: "field-buttons"
  }, [_c("b-button", {
    staticClass: "btn-save-button",
    on: {
      click: _vm.saveOnboarding
    }
  }, [_vm._v("\n      Salvar\n    ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;