"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUserAdmin = exports.authLogout = exports.authGuard = void 0;
var _auth = require("@/utils/auth");
var _router = _interopRequireDefault(require("@/router"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var authGuard = exports.authGuard = function authGuard(to, from, next) {
  if (_router.default.app.authenticated) {
    next();
  } else {
    (0, _auth.removeToken)();
    _router.default.push({
      name: 'VerifyLogin'
    });
  }
};
var authLogout = exports.authLogout = function authLogout() {
  (0, _auth.removeToken)();
  _router.default.push({
    name: 'VerifyLogin'
  });
};
var isUserAdmin = exports.isUserAdmin = function isUserAdmin() {
  var _getUser, _getUser$user;
  var roleAdmin = (_getUser = (0, _auth.getUser)()) === null || _getUser === void 0 ? void 0 : (_getUser$user = _getUser.user) === null || _getUser$user === void 0 ? void 0 : _getUser$user.roleAdmin;
  return roleAdmin;
};