"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _export = _interopRequireDefault(require("@/assets/styles/_export.scss"));
var _mockMenu = _interopRequireDefault(require("@/store/mocks/mock-menu"));
var _auth = require("@/utils/auth");
var _constants = require("@/config/constants");
var _validate = require("@/utils/validate");
var _version2 = _interopRequireDefault(require("@/version"));
var _authGuard = require("@/auth/authGuard");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _default = exports.default = {
  components: {
    SidebarItem: _SidebarItem.default
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['sidebar', 'device'])), {}, {
    variables: function variables() {
      return _export.default;
    },
    permissionRoutes: function permissionRoutes() {
      var _this = this;
      if ((0, _authGuard.isUserAdmin)()) {
        return _mockMenu.default;
      }
      var BU = (0, _auth.getAccessData)().activeBusinessUnit;
      var BUName = BU === null || BU === void 0 ? void 0 : BU.name;
      var menuItems = _mockMenu.default.filter(function (i) {
        return i.clients.length && i.clients.includes(BUName) || !i.clients.length;
      });
      if (menuItems.length) {
        var menu = menuItems.filter(function (i) {
          return i.roles.includes(_this.user.roleId);
        });
        return menu;
      }
      return [];
    },
    activeMenu: function activeMenu() {
      var _this$$route = this.$route,
        meta = _this$$route.meta,
        path = _this$$route.path;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    isCollapse: function isCollapse() {
      return this.device === 'mobile' ? false : !this.sidebar.opened;
    },
    buttonColor: function buttonColor() {
      return '#000';
    },
    photoValid: function photoValid() {
      if ((0, _validate.validURL)(this.user.imageUrl)) return true;
      return false;
    },
    user: function user() {
      return (0, _auth.getUser)().user;
    },
    descriptionType: function descriptionType() {
      var _this2 = this;
      if (!this.user.roleId) return '';
      var type = _constants.LIST_TYPES_USERS.find(function (i) {
        return i.id === _this2.user.roleId;
      });
      return type.name;
    },
    version: function version() {
      return _version2.default;
    },
    isMobile: function isMobile() {
      return this.device === 'mobile';
    },
    organization: function organization() {
      var _getAccessData, _getAccessData$active;
      return ((_getAccessData = (0, _auth.getAccessData)()) === null || _getAccessData === void 0 ? void 0 : (_getAccessData$active = _getAccessData.activeBusinessUnit) === null || _getAccessData$active === void 0 ? void 0 : _getAccessData$active.organizationId) || {};
    },
    logoTipo: function logoTipo() {
      return this.organization.logo;
    },
    businessUnit: function businessUnit() {
      var _getAccessData2;
      return ((_getAccessData2 = (0, _auth.getAccessData)()) === null || _getAccessData2 === void 0 ? void 0 : _getAccessData2.activeBusinessUnit) || {};
    }
  }),
  methods: {
    openDetailProfile: function openDetailProfile() {
      this.$router.push('/profile/user');
    },
    openDetailClient: function openDetailClient() {
      this.$router.push({
        name: 'OrganizationDetails'
      });
    }
  }
};