"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'MonitorStatusItems',
  props: {
    healthy: {
      type: [String, Number],
      default: 0
    },
    warning: {
      type: [String, Number],
      default: 0
    },
    critical: {
      type: [String, Number],
      default: 0
    }
  }
};