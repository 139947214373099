"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "page-dashboard-details-service"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n            Open Banking\n          ")])])])])]), _vm._v(" "), _c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentIdForA11y2",
      animation: "slide",
      open: _vm.isOpen == 0
    },
    on: {
      open: function open($event) {
        _vm.isOpen = 0;
      }
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn(props) {
        return [_c("div", {
          staticClass: "card-header",
          attrs: {
            role: "button",
            "aria-controls": "contentIdForA11y2",
            "aria-expanded": _vm.isOpen
          }
        }, [_c("p", {
          staticClass: "card-header-title"
        }, [_vm._v("\n          Sobre o Compartilhamento\n        ")]), _vm._v(" "), _c("a", {
          staticClass: "card-header-icon"
        }, [_c("b-icon", {
          attrs: {
            icon: props.open ? "menu-down" : "menu-up"
          }
        })], 1)])];
      }
    }])
  }, [_vm._v(" "), _c("div", {
    staticClass: "share-container"
  }, [_c("div", {
    staticClass: "row share-content"
  }, [_c("table", {
    staticClass: "table is-striped is-narrow is-fullwidth"
  }, [_c("tbody", _vm._l(_vm.shareInfo, function (item) {
    return _c("tr", {
      key: item.key
    }, [_c("td", {
      staticClass: "td-key"
    }, [_vm._v("\n                " + _vm._s(item.key) + "\n              ")]), _vm._v(" "), _c("td", [_vm._v("\n                " + _vm._s(item.description) + "\n              ")])]);
  }), 0)])])])]), _vm._v(" "), _c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentIdForA11y2",
      animation: "slide",
      open: _vm.isOpen == 1
    },
    on: {
      open: function open($event) {
        _vm.isOpen = 1;
      }
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn(props) {
        return [_c("div", {
          staticClass: "card-header",
          attrs: {
            role: "button",
            "aria-controls": "contentIdForA11y2",
            "aria-expanded": _vm.isOpen
          }
        }, [_c("p", {
          staticClass: "card-header-title"
        }, [_vm._v("\n          Dados Básicos\n        ")]), _vm._v(" "), _c("a", {
          staticClass: "card-header-icon"
        }, [_c("b-icon", {
          attrs: {
            icon: props.open ? "menu-down" : "menu-up"
          }
        })], 1)])];
      }
    }])
  }, [_vm._v(" "), _c("div", {
    staticClass: "share-container"
  }, [_c("div", {
    staticClass: "row share-content"
  }, [_c("table", {
    staticClass: "table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
  }, [_c("tbody", [_vm._l(_vm.basicData, function (item) {
    return _c("tr", {
      key: item.key
    }, [_c("td", {
      staticClass: "td-key"
    }, [_vm._v("\n                " + _vm._s(item.key) + "\n              ")]), _vm._v(" "), _c("td", [_vm._v("\n                " + _vm._s(item.description) + "\n              ")])]);
  }), _vm._v(" "), _c("tr", [_c("td", {
    staticClass: "td-key"
  }, [_vm._v("\n                Régua de Distribuição\n              ")]), _vm._v(" "), _c("td", [_c("table", {
    staticClass: "table is-fullwidth has-text-centered"
  }, [_c("tr", [_c("td", {
    staticStyle: {
      "background-color": "#e0e0e0"
    }
  }, [_vm._v(" NA ")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "#FFF"
    }
  }, [_vm._v(" 0% ")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "#e8f5e9"
    }
  }, [_vm._v(" 0,01% a 19,99% ")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "#f0f4c3"
    }
  }, [_vm._v(" 20% a 39,99% ")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "#fff59d"
    }
  }, [_vm._v(" 40% a 69,99% ")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "#ffe082"
    }
  }, [_vm._v(" 70% a 79,99% ")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "#ffcc80"
    }
  }, [_vm._v(" 80% a 89,99% ")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "#ffab91"
    }
  }, [_vm._v(" 90% a 99,99% ")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "#ef9a9a"
    }
  }, [_vm._v(" 100% ")])])])])])], 2)])])])]), _vm._v(" "), _c("div", {
    staticClass: "columns serach-container"
  }, [_c("div", {
    staticClass: "column is-one-quarter"
  }, [_c("field-search-input", {
    attrs: {
      placeholder: "Navegue por Mobilidade"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _vm._v(" "), _vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "column is-one-quarter"
  })]), _vm._v(" "), _c("div", {
    staticClass: "share-container"
  }, [_c("div", {
    staticClass: "row share-content"
  }, _vm._l(_vm.terrifSections, function (item, idx) {
    return _c("table", {
      key: idx,
      staticClass: "table is-narrow is-fullwidth"
    }, [_c("tbody", [_c("tr", {
      staticClass: "tr-content"
    }, [_c("td", {
      staticClass: "td-key",
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" Nome Tarifa ")]), _vm._v(" "), _c("td", {
      staticClass: "td-description",
      attrs: {
        colspan: "8"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])]), _vm._v(" "), _c("tr", {
      staticClass: "tr-content"
    }, [_c("td", {
      staticClass: "td-key",
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" Código da Tarifa ")]), _vm._v(" "), _c("td", {
      attrs: {
        colspan: "8"
      }
    }, [_vm._v(" " + _vm._s(item.code) + " ")])]), _vm._v(" "), _c("tr", {
      staticClass: "tr-content"
    }, [_c("td", {
      staticClass: "td-key",
      attrs: {
        colspan: "2"
      }
    }, [_vm._v(" Fator Gerador ")]), _vm._v(" "), _c("td", {
      attrs: {
        colspan: "8"
      }
    }, [_vm._v(" " + _vm._s(item.chargingTriggerInfo) + " ")])]), _vm._v(" "), _c("tr", {
      staticClass: "tr-container"
    }, [_c("td", {
      staticStyle: {
        padding: "0"
      }
    }, [_c("table", {
      staticClass: "table is-striped is-narrow is-hoverable is-fullwidth"
    }, [_vm._m(1, true), _vm._v(" "), _c("tr", [_c("td", {
      staticClass: "is-centered"
    }, [_vm._v(_vm._s("".concat(item.minimum.currency, " ").concat(item.minimum.value)))]), _vm._v(" "), _c("td", {
      staticClass: "is-centered"
    }, [_vm._v(_vm._s("".concat(item.maximum.currency, " ").concat(item.maximum.value)))])])])]), _vm._v(" "), _vm._l(item.prices, function (item2, idx2) {
      return _c("td", {
        key: idx2 * 3,
        staticStyle: {
          padding: "0"
        },
        attrs: {
          colspan: "2"
        }
      }, [_c("table", {
        staticClass: "table is-fullwidth",
        style: "".concat(_vm.statusColor(item2.customers.rate))
      }, [_c("tr", [_c("td", {
        attrs: {
          colspan: "2"
        }
      }, [_vm._v("\n                    " + _vm._s(item2.interval) + "\n                  ")])]), _vm._v(" "), _c("tr", {
        style: "".concat(_vm.statusColor(item2.customers.rate))
      }, [_c("td", [_vm._v("mediana")]), _vm._v(" "), _c("td", [_vm._v("clientes")])]), _vm._v(" "), _c("tr", [_c("td", [_vm._v(_vm._s("".concat(item2.currency, " ").concat(item2.value)))]), _vm._v(" "), _c("td", [_vm._v(_vm._s("".concat((item2.customers.rate * 100).toFixed(2), "%")))])])])]);
    })], 2)])]);
  }), 0)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "share-container-title"
  }, [_c("p", {
    staticClass: "title is-4"
  }, [_vm._v("Seção de Tarifas")]), _vm._v(" "), _c("p", {
    staticClass: "subtitle is-6"
  }, [_vm._v("CONTA_DEPOSITO_A_VISTA")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("tr", [_c("td", {
    staticClass: "is-centered"
  }, [_vm._v("Mínimo")]), _vm._v(" "), _c("td", {
    staticClass: "is-centered"
  }, [_vm._v("Máximo")])]);
}];
render._withStripped = true;