"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'ModalNotificationBody',
  props: {
    listAlert: {
      type: Object,
      required: true
    }
  },
  methods: {
    close: function close() {
      this.$parent.close();
    }
  }
};