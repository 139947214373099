"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cols-form tab-card-validation-documents-component"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [!_vm.validationItem._id ? _c("not-found") : _vm._e(), _vm._v(" "), _vm.validationItem._id ? _c("card", {
    staticClass: "card-detail-image"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h2", [_c("b", [_vm._v("Data da Validação:")]), _vm._v(" " + _vm._s(_vm._f("dateComplete")(_vm.validationItem.createdAt)))]), _vm._v(" "), _c("h2", [_c("b", [_vm._v("Score Documentoscopia:")]), _c("br"), _vm._v(_vm._s(_vm._f("documentoscopia")(_vm.validationDocumentoscopia(_vm.validationItem.documentoscopia).score)))])])]), _vm._v(" "), _c("div", [_c("div", {
    staticClass: "columns"
  }, _vm._l(_vm.validationItem.validationApp, function (itemValidation, idxValidation) {
    return _c("div", {
      key: idxValidation * 2,
      staticClass: "column col-img"
    }, [_c("div", [_vm.imageUrl(itemValidation) ? _c("img", {
      attrs: {
        src: _vm.imageUrl(itemValidation)
      }
    }) : _vm._e()]), _vm._v(" "), _c("h2", [_vm._v("Tipo: " + _vm._s((itemValidation === null || itemValidation === void 0 ? void 0 : itemValidation.tipo) || "--"))]), _vm._v(" "), _c("h2", [_vm._v("Score: " + _vm._s((itemValidation === null || itemValidation === void 0 ? void 0 : itemValidation.score) || "--"))])]);
  }), 0)])]) : _vm._e(), _vm._v(" "), _vm.totalItems > 1 ? _c("div", {
    staticClass: "content-load-more-items"
  }, [!_vm.validations.length ? _c("div", {
    staticClass: "buttons"
  }, [_c("b-button", {
    attrs: {
      type: "is-primary"
    },
    on: {
      click: _vm.loadMoreItems
    }
  }, [_vm._v("Carregar outros " + _vm._s(_vm.totalItems - 1) + " itens")])], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "list-items"
  }, _vm._l(_vm.validations, function (item, idx) {
    return _c("card", {
      key: idx,
      staticClass: "card-detail-image"
    }, [_c("div", {
      staticClass: "columns"
    }, [_c("div", {
      staticClass: "column"
    }, [_c("h2", [_c("b", [_vm._v("Data da Validação:")]), _vm._v(" " + _vm._s(_vm._f("dateComplete")(item.createdAt)))]), _vm._v(" "), _c("h2", [_c("b", [_vm._v("Score Documentoscopia:")]), _c("br"), _vm._v(_vm._s(_vm._f("documentoscopia")(_vm.validationDocumentoscopia(item.documentoscopia).score)))])])]), _vm._v(" "), _c("div", [_c("div", {
      staticClass: "columns"
    }, _vm._l(item.validationApp, function (itemValidation, idxValidation) {
      return _c("div", {
        key: idxValidation * 2,
        staticClass: "column col-img"
      }, [_c("div", [_vm.imageUrl(itemValidation) ? _c("img", {
        attrs: {
          src: _vm.imageUrl(itemValidation)
        }
      }) : _vm._e()]), _vm._v(" "), _c("h2", [_vm._v("Tipo: " + _vm._s((itemValidation === null || itemValidation === void 0 ? void 0 : itemValidation.tipo) || "--"))]), _vm._v(" "), _c("h2", [_vm._v("Score: " + _vm._s((itemValidation === null || itemValidation === void 0 ? void 0 : itemValidation.score) || "--"))])]);
    }), 0)])]);
  }), 1)]) : _vm._e()], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;