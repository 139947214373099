"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-client-list"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Clientes")]), _vm._v(" "), _c("header-filter", {
    attrs: {
      placeholder: "Busque pelo nome do cliente ou CNPJ"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "content-list-table"
  }, [_c("table-list-clients", {
    attrs: {
      list: _vm.listClients,
      "action-item": _vm.openDetailLogFiles
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;