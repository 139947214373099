"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("header", {
    staticClass: "header",
    attrs: {
      id: "hdr-header-component"
    }
  }, [_c("div", {
    staticClass: "header-container"
  }, [_c("div", {
    staticClass: "header-main-content",
    class: {
      "header-container-back": _vm.headerData.back
    },
    attrs: {
      id: "div-header-body"
    }
  }, [_c("div", {
    staticClass: "header-title"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "div-hamburger-container",
      "is-active": _vm.isActive
    },
    on: {
      toggleClick: _vm.toggleClick
    }
  }), _vm._v(" "), _c("h1", {
    staticClass: "title-system-header"
  }, [_vm._v("HIVEPlace")])], 1), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.headerData.noRight,
      expression: "!headerData.noRight"
    }],
    staticClass: "header-profile",
    attrs: {
      id: "div-header-profile"
    }
  }, [!_vm.isMobile && _vm.roleId != 14 ? _c("b-button", {
    staticClass: "btn-search-protocol",
    attrs: {
      label: "Consultar protocolo",
      type: "is-primary",
      "icon-left": "magnify"
    },
    on: {
      click: _vm.openModalSearchProtocol
    }
  }) : _vm._e(), _vm._v(" "), _vm.isMobile ? _c("b-button", {
    staticClass: "btn-search-protocol",
    attrs: {
      type: "is-primary",
      "icon-left": "magnify"
    },
    on: {
      click: _vm.openModalSearchProtocol
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMobile,
      expression: "!isMobile"
    }],
    staticClass: "container-buttons-actions-header"
  }, [_c("b-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAdmin,
      expression: "isAdmin"
    }],
    staticClass: "cursor-p",
    attrs: {
      type: "is-info",
      rounded: "",
      outlined: ""
    },
    on: {
      click: function click($event) {
        return _vm.listContriRedem();
      }
    }
  }, [_vm._v("\n            Novo Serviço\n          ")])], 1), _vm._v(" "), _vm.businessUnits.length > 1 ? _c("div", {
    staticClass: "header-user cursor-p",
    attrs: {
      id: "div-header-user"
    },
    on: {
      click: _vm.changeBusinesUnit
    }
  }, [_vm._m(0)]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "header-user cursor-p",
    attrs: {
      id: "div-header-user"
    },
    on: {
      click: _vm.pushProfile
    }
  }, [_vm._m(1)]), _vm._v(" "), _c("div", {
    staticClass: "header-logout cursor-p",
    attrs: {
      id: "div-header-logout"
    },
    on: {
      click: _vm.handleLogout
    }
  }, [_c("img", {
    attrs: {
      id: "img-header-logout",
      src: require("@/assets/images/icons/logout.png")
    }
  })])], 1)])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "header-switch",
    style: {
      "background-color": _vm.headerColor
    },
    attrs: {
      id: "div-header-switch"
    },
    on: {
      click: function click($event) {
        _vm.headerOpen = !_vm.headerOpen;
      }
    }
  }, [_c("div", {
    staticClass: "switch-button",
    style: {
      "background-color": _vm.colorAccessorie
    },
    attrs: {
      id: "div-header-switch-button"
    }
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "change-bu-btn"
  }, [_c("img", {
    attrs: {
      id: "img-header-avatar",
      src: require("@/assets/images/icons/icon-change.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "avatar-image"
  }, [_c("img", {
    attrs: {
      id: "img-header-avatar",
      src: require("@/assets/images/icons/icon-user-header.png")
    }
  })]);
}];
render._withStripped = true;