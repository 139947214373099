"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-service-detail-list-component"
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))])])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "content",
    class: {
      error: _vm.error
    }
  }, [_c("h1", [_vm._v(_vm._s(_vm.service))])])]), _vm._v(" "), _vm.month ? _c("div", {
    staticClass: "card-footer"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h6", [_vm._v("Mês referencia")]), _vm._v(" "), _c("div", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.month))])])])]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;