"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Badge',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    href: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default',
      validator: function validator(value) {
        var acceptedValues = ['primary', 'info', 'success', 'warning', 'danger', 'default', 'neutral'];
        return acceptedValues.indexOf(value) !== -1;
      }
    }
  },
  computed: {
    componentTag: function componentTag() {
      return this.href ? 'a' : this.tag;
    }
  }
};