"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'NCheckbox',
  model: {
    prop: 'checked'
  },
  props: {
    checked: {
      type: [Array, Boolean],
      default: function _default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      cbId: '',
      touched: false
    };
  },
  computed: {
    model: {
      get: function get() {
        return this.checked;
      },
      set: function set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit('input', check);
      }
    },
    inlineClass: function inlineClass() {
      if (this.inline) {
        return "form-check-inline";
      }
      return "";
    }
  },
  created: function created() {
    this.cbId = Math.random().toString(16).slice(2);
  }
};