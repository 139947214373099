"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-pro-det"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-xxl-3"
  }, [_c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v(" "), _c("img", {
    attrs: {
      src: require("@/assets/images/logos/logo-default.png"),
      alt: "logo"
    }
  }), _vm._v("\n              Service Name\n            ")])])])])])]), _vm._v(" "), _c("div", {
    staticClass: "row"
  }, [_c("div", {
    staticClass: "col-md-12 col-lg-7 col-descriptions"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "content-certificates"
  }, [_c("h3", [_vm._v("Certificados necessários")]), _vm._v(" "), _c("div", {
    staticClass: "list-certs"
  }, [_c("card-certificate", {
    attrs: {
      title: "BSS",
      logo: require("@/assets/images/certificates/certificate-star.png")
    }
  }), _vm._v(" "), _c("card-certificate", {
    attrs: {
      title: "GDPR",
      logo: require("@/assets/images/certificates/certificate-exin.png")
    }
  }), _vm._v(" "), _c("card-certificate", {
    attrs: {
      title: "PCI",
      logo: require("@/assets/images/certificates/certificate-pci.png")
    }
  }), _vm._v(" "), _c("card-certificate", {
    attrs: {
      title: "LGPD",
      logo: require("@/assets/images/certificates/certificate-dpo.png")
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "col-md-12 col-lg-5 col-details"
  }, [_c("h3", [_vm._v("OVerview")]), _vm._v(" "), _c("div", {
    staticClass: "list-items-details"
  }, [_c("div", {
    staticClass: "card-detail-component card-sandbox"
  }, [_c("a", {
    staticClass: "detail",
    on: {
      click: _vm.openDetail
    }
  }, [_vm._v("\n            Detalhes do serviço\n          ")])]), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm._m(2), _vm._v(" "), _vm._m(3), _vm._v(" "), _vm._m(4), _vm._v(" "), _vm._m(5), _vm._v(" "), _vm._m(6)])]), _vm._v(" "), _vm._m(7), _vm._v(" "), _c("div", {
    staticClass: "col-12 col-apis-list"
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-descriptions"
  }, [_c("h3", [_vm._v("Informações")]), _vm._v(" "), _c("p", [_vm._v("\n          The Stripe API is organized around REST. Our API has predictable resource-oriented URLs, accepts form-encoded request bodies, returns JSON-encoded responses, and uses standard HTTP response codes, authentication, and verbs.\n        ")]), _vm._v(" "), _c("p", [_vm._v("\n          You can use the Stripe API in test mode, which does not affect your live data or interact with the banking networks. The API key you use to authenticate the request determines whether the request is live mode or test mode.\n          The Stripe API differs for every account as we release new versions and tailor functionality. Log in to see docs customized to your version of the API, with your test key and data.\n          Subscribe to Stripe's API announce mailing list for updates.\n        ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "site"
    }
  }, [_vm._v("Site")]), _vm._v(" "), _c("a", {
    staticClass: "detail",
    attrs: {
      href: "https://www.service.com.br",
      target: "_blank",
      rel: "noopener noreferrer"
    }
  }, [_vm._v("https://www.service.com.br")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "service"
    }
  }, [_vm._v("Setor")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v("Bancos")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "dateValid"
    }
  }, [_vm._v("Tamanho")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v("1.001 - 5.000 funcionários")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "phone"
    }
  }, [_vm._v("Sede")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v("Belo Horizonte, Minas Gerais")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "mail"
    }
  }, [_vm._v("CNPJ")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v("23.423.534/0001-53")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-detail-component"
  }, [_c("label", {
    attrs: {
      for: "mail"
    }
  }, [_vm._v("Especializações")]), _vm._v(" "), _c("p", {
    staticClass: "detail"
  }, [_vm._v("\n            Conta Corrente, Investimentos (CDB; LCI; LCA; LF; CRI, Fundo Primus, Tesouro Direto), Crédito Imobiliário, Crédito Consignado, Capital de Giro (Middle Market), Seguros, Gestão de recursos de terceiros, Distribuição de produtos financeiros, Custódia de ações e títulos do tesouro e Previdência Privada\n          ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "col-12 col-support"
  }, [_c("h3", [_vm._v("\n        Suporte técnico\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "list-support"
  }, [_c("div", {
    staticClass: "header"
  }, [_c("div", {
    staticClass: "one"
  }, [_vm._v("Nome")]), _vm._v(" "), _c("div", {
    staticClass: "two"
  }, [_vm._v("Cargo")]), _vm._v(" "), _c("div", {
    staticClass: "three"
  }, [_vm._v("E-mail")]), _vm._v(" "), _c("div", {
    staticClass: "four"
  }, [_vm._v("Telefone")])]), _vm._v(" "), _c("div", {
    staticClass: "body"
  }, [_c("div", {
    staticClass: "row-item"
  }, [_c("div", {
    staticClass: "one"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/avatar-default.png"),
      alt: ""
    }
  }), _vm._v("\n              Pedro Paulo Albuquerque\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "two"
  }, [_vm._v("Gerente")]), _vm._v(" "), _c("div", {
    staticClass: "three"
  }, [_vm._v("pp.albuquerque@hiveplace.com.br")]), _vm._v(" "), _c("div", {
    staticClass: "four"
  }, [_vm._v("+55 61 99973-3676")])]), _vm._v(" "), _c("div", {
    staticClass: "row-item"
  }, [_c("div", {
    staticClass: "one"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/avatar-default.png"),
      alt: ""
    }
  }), _vm._v("\n              Ricardo Henrique Marinho O Reilly\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "two"
  }, [_vm._v("Gerente de Divisão")]), _vm._v(" "), _c("div", {
    staticClass: "three"
  }, [_vm._v("ricardo.henrique@hiveplace.com.br")]), _vm._v(" "), _c("div", {
    staticClass: "four"
  }, [_vm._v("+55 61 98115-9454")])])])])]);
}];
render._withStripped = true;