"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$personDetailNV, _vm$personDetailNV$CA, _vm$personDetailPh, _vm$personDetailNV2, _vm$personDetailNV2$C, _vm$personDetailPh2, _vm$personDetailNV3, _vm$personDetailNV3$C, _vm$personDetailNV4, _vm$personDetailNV4$O, _vm$personDetailNV5, _vm$personDetailNV5$P, _vm$personDetailNV6, _vm$personDetailNV6$C, _vm$personDetailNV7, _vm$personDetailNV7$C, _vm$personDetailNV8, _vm$personDetailNV8$E, _vm$personDetailPh3, _vm$personDetailPh3$A, _vm$personDetailNV9, _vm$personDetailNV9$T, _vm$personDetailPh4, _vm$personDetailPh4$P, _vm$personDetailPh5, _vm$personDetailPh5$E, _vm$personDetailPh6, _vm$personDetailPh6$E, _vm$personDetailNV10, _vm$personDetailNV10$, _vm$personDetailNV11, _vm$personDetailNV11$, _vm$personDetailPh7, _vm$personDetailPh7$I, _vm$personDetailNV12, _vm$personDetailNV12$, _vm$personDetailNV13, _vm$personDetailNV13$, _vm$personDetailNV14, _vm$personDetailNV14$, _vm$personDetailNV15, _vm$personDetailNV15$, _vm$personDetailNV16, _vm$personDetailNV16$, _vm$personDetailNV17, _vm$personDetailNV17$, _vm$personDetailNV19, _vm$personDetailNV19$, _vm$personDetailNV20, _vm$personDetailNV20$, _vm$personDetailPh8, _vm$personDetailPh8$C, _vm$personDetailNV21, _vm$personDetailNV21$, _vm$personDetailNV22, _vm$personDetailNV22$, _vm$personDetailNV23, _vm$personDetailNV23$, _vm$personDetailNV24, _vm$personDetailNV24$, _vm$personDetailNV25, _vm$personDetailNV25$, _vm$personDetailPh9, _vm$personDetailPh10, _vm$personDetailPh10$, _vm$personDetailPh11, _vm$personDetailPh12, _vm$personDetailPh13, _vm$personDetailPh14, _vm$personDetailNV26, _vm$personDetailNV26$, _vm$personDetailNV27, _vm$personDetailNV27$, _vm$personDetailNV28, _vm$personDetailNV28$, _vm$personDetailNV29, _vm$personDetailNV29$, _vm$personDetailNV30, _vm$personDetailNV30$, _vm$personDetailNV31, _vm$personDetailNV31$, _vm$personDetailNV32;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-pro-det"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n      Análise Pessoal\n    ")])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column col-detail-details"
  }, [_c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentPersonalData",
      animation: "slide"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c("div", {
          staticClass: "panel-heading",
          attrs: {
            role: "button",
            "aria-controls": "contentPersonalData"
          }
        }, [_c("strong", [_vm._v("Dados Pessoais")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("div", {
    staticClass: "content-data"
  }, [_c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "NOME (NovaVida)",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: (_vm$personDetailNV = _vm.personDetailNV) === null || _vm$personDetailNV === void 0 ? void 0 : (_vm$personDetailNV$CA = _vm$personDetailNV.CADASTRAIS) === null || _vm$personDetailNV$CA === void 0 ? void 0 : _vm$personDetailNV$CA.NOME,
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "NOME (PH3A)",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: (_vm$personDetailPh = _vm.personDetailPh) === null || _vm$personDetailPh === void 0 ? void 0 : _vm$personDetailPh.NameBrasil,
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "Dt. Nascimento (NovaVida)"
    }
  }, [_c("b-input", {
    attrs: {
      value: (_vm$personDetailNV2 = _vm.personDetailNV) === null || _vm$personDetailNV2 === void 0 ? void 0 : (_vm$personDetailNV2$C = _vm$personDetailNV2.CADASTRAIS) === null || _vm$personDetailNV2$C === void 0 ? void 0 : _vm$personDetailNV2$C.NASC,
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "Dt. Nascimento (PH3A)"
    }
  }, [_c("b-input", {
    attrs: {
      value: _vm._f("dateFormat")((_vm$personDetailPh2 = _vm.personDetailPh) === null || _vm$personDetailPh2 === void 0 ? void 0 : _vm$personDetailPh2.BirthDate),
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "ESTADO CIVIL",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: (_vm$personDetailNV3 = _vm.personDetailNV) === null || _vm$personDetailNV3 === void 0 ? void 0 : (_vm$personDetailNV3$C = _vm$personDetailNV3.CADASTRAIS) === null || _vm$personDetailNV3$C === void 0 ? void 0 : _vm$personDetailNV3$C.ESTADOCIVIL,
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "INDICAÇÃO DE ÓBITO"
    }
  }, [_c("b-input", {
    attrs: {
      value: ((_vm$personDetailNV4 = _vm.personDetailNV) === null || _vm$personDetailNV4 === void 0 ? void 0 : (_vm$personDetailNV4$O = _vm$personDetailNV4.OBITO) === null || _vm$personDetailNV4$O === void 0 ? void 0 : _vm$personDetailNV4$O.FLOBITO) === "S" ? "SIM" : "NÃO",
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "PEP"
    }
  }, [_c("b-input", {
    attrs: {
      value: ((_vm$personDetailNV5 = _vm.personDetailNV) === null || _vm$personDetailNV5 === void 0 ? void 0 : (_vm$personDetailNV5$P = _vm$personDetailNV5.PEP) === null || _vm$personDetailNV5$P === void 0 ? void 0 : _vm$personDetailNV5$P.FLPEP) === "S" ? "SIM" : "NÃO",
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "ESCOLARIDADE",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: (_vm$personDetailNV6 = _vm.personDetailNV) === null || _vm$personDetailNV6 === void 0 ? void 0 : (_vm$personDetailNV6$C = _vm$personDetailNV6.CADASTRAIS) === null || _vm$personDetailNV6$C === void 0 ? void 0 : _vm$personDetailNV6$C.POSSIVELESCOLARIDADE,
      disabled: ""
    }
  })], 1)], 1)], 1)]), _vm._v(" "), _c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentPersonalAddress",
      animation: "slide"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c("div", {
          staticClass: "panel-heading",
          attrs: {
            role: "button",
            "aria-controls": "contentPersonalAddress"
          }
        }, [_c("strong", [_vm._v("Endereços Nova Vida")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("div", {
    staticClass: "content-data"
  }, [_c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                AGLOMERADO SUBNORMAL\n                "), _c("b-tooltip", {
          attrs: {
            type: "is-primary is-light",
            label: "Aglomerado Subnormal é uma forma de ocupação irregular de terrenos de propriedade alheia, informação do primeiro endereço do usuário!",
            multilined: ""
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("b-input", {
    attrs: {
      value: ((_vm$personDetailNV7 = _vm.personDetailNV) === null || _vm$personDetailNV7 === void 0 ? void 0 : (_vm$personDetailNV7$C = _vm$personDetailNV7.CADASTRAIS) === null || _vm$personDetailNV7$C === void 0 ? void 0 : _vm$personDetailNV7$C.FLAG_AGLOMERADO_SUBNORMAL) === "S" ? "SIM" : "NÃO",
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    staticClass: "divider",
    attrs: {
      label: "Endereços:"
    }
  }), _vm._v(" "), _vm._l((_vm$personDetailNV8 = _vm.personDetailNV) === null || _vm$personDetailNV8 === void 0 ? void 0 : (_vm$personDetailNV8$E = _vm$personDetailNV8.ENDERECOS) === null || _vm$personDetailNV8$E === void 0 ? void 0 : _vm$personDetailNV8$E.slice(0, 3), function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "content-address"
    }, [_c("b-field", {
      attrs: {
        label: "LOGRADOURO",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: _vm.formatAddress(item),
        disabled: ""
      }
    })], 1)], 1);
  })], 2)]), _vm._v(" "), _c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentPersonalAddressPH",
      animation: "slide"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c("div", {
          staticClass: "panel-heading",
          attrs: {
            role: "button",
            "aria-controls": "contentPersonalAddressPH"
          }
        }, [_c("strong", [_vm._v("Endereços PH3A")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("div", {
    staticClass: "content-data"
  }, [_c("b-field", {
    staticClass: "divider",
    attrs: {
      label: "Endereços:"
    }
  }), _vm._v(" "), _vm._l((_vm$personDetailPh3 = _vm.personDetailPh) === null || _vm$personDetailPh3 === void 0 ? void 0 : (_vm$personDetailPh3$A = _vm$personDetailPh3.Addresses) === null || _vm$personDetailPh3$A === void 0 ? void 0 : _vm$personDetailPh3$A.slice(0, 3), function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "content-address"
    }, [_c("b-field", {
      attrs: {
        grouped: ""
      }
    }, [_c("b-field", {
      attrs: {
        label: "LOGRADOURO",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item.Alias,
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_vm._v("\n                  SCORE\n                  "), _c("b-tooltip", {
            attrs: {
              type: "is-primary is-light",
              label: "Valor da 0 a 10, sendo 10 a maior probabilidade do usuário residir neste endereço!",
              multilined: ""
            }
          }, [_c("b-icon", {
            attrs: {
              size: "is-small",
              icon: "help-circle-outline"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" "), _c("b-input", {
      attrs: {
        value: item.Score,
        disabled: ""
      }
    })], 1)], 1)], 1);
  })], 2)]), _vm._v(" "), _c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentPersonalContacts",
      animation: "slide"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c("div", {
          staticClass: "panel-heading",
          attrs: {
            role: "button",
            "aria-controls": "contentPersonalContacts"
          }
        }, [_c("strong", [_vm._v("Dados de Contato")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("div", {
    staticClass: "content-data"
  }, [_c("b-field", {
    staticClass: "divider",
    attrs: {
      label: "Telefones NovaVida:"
    }
  }), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, _vm._l((_vm$personDetailNV9 = _vm.personDetailNV) === null || _vm$personDetailNV9 === void 0 ? void 0 : (_vm$personDetailNV9$T = _vm$personDetailNV9.TELEFONES) === null || _vm$personDetailNV9$T === void 0 ? void 0 : _vm$personDetailNV9$T.slice(0, 3), function (item, idx) {
    return _c("b-field", {
      key: idx,
      class: {
        "show-contrast": _vm.verifyPhoneExistPH(_vm.formatPhone(item))
      },
      attrs: {
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: "".concat(_vm.formatPhone(item), " ").concat(item.ASSINANTE === "S" ? "- Proprietário" : ""),
        disabled: ""
      }
    })], 1);
  }), 1), _vm._v(" "), _c("b-field", {
    staticClass: "divider",
    attrs: {
      label: "Telefones PH3A:"
    }
  }), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, _vm._l((_vm$personDetailPh4 = _vm.personDetailPh) === null || _vm$personDetailPh4 === void 0 ? void 0 : (_vm$personDetailPh4$P = _vm$personDetailPh4.Phones) === null || _vm$personDetailPh4$P === void 0 ? void 0 : _vm$personDetailPh4$P.slice(0, 3), function (item, idx) {
    return _c("b-field", {
      key: idx,
      class: {
        "show-contrast": _vm.verifyPhoneExistNV(item.FormattedNumber)
      },
      attrs: {
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item.FormattedNumber,
        disabled: ""
      }
    })], 1);
  }), 1), _vm._v(" "), _vm.emailsNV.length ? _c("b-field", {
    staticClass: "divider",
    attrs: {
      label: "E-mails NovaVida:"
    }
  }) : _vm._e(), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, _vm._l(_vm.emailsNV, function (item, idx) {
    return _c("b-field", {
      key: idx,
      class: {
        "show-contrast": _vm.verifyEmailExistPH(item.EMAIL)
      },
      attrs: {
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item.EMAIL,
        disabled: ""
      }
    })], 1);
  }), 1), _vm._v(" "), (_vm$personDetailPh5 = _vm.personDetailPh) !== null && _vm$personDetailPh5 !== void 0 && (_vm$personDetailPh5$E = _vm$personDetailPh5.Emails) !== null && _vm$personDetailPh5$E !== void 0 && _vm$personDetailPh5$E.length ? _c("b-field", {
    staticClass: "divider",
    attrs: {
      label: "E-mails PH3A:"
    }
  }) : _vm._e(), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, _vm._l((_vm$personDetailPh6 = _vm.personDetailPh) === null || _vm$personDetailPh6 === void 0 ? void 0 : (_vm$personDetailPh6$E = _vm$personDetailPh6.Emails) === null || _vm$personDetailPh6$E === void 0 ? void 0 : _vm$personDetailPh6$E.slice(0, 3), function (item, idx) {
    return _c("b-field", {
      key: idx,
      class: {
        "show-contrast": _vm.verifyEmailExistNV(item.Email)
      },
      attrs: {
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item.Email,
        disabled: ""
      }
    })], 1);
  }), 1)], 1)]), _vm._v(" "), _c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentPersonalProfessional",
      animation: "slide"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c("div", {
          staticClass: "panel-heading",
          attrs: {
            role: "button",
            "aria-controls": "contentPersonalProfessional"
          }
        }, [_c("strong", [_vm._v("Dados Profissionais")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("div", {
    staticClass: "content-data"
  }, [_c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "PROFISSÃO (NovaVida)",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: (_vm$personDetailNV10 = _vm.personDetailNV) === null || _vm$personDetailNV10 === void 0 ? void 0 : (_vm$personDetailNV10$ = _vm$personDetailNV10.CADASTRAIS) === null || _vm$personDetailNV10$ === void 0 ? void 0 : _vm$personDetailNV10$.POSSIVELPROFISSAO,
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "RENDA PRESUMIDA (NovaVida)"
    }
  }, [_c("b-input", {
    attrs: {
      value: _vm._f("maskMoney")((_vm$personDetailNV11 = _vm.personDetailNV) === null || _vm$personDetailNV11 === void 0 ? void 0 : (_vm$personDetailNV11$ = _vm$personDetailNV11.CADASTRAIS) === null || _vm$personDetailNV11$ === void 0 ? void 0 : _vm$personDetailNV11$.RENDA),
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "RENDA PRESUMIDA (PH3A)"
    }
  }, [_c("b-input", {
    attrs: {
      value: _vm._f("maskMoney")((_vm$personDetailPh7 = _vm.personDetailPh) === null || _vm$personDetailPh7 === void 0 ? void 0 : (_vm$personDetailPh7$I = _vm$personDetailPh7.Income) === null || _vm$personDetailPh7$I === void 0 ? void 0 : _vm$personDetailPh7$I.Presumed),
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "FONTE DE RENDA (NovaVida)",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: (_vm$personDetailNV12 = _vm.personDetailNV) === null || _vm$personDetailNV12 === void 0 ? void 0 : (_vm$personDetailNV12$ = _vm$personDetailNV12.CADASTRAIS) === null || _vm$personDetailNV12$ === void 0 ? void 0 : _vm$personDetailNV12$.FONTE_DE_RENDA,
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "CLASSE ECONÔMICA",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: (_vm$personDetailNV13 = _vm.personDetailNV) === null || _vm$personDetailNV13 === void 0 ? void 0 : (_vm$personDetailNV13$ = _vm$personDetailNV13.CADASTRAIS) === null || _vm$personDetailNV13$ === void 0 ? void 0 : _vm$personDetailNV13$.CLASSEECONOMICA,
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "AUXÍLIO BRASIL",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: ((_vm$personDetailNV14 = _vm.personDetailNV) === null || _vm$personDetailNV14 === void 0 ? void 0 : (_vm$personDetailNV14$ = _vm$personDetailNV14.CADASTRAIS) === null || _vm$personDetailNV14$ === void 0 ? void 0 : _vm$personDetailNV14$.AUXILIOBRASIL) === "N" ? "NÃO" : "SIM",
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "TEMPO NO ÚLTIMO TRABALHO",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: "".concat((_vm$personDetailNV15 = _vm.personDetailNV) === null || _vm$personDetailNV15 === void 0 ? void 0 : (_vm$personDetailNV15$ = _vm$personDetailNV15.CADASTRAIS) === null || _vm$personDetailNV15$ === void 0 ? void 0 : _vm$personDetailNV15$.TEMPO_EMPREENDEDOR, " anos"),
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "POSSÍVEL APOSENTADO",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: ((_vm$personDetailNV16 = _vm.personDetailNV) === null || _vm$personDetailNV16 === void 0 ? void 0 : (_vm$personDetailNV16$ = _vm$personDetailNV16.PERFILCONSUMO) === null || _vm$personDetailNV16$ === void 0 ? void 0 : _vm$personDetailNV16$.POSSIVEL_APOSENTADO) === "N" ? "NÃO" : "SIM",
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "SALDO PRESUMIDO DO FGTS",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: _vm._f("maskMoney")((_vm$personDetailNV17 = _vm.personDetailNV) === null || _vm$personDetailNV17 === void 0 ? void 0 : (_vm$personDetailNV17$ = _vm$personDetailNV17.FGTS) === null || _vm$personDetailNV17$ === void 0 ? void 0 : _vm$personDetailNV17$.VLR_PRESUMIDO),
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _vm.professionalData.length ? _c("b-field", {
    staticClass: "divider",
    attrs: {
      label: "HISTÓRICO DE EMPREGOS:"
    }
  }) : _vm._e(), _vm._v(" "), _vm._l(_vm.professionalData, function (item, idx) {
    var _item$Company;
    return _c("div", {
      key: idx,
      staticClass: "content-address"
    }, [_c("b-field", {
      attrs: {
        grouped: ""
      }
    }, [_c("b-field", {
      attrs: {
        label: "NOME DA EMPRESA",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item === null || item === void 0 ? void 0 : (_item$Company = item.Company) === null || _item$Company === void 0 ? void 0 : _item$Company.Name,
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        label: "DATA DE ADMISSÃO"
      }
    }, [_c("b-input", {
      attrs: {
        value: _vm._f("dateFormat")(item === null || item === void 0 ? void 0 : item.CreateDate),
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        label: "DATA DE DEMISSÃO"
      }
    }, [_c("b-input", {
      attrs: {
        value: _vm._f("dateFormat")(item === null || item === void 0 ? void 0 : item.DemissionDate),
        disabled: ""
      }
    })], 1)], 1)], 1);
  })], 2)]), _vm._v(" "), _c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentPersonalCredit",
      animation: "slide"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c("div", {
          staticClass: "panel-heading",
          attrs: {
            role: "button",
            "aria-controls": "contentPersonalCredit"
          }
        }, [_c("strong", [_vm._v("Dados de Crédito")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("div", {
    staticClass: "content-data"
  }, [_c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        var _vm$personDetailNV18, _vm$personDetailNV18$;
        return [_vm._v("\n                PERSONA\n                "), _c("b-tooltip", {
          attrs: {
            type: "is-primary is-light",
            label: "Vari\xE1vel de cr\xE9dito com uma vis\xE3o presumida de como o usu\xE1rio se comporta. (".concat(_vm.descriptionPersonCredit((_vm$personDetailNV18 = _vm.personDetailNV) === null || _vm$personDetailNV18 === void 0 ? void 0 : (_vm$personDetailNV18$ = _vm$personDetailNV18.CADASTRAIS) === null || _vm$personDetailNV18$ === void 0 ? void 0 : _vm$personDetailNV18$.PERSONACREDITO), ")"),
            multilined: ""
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("b-input", {
    attrs: {
      value: (_vm$personDetailNV19 = _vm.personDetailNV) === null || _vm$personDetailNV19 === void 0 ? void 0 : (_vm$personDetailNV19$ = _vm$personDetailNV19.CADASTRAIS) === null || _vm$personDetailNV19$ === void 0 ? void 0 : _vm$personDetailNV19$.PERSONACREDITO,
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                SCORE (NovaVida)\n                "), _c("b-tooltip", {
          attrs: {
            type: "is-primary is-light",
            label: "O score de crédito é uma pontuação para avaliar o histórico financeiro do consumidor. Quanto maior a pontuação, melhor a avaliação! Score de 0 a 1000",
            multilined: ""
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("b-input", {
    attrs: {
      value: (_vm$personDetailNV20 = _vm.personDetailNV) === null || _vm$personDetailNV20 === void 0 ? void 0 : (_vm$personDetailNV20$ = _vm$personDetailNV20.CADASTRAIS) === null || _vm$personDetailNV20$ === void 0 ? void 0 : _vm$personDetailNV20$.SCORE,
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "SCORE PH3A"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                SCORE (PH3A)\n                "), _c("b-tooltip", {
          attrs: {
            type: "is-primary is-light",
            label: "O score de crédito é uma pontuação para avaliar o histórico financeiro do consumidor. Quanto maior a pontuação, melhor a avaliação! Score de 0 a 1000",
            multilined: ""
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("b-input", {
    attrs: {
      value: (_vm$personDetailPh8 = _vm.personDetailPh) === null || _vm$personDetailPh8 === void 0 ? void 0 : (_vm$personDetailPh8$C = _vm$personDetailPh8.CreditScore) === null || _vm$personDetailPh8$C === void 0 ? void 0 : _vm$personDetailPh8$C.D00,
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      label: "NÍVEL DE RISCO",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: (_vm$personDetailNV21 = _vm.personDetailNV) === null || _vm$personDetailNV21 === void 0 ? void 0 : (_vm$personDetailNV21$ = _vm$personDetailNV21.CADASTRAIS) === null || _vm$personDetailNV21$ === void 0 ? void 0 : _vm$personDetailNV21$.MENSAGEMSCORE,
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                PROPENSÃO PAGAMENTO\n                "), _c("b-tooltip", {
          attrs: {
            type: "is-primary is-light",
            label: "Analise de dados que calculam as chances do usuário realizar uma promessa de pagamento, valor de 0 a 1000.",
            multilined: ""
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("b-input", {
    attrs: {
      value: (_vm$personDetailNV22 = _vm.personDetailNV) === null || _vm$personDetailNV22 === void 0 ? void 0 : (_vm$personDetailNV22$ = _vm$personDetailNV22.PERFILCONSUMO) === null || _vm$personDetailNV22$ === void 0 ? void 0 : _vm$personDetailNV22$.PROPENSAO_PAGAMENTO,
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      label: "DIVIDA ATIVA DA UNIÃO",
      expanded: ""
    }
  }, [_c("b-input", {
    attrs: {
      value: ((_vm$personDetailNV23 = _vm.personDetailNV) === null || _vm$personDetailNV23 === void 0 ? void 0 : (_vm$personDetailNV23$ = _vm$personDetailNV23.CADASTRAIS) === null || _vm$personDetailNV23$ === void 0 ? void 0 : _vm$personDetailNV23$.DIVIDAATIVADAUNIAO_FLAG_DAU) === "S" ? "SIM" : "NÃO",
      disabled: ""
    }
  })], 1)], 1)], 1)]), _vm._v(" "), _c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentPersonalProfile",
      animation: "slide"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c("div", {
          staticClass: "panel-heading",
          attrs: {
            role: "button",
            "aria-controls": "contentPersonalProfile"
          }
        }, [_c("strong", [_vm._v("Dados de Perfil de Consumo")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("div", {
    staticClass: "content-data"
  }, [_c("b-field", {
    attrs: {
      grouped: ""
    }
  }, [_c("b-field", {
    attrs: {
      expanded: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                PERSONA DEMOGRÁFICA\n                "), _c("b-tooltip", {
          attrs: {
            type: "is-primary is-light",
            label: "Informação populacional que leva em conta alguns dados como Idade, sexo, ciclo de vida, renda presumida, ocupação profissional, região em que mora, formação e por aí vai.",
            multilined: ""
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("b-input", {
    attrs: {
      value: (_vm$personDetailNV24 = _vm.personDetailNV) === null || _vm$personDetailNV24 === void 0 ? void 0 : (_vm$personDetailNV24$ = _vm$personDetailNV24.CADASTRAIS) === null || _vm$personDetailNV24$ === void 0 ? void 0 : _vm$personDetailNV24$.PERSONADEMOGRAFICA,
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("b-field", {
    attrs: {
      expanded: ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v("\n                PERSONA DIGITAL\n                "), _c("b-tooltip", {
          attrs: {
            type: "is-primary is-light",
            label: "Informação do comportamento do usuário no meio digital",
            multilined: ""
          }
        }, [_c("b-icon", {
          attrs: {
            size: "is-small",
            icon: "help-circle-outline"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("b-input", {
    attrs: {
      value: (_vm$personDetailNV25 = _vm.personDetailNV) === null || _vm$personDetailNV25 === void 0 ? void 0 : (_vm$personDetailNV25$ = _vm$personDetailNV25.PERFILCONSUMO) === null || _vm$personDetailNV25$ === void 0 ? void 0 : _vm$personDetailNV25$.PERSONADIGITAL,
      disabled: ""
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "content-profile-interests"
  }, [_c("h2", {
    staticClass: "title"
  }, [_vm._v("Interesses")]), _vm._v(" "), _c("div", {
    staticClass: "list-cards-items"
  }, [_c("div", {
    staticClass: "columns"
  }, _vm._l(_vm.rowOneList, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column col-card-information",
      class: {
        active: _vm.getValueInterest(item.key) === 1
      }
    }, [_c("h4", [_vm._v(_vm._s(item.title))]), _vm._v(" "), _c("span", {
      class: "icon eva eva-".concat(item.icon)
    }), _vm._v(" "), _c("span", {
      staticClass: "bol-check"
    })]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, _vm._l(_vm.rowTwoList, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column col-card-information",
      class: {
        active: _vm.getValueInterest(item.key) === 1
      }
    }, [_c("h4", [_vm._v(_vm._s(item.title))]), _vm._v(" "), _c("span", {
      class: "icon eva eva-".concat(item.icon)
    }), _vm._v(" "), _c("span", {
      staticClass: "bol-check"
    })]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, _vm._l(_vm.rowThreeList, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column col-card-information",
      class: {
        active: _vm.getValueInterest(item.key) === 1
      }
    }, [_c("h4", [_vm._v(_vm._s(item.title))]), _vm._v(" "), _c("span", {
      class: "icon eva eva-".concat(item.icon)
    }), _vm._v(" "), _c("span", {
      staticClass: "bol-check"
    })]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, _vm._l(_vm.rowFourList, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column col-card-information",
      class: {
        active: _vm.getValueInterest(item.key) === 1
      }
    }, [_c("h4", [_vm._v(_vm._s(item.title))]), _vm._v(" "), _c("span", {
      class: "icon eva eva-".concat(item.icon)
    }), _vm._v(" "), _c("span", {
      staticClass: "bol-check"
    })]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, _vm._l(_vm.rowFiveList, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column col-card-information",
      class: {
        active: _vm.getValueInterest(item.key) === 1
      }
    }, [_c("h4", [_vm._v(_vm._s(item.title))]), _vm._v(" "), _c("span", {
      class: "icon eva eva-".concat(item.icon)
    }), _vm._v(" "), _c("span", {
      staticClass: "bol-check"
    })]);
  }), 0)])])], 1)]), _vm._v(" "), _vm.sociedades.length ? _c("b-collapse", {
    staticClass: "panel",
    attrs: {
      "aria-id": "contentPersonalCorporate",
      animation: "slide"
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c("div", {
          staticClass: "panel-heading",
          attrs: {
            role: "button",
            "aria-controls": "contentPersonalCorporate"
          }
        }, [_c("strong", [_vm._v("Participação Societaria")])])];
      },
      proxy: true
    }], null, false, 3546429508)
  }, [_vm._v(" "), _c("div", {
    staticClass: "content-data"
  }, [_c("b-field", {
    staticClass: "divider",
    attrs: {
      label: "Dados NovaVida:"
    }
  }), _vm._v(" "), _vm._l(_vm.sociedades.slice(0, 3), function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "content-address"
    }, [item.CNPJ ? _c("div", [_c("b-field", {
      attrs: {
        grouped: ""
      }
    }, [_c("b-field", {
      attrs: {
        label: "CNPJ"
      }
    }, [_c("b-input", {
      attrs: {
        value: _vm._f("maskCNPJ")(item.CNPJ),
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        label: "RAZÃO SOCIAL",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item.RAZAO,
        disabled: ""
      }
    })], 1)], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        grouped: ""
      }
    }, [_c("b-field", {
      attrs: {
        label: "PARTICIPAÇÃO"
      }
    }, [_c("b-input", {
      attrs: {
        value: item.PARTICIPACAO,
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        label: "DATA FUNDAÇÃO",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: _vm._f("dateFormat")(item.DATA_FUNDACAO),
        disabled: ""
      }
    })], 1), _vm._v(" "), _c("b-field", {
      attrs: {
        label: "STATUS RECEITA FEDERAL",
        expanded: ""
      }
    }, [_c("b-input", {
      attrs: {
        value: item.STATUS_RF,
        disabled: ""
      }
    })], 1)], 1)], 1) : _vm._e()]);
  })], 2)]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "column col-detail-person is-one-quarter"
  }, [_c("div", {
    staticClass: "list-col-card-detail"
  }, [_c("div", {
    staticClass: "col-header-content"
  }, [_vm.personDetail.imageBase64 ? _c("img", {
    staticClass: "user-img",
    attrs: {
      src: _vm.userImg
    }
  }) : _vm._e(), _vm._v(" "), _vm.personDetail.liveness ? _c("h4", [_vm._v("Score da Imagem: " + _vm._s(_vm.personDetail.liveness.score) + " de 100")]) : _vm._e(), _vm._v(" "), _c("h4", [_vm._v("CPF: " + _vm._s(_vm._f("maskCPF")(_vm.personDetail.document)))]), _vm._v(" "), (_vm$personDetailPh9 = _vm.personDetailPh) !== null && _vm$personDetailPh9 !== void 0 && _vm$personDetailPh9.Name ? _c("h2", [_vm._v(_vm._s(_vm.personDetailPh.Name))]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "col-content-section"
  }, [_c("div", {
    staticClass: "field"
  }, [_c("label", [_vm._v("Nome da mãe")]), _vm._v(" "), (_vm$personDetailPh10 = _vm.personDetailPh) !== null && _vm$personDetailPh10 !== void 0 && (_vm$personDetailPh10$ = _vm$personDetailPh10.Person) !== null && _vm$personDetailPh10$ !== void 0 && _vm$personDetailPh10$.MotherName ? _c("p", [_vm._v(_vm._s(_vm.personDetailPh.Person.MotherName))]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", [_vm._v("Data de nascimento")]), _vm._v(" "), (_vm$personDetailPh11 = _vm.personDetailPh) !== null && _vm$personDetailPh11 !== void 0 && _vm$personDetailPh11.BirthDate ? _c("p", [_vm._v(_vm._s(_vm._f("dateFormat")((_vm$personDetailPh12 = _vm.personDetailPh) === null || _vm$personDetailPh12 === void 0 ? void 0 : _vm$personDetailPh12.BirthDate)))]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", [_vm._v("Idade")]), _vm._v(" "), (_vm$personDetailPh13 = _vm.personDetailPh) !== null && _vm$personDetailPh13 !== void 0 && _vm$personDetailPh13.Age ? _c("p", [_vm._v("\n              " + _vm._s("".concat((_vm$personDetailPh14 = _vm.personDetailPh) === null || _vm$personDetailPh14 === void 0 ? void 0 : _vm$personDetailPh14.Age, " anos")) + "\n            ")]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", [_vm._v("Gênero")]), _vm._v(" "), (_vm$personDetailNV26 = _vm.personDetailNV) !== null && _vm$personDetailNV26 !== void 0 && (_vm$personDetailNV26$ = _vm$personDetailNV26.CADASTRAIS) !== null && _vm$personDetailNV26$ !== void 0 && _vm$personDetailNV26$.SEXO ? _c("p", [_vm._v(_vm._s((_vm$personDetailNV27 = _vm.personDetailNV) === null || _vm$personDetailNV27 === void 0 ? void 0 : (_vm$personDetailNV27$ = _vm$personDetailNV27.CADASTRAIS) === null || _vm$personDetailNV27$ === void 0 ? void 0 : _vm$personDetailNV27$.SEXO))]) : _vm._e()]), _vm._v(" "), (_vm$personDetailNV28 = _vm.personDetailNV) !== null && _vm$personDetailNV28 !== void 0 && (_vm$personDetailNV28$ = _vm$personDetailNV28.CADASTRAIS) !== null && _vm$personDetailNV28$ !== void 0 && _vm$personDetailNV28$.NACIONALIDADE ? _c("div", {
    staticClass: "field"
  }, [_c("label", [_vm._v("País")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.personDetailNV.CADASTRAIS.NACIONALIDADE))])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", [_vm._v("Escolaridade")]), _vm._v(" "), (_vm$personDetailNV29 = _vm.personDetailNV) !== null && _vm$personDetailNV29 !== void 0 && (_vm$personDetailNV29$ = _vm$personDetailNV29.CADASTRAIS) !== null && _vm$personDetailNV29$ !== void 0 && _vm$personDetailNV29$.POSSIVELESCOLARIDADE ? _c("p", [_vm._v(_vm._s((_vm$personDetailNV30 = _vm.personDetailNV) === null || _vm$personDetailNV30 === void 0 ? void 0 : (_vm$personDetailNV30$ = _vm$personDetailNV30.CADASTRAIS) === null || _vm$personDetailNV30$ === void 0 ? void 0 : _vm$personDetailNV30$.POSSIVELESCOLARIDADE))]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "field"
  }, [_c("label", [_vm._v("Indicação de óbito")]), _vm._v(" "), (_vm$personDetailNV31 = _vm.personDetailNV) !== null && _vm$personDetailNV31 !== void 0 && (_vm$personDetailNV31$ = _vm$personDetailNV31.OBITO) !== null && _vm$personDetailNV31$ !== void 0 && _vm$personDetailNV31$.FLOBITO ? _c("p", [_vm._v("\n              " + _vm._s(((_vm$personDetailNV32 = _vm.personDetailNV) === null || _vm$personDetailNV32 === void 0 ? void 0 : _vm$personDetailNV32.OBITO.FLOBITO) === "S" ? "SIM" : "NÃO") + "\n            ")]) : _vm._e()])])])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;