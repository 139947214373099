"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cols-form tab-card-validation-finger-component"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [!_vm.validationItem._id ? _c("not-found") : _vm._e(), _vm._v(" "), _vm.validationItem._id ? _c("card", {
    staticClass: "card-detail-image"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h2", [_vm._v("Validação " + _vm._s(_vm._f("dateComplete")(_vm.validationItem.createdAt)))])])]), _vm._v(" "), _c("div", [_c("div", {
    staticClass: "columns"
  }, _vm._l(_vm.validationItem.validationApp, function (itemData, idxData) {
    return _c("div", {
      key: idxData * 3,
      staticClass: "column col-img"
    }, [_vm._l(itemData === null || itemData === void 0 ? void 0 : itemData.templates, function (itemImage, idxImage) {
      return _c("div", {
        key: idxImage * 4
      }, [_vm.imageUrl(itemImage) ? _c("img", {
        staticClass: "img-raised",
        attrs: {
          src: _vm.imageUrl(itemImage)
        }
      }) : _vm._e()]);
    }), _vm._v(" "), _c("h2", [_vm._v("Mão: " + _vm._s(_vm._f("hand")(itemData === null || itemData === void 0 ? void 0 : itemData.hand)))]), _vm._v(" "), _c("h2", [_vm._v("Digital: " + _vm._s(_vm._f("finger")(itemData === null || itemData === void 0 ? void 0 : itemData.finger)))]), _vm._v(" "), _c("h2", [_vm._v("Qualidade: " + _vm._s((itemData === null || itemData === void 0 ? void 0 : itemData.quality) || "--"))]), _vm._v(" "), _c("h4", [_vm._v("NFIQ: " + _vm._s(itemData === null || itemData === void 0 ? void 0 : itemData.NFIQ))]), _vm._v(" "), _c("h4", [_vm._v("Spoofing: " + _vm._s(itemData === null || itemData === void 0 ? void 0 : itemData.spoofScore))]), _vm._v(" "), _c("h4", [_vm._v("Bits por pixel: " + _vm._s((itemData === null || itemData === void 0 ? void 0 : itemData.bitsPerpixel) || "--"))]), _vm._v(" "), _c("h4", [_vm._v("Tamanho: " + _vm._s((itemData === null || itemData === void 0 ? void 0 : itemData.height) || "--") + " x " + _vm._s((itemData === null || itemData === void 0 ? void 0 : itemData.width) || "--"))])], 2);
  }), 0)])]) : _vm._e(), _vm._v(" "), _vm.totalItems > 1 ? _c("div", {
    staticClass: "content-load-more-items"
  }, [!_vm.validations.length ? _c("div", {
    staticClass: "buttons"
  }, [_c("b-button", {
    attrs: {
      type: "is-primary"
    },
    on: {
      click: _vm.loadMoreItems
    }
  }, [_vm._v("Carregar outros " + _vm._s(_vm.totalItems - 1) + " itens")])], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "list-items"
  }, _vm._l(_vm.validations, function (item, idx) {
    return _c("card", {
      key: idx,
      staticClass: "card-detail-image"
    }, [_c("div", {
      staticClass: "columns"
    }, [_c("div", {
      staticClass: "column"
    }, [_c("h2", [_vm._v("Validação " + _vm._s(_vm._f("dateComplete")(item.createdAt)))])])]), _vm._v(" "), _c("div", [_c("div", {
      staticClass: "columns"
    }, _vm._l(item.validationApp, function (itemData, idxData) {
      return _c("div", {
        key: idxData * 3,
        staticClass: "column col-img"
      }, [_vm._l(itemData === null || itemData === void 0 ? void 0 : itemData.templates, function (itemImage, idxImage) {
        return _c("div", {
          key: idxImage * 4
        }, [_vm.imageUrl(itemImage) ? _c("img", {
          staticClass: "img-raised",
          attrs: {
            src: _vm.imageUrl(itemImage)
          }
        }) : _vm._e()]);
      }), _vm._v(" "), _c("h2", [_vm._v("Mão: " + _vm._s(_vm._f("hand")(itemData === null || itemData === void 0 ? void 0 : itemData.hand)))]), _vm._v(" "), _c("h2", [_vm._v("Digital: " + _vm._s(_vm._f("finger")(itemData === null || itemData === void 0 ? void 0 : itemData.finger)))]), _vm._v(" "), _c("h2", [_vm._v("Qualidade: " + _vm._s((itemData === null || itemData === void 0 ? void 0 : itemData.quality) || "--"))]), _vm._v(" "), _c("h4", [_vm._v("NFIQ: " + _vm._s(itemData === null || itemData === void 0 ? void 0 : itemData.NFIQ))]), _vm._v(" "), _c("h4", [_vm._v("Spoofing: " + _vm._s(itemData === null || itemData === void 0 ? void 0 : itemData.spoofScore))]), _vm._v(" "), _c("h4", [_vm._v("Bits por pixel: " + _vm._s((itemData === null || itemData === void 0 ? void 0 : itemData.bitsPerpixel) || "--"))]), _vm._v(" "), _c("h4", [_vm._v("Tamanho: " + _vm._s((itemData === null || itemData === void 0 ? void 0 : itemData.height) || "--") + " x " + _vm._s((itemData === null || itemData === void 0 ? void 0 : itemData.width) || "--"))])], 2);
    }), 0)])]);
  }), 1)]) : _vm._e()], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;