"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "container onboarding-data"
  }, [_c("card-personal-data", {
    attrs: {
      "item-id": _vm.idItem
    }
  })], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;