"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NavbarToggleButton = _interopRequireDefault(require("./NavbarToggleButton"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(function () {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}
var _default = exports.default = {
  name: 'Navbar',
  components: {
    NavbarToggleButton: _NavbarToggleButton.default
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'relative'
    },
    colorOnScroll: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'white',
      validator: function validator(value) {
        return ['white', 'default', 'primary', 'danger', 'success', 'warning', 'info'].includes(value);
      }
    },
    navMenuClasses: {
      type: String,
      default: ''
    },
    menuImage: {
      type: String,
      default: ''
    },
    expand: {
      type: [String, Boolean],
      default: 'lg'
    }
  },
  provide: function provide() {
    return {
      closeNavbar: this.close
    };
  },
  data: function data() {
    return {
      showMenu: false,
      extraNavClasses: '',
      currentScrollValue: 0
    };
  },
  computed: {
    slotData: function slotData() {
      return {
        toggle: this.toggle,
        open: this.open,
        close: this.close,
        isToggled: this.showMenu
      };
    },
    classes: function classes() {
      var color = "bg-".concat(this.type);
      var navPosition = "navbar-".concat(this.position);
      var colorOnScrollTransparent = this.colorOnScroll && this.currentScrollValue < this.colorOnScroll;
      if (this.position === 'fixed') {
        navPosition = 'fixed-top';
      }
      return [{
        'navbar-transparent': this.transparent || colorOnScrollTransparent
      }, _defineProperty({}, color, !this.transparent && this.colorOnScroll === 0), this.expand ? "navbar-expand-".concat(this.expand) : '', navPosition, this.extraNavClasses];
    }
  },
  mounted: function mounted() {
    document.addEventListener('scroll', this.scrollListener);
  },
  beforeDestroy: function beforeDestroy() {
    document.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    setNav: function setNav(value) {
      var htmlClasses = document.querySelector('html').classList;
      if (value) {
        htmlClasses.add('nav-open');
      } else {
        htmlClasses.remove('nav-open');
      }
      var isOpen = htmlClasses.contains('nav-open');
      var eventToTrigger = isOpen ? 'open' : 'close';
      this.showMenu = isOpen;
      this.$emit(eventToTrigger);
    },
    toggle: function toggle() {
      this.setNav(!this.showMenu);
    },
    open: function open() {
      this.setNav(true);
    },
    close: function close() {
      if (this.showMenu) {
        this.setNav(false);
      }
    },
    handleScroll: function handleScroll() {
      var scrollValue = document.body.scrollTop || document.documentElement.scrollTop;
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = "bg-".concat(this.type);
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = '';
        }
      }
    },
    scrollListener: function scrollListener() {
      resizeThrottler(this.handleScroll);
    }
  }
};