"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "graficbar-container",
    attrs: {
      id: "div-graficbar-component"
    }
  }, [_vm._m(0), _vm._v(" "), _c("card-grafic", {
    attrs: {
      category: "Ping - Hive"
    }
  }, [_c("h2", [_vm._v("10 ms")])]), _vm._v(" "), _vm.imgCPU ? _c("card-grafic", {
    attrs: {
      category: "CPU"
    }
  }, [_c("img", {
    attrs: {
      src: _vm.imgCPU
    }
  })]) : _vm._e(), _vm._v(" "), _vm.imgRAM ? _c("card-grafic", {
    attrs: {
      category: "MEMORY"
    }
  }, [_c("img", {
    attrs: {
      src: _vm.imgRAM
    }
  })]) : _vm._e(), _vm._v(" "), _vm.imgREDE ? _c("card-grafic", {
    attrs: {
      category: "REDE"
    }
  }, [_c("img", {
    attrs: {
      src: _vm.imgREDE
    }
  })]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-client-details"
  }, [_c("h3", [_vm._v("Monitoramento")])]);
}];
render._withStripped = true;