"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this;
    var args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}
var _default = exports.default = {
  name: 'Parallax',
  data: function data() {
    return {
      styles: {},
      debounceTimeout: 6
    };
  },
  mounted: function mounted() {
    var self = this;
    window.addEventListener('scroll', function () {
      var scrollVal = this.scrollY;
      self.checkForParallax(scrollVal);
    });
  },
  methods: {
    handleScroll: function handleScroll(scrollVal) {
      var oVal = scrollVal / 3;
      this.styles = {
        transform: "translate3d(0, ".concat(oVal, "px,0)")
      };
    },
    checkForParallax: function checkForParallax(scrollVal) {
      var _this = this;
      var fn = debounce(function () {
        return _this.handleScroll(scrollVal);
      }, this.debounceTimeout);
      fn();
    }
  }
};