"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-graficbar-component"
  }, [_vm.$slots.category || _vm.category ? _c("h6", {
    staticClass: "card-category"
  }, [_vm._v("\n    " + _vm._s(_vm.category) + "\n  ")]) : _vm._e(), _vm._v(" "), _vm.$slots.default ? _c("div", {
    staticClass: "card-body",
    class: _vm.cardBodyClasses
  }, [_vm._t("default")], 2) : _vm._e(), _vm._v(" "), _vm._t("raw-content"), _vm._v(" "), _vm.$slots.footer && !_vm.noFooterLine ? _c("hr") : _vm._e(), _vm._v(" "), _vm.$slots.footer ? _c("div", {
    staticClass: "card-footer"
  }, [_vm._t("footer")], 2) : _vm._e()], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;