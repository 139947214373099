"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj,
    attrs: {
      id: "div-appWrapper"
    }
  }, [_c("div", {
    staticClass: "noSidebarContainer",
    attrs: {
      id: "div-mainContainer"
    }
  }, [_c("app-main", {
    attrs: {
      id: "div-mainContainer-appMain"
    }
  }), _vm._v(" "), _c("footerbar", {
    attrs: {
      id: "div-mainContainer-footerbar"
    }
  })], 1), _vm._v(" "), _c("b-loading", {
    attrs: {
      "is-full-page": true,
      active: _vm.isLoading,
      "can-cancel": false
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.isLoading = $event;
      }
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;