"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
require("@/assets/styles/index.scss");
var _vue = _interopRequireDefault(require("vue"));
var _App = _interopRequireDefault(require("./App"));
var _i18n = _interopRequireDefault(require("./i18n"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _moment = _interopRequireDefault(require("moment"));
var _pluginsKit = _interopRequireDefault(require("./plugins/plugins-kit"));
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _vueEllipseProgress = _interopRequireDefault(require("vue-ellipse-progress"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/*!
 =========================================================
 * HivePlace BackOffice - v1.0.0
 =========================================================
 * Product Page: https://backoffice.hiveplace.com.br
 * Copyright 2020 Skill
 * Designed by www.skilltecnologia.com Coded by skilltecnologia.com.br
 =========================================================
 */
/* Styles */

// Libs

// permission control

// global filters

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});

// ProductionTip
_vue.default.config.productionTip = false;

// Plugins
_vue.default.use(_pluginsKit.default);
_vue.default.use(_elementUi.default);
_vue.default.use(_vueEllipseProgress.default);

// translate date format
_moment.default.locale('pt-BR');
new _vue.default({
  el: '#app',
  i18n: _i18n.default,
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});