"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
var _components = require("./components");
var _default = exports.default = {
  name: 'OnboardingDetailPerson',
  bodyClass: 'onboarding-detail-person-page',
  components: {
    CardPersonalData: _components.CardPersonalData
  },
  data: function data() {
    return {
      filePhoto: null,
      idItem: null,
      personalData: {},
      listScore: {}
    };
  },
  computed: {
    birthdate: function birthdate() {
      var data = (0, _utils.convertDateBR)(this.personalData.birthdate);
      if (data) {
        return data;
      }
      return '';
    }
  },
  watch: {
    tabActive: function tabActive(val) {
      if (!this.tabsOpened.includes(val)) {
        this.tabsOpened.push(val);
      }
    }
  },
  created: function created() {
    var idItem = this.$route.params.idItem;
    if (idItem) this.idItem = idItem;
  },
  methods: {}
};