"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-dashboard-details-service"
  }, [_c("tiles", {
    attrs: {
      "max-per-row": 4
    }
  }, [_c("b-tooltip", {
    staticClass: "tooltip-service",
    attrs: {
      position: "is-bottom",
      "is-multiline": ""
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c("p", {
          domProps: {
            innerHTML: _vm._s(_vm.servicesName)
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-primary",
      number: _vm.totalServicesNow || 0,
      label: "Serviços chamados",
      load: _vm.loadCards
    }
  })], 1), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-success",
      number: _vm.details.totalRequestsCreateds || 0,
      label: "Total de requisições",
      load: _vm.loadCards
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.details.totalRequestsErrors || 0,
      "error-percent": _vm.details.percentErrors || 0,
      label: "Total de erros",
      load: _vm.loadCards
    }
  }), _vm._v(" "), _c("card-widget", {
    staticClass: "tile is-child",
    attrs: {
      type: "is-info",
      number: _vm.details.totalRequestsSuccess || 0,
      "error-percent": _vm.details.PercentNotFetchs || 0,
      label: "Total de sucessos",
      load: _vm.loadCards
    }
  })], 1), _vm._v(" "), _c("card-component", {
    attrs: {
      title: _vm.titleMonitoramento,
      icon: "finance",
      "header-icon": "reload"
    },
    on: {
      "header-icon-click": _vm.fillChartData
    }
  }, [!_vm.loadChart ? _c("div", {
    staticClass: "chart-area"
  }, [_c("line-chart", {
    style: {
      height: "100%"
    },
    attrs: {
      "chart-data": _vm.chartData,
      "chart-options": _vm.chartOptions
    }
  })], 1) : _c("div", {
    staticClass: "chart-area"
  }, [_c("b-skeleton", {
    attrs: {
      height: "180px"
    }
  })], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;