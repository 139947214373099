"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.componentTag, {
    tag: "component",
    staticClass: "badge",
    class: "badge-".concat(_vm.type),
    attrs: {
      href: _vm.href
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;