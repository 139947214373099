"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.componentType, {
    tag: "component",
    staticClass: "dropdown-item",
    attrs: {
      to: _vm.to
    },
    nativeOn: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.closeNav.apply(null, arguments);
      }
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;