"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card-certificate-component",
    on: {
      click: _vm.onDownload
    }
  }, [_c("div", {
    staticClass: "card"
  }, [_c("div", {
    staticClass: "card-content"
  }, [_c("img", {
    staticClass: "type-cert",
    attrs: {
      src: _vm.iconType,
      alt: ""
    }
  }), _vm._v(" "), _vm.logo ? _c("div", {
    staticClass: "content-logo",
    class: _vm.type
  }, [_c("img", {
    staticClass: "logo-cert",
    attrs: {
      src: _vm.logo,
      alt: ""
    }
  })]) : _vm._e(), _vm._v(" "), _c("h2", [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;