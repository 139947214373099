"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "progress-channel-component md-progress-bar md-accent md-buffer md-theme-default",
    class: {
      success: _vm.type === "success"
    }
  }, [_c("div", {
    staticClass: "md-progress-bar-track",
    style: "width: calc(100% - ".concat(_vm.value, "% - 50px);left: calc(").concat(_vm.value, "% + 31px);")
  }), _vm._v(" "), _c("div", {
    staticClass: "md-progress-bar-fill",
    style: "width: ".concat(_vm.value, "%;")
  }), _vm._v(" "), _c("div", {
    staticClass: "md-progress-bar-buffer",
    style: "left: calc(".concat(_vm.value, "% + 8px)")
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;