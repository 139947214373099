"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue2Transitions = require("vue2-transitions");
var _default = exports.default = {
  name: 'Alert',
  components: {
    FadeTransition: _vue2Transitions.FadeTransition
  },
  props: {
    type: {
      type: String,
      default: 'default',
      description: 'Alert type'
    },
    dismissible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      visible: true
    };
  },
  methods: {
    dismissAlert: function dismissAlert() {
      this.visible = false;
    }
  }
};