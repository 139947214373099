"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-dash"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h1", {
    staticClass: "title-page"
  }, [_vm._v("Monitor de Conexões")]), _vm._v(" "), _vm.filterService.length ? _c("monitor-status-items", {
    attrs: {
      healthy: _vm.servicesHealthys,
      critical: _vm.servicesCriticals
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "column is-two-fifths is-flex is-justify-content-flex-end"
  }, [_c("field-search-input", {
    attrs: {
      placeholder: "Pesquise por serviço"
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)]), _vm._v(" "), _vm.filterService.length ? _c("div", {
    staticClass: "columns cols-cards-info is-multiline"
  }, _vm._l(_vm.filterService, function (item, idx) {
    return _c("div", {
      key: idx,
      staticClass: "column is-one-third"
    }, [_c("card-monitor", {
      attrs: {
        title: "",
        service: item.name,
        logo: item.logo,
        active: item.active
      },
      nativeOn: {
        click: function click($event) {
          return _vm.openDetailService(item);
        }
      }
    })], 1);
  }), 0) : _c("not-found", {
    attrs: {
      text: "Não foram encontrados serviços para monitoração."
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;