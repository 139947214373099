"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$ticket$ticketType, _vm$ticket$digitalAss, _vm$ticket, _vm$ticket$userSuppor, _vm$ticket$userSuppor2, _vm$ticket2, _vm$ticket2$userSuppo, _vm$ticket2$userSuppo2, _vm$ticket3, _vm$ticket3$userSuppo, _vm$ticket3$userSuppo2, _vm$ticket4, _vm$ticket4$userSuppo, _vm$ticket4$userSuppo2;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-tickets-detail"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-three-fifths"
  }, [_c("el-card", {
    staticClass: "box-card ticket-infos"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("h1", {
    staticClass: "title-page",
    staticStyle: {
      display: "inline"
    }
  }, [_vm._v("Ticket #" + _vm._s(_vm.ticket.ticketNumber))]), _vm._v(" "), _c("div", {
    staticStyle: {
      float: "right"
    }
  }, [_c("el-tag", {
    attrs: {
      type: "success"
    }
  }, [_vm._v(_vm._s(_vm.ticket.subject || ""))]), _vm._v(" "), _c("el-tag", {
    attrs: {
      type: "danger"
    }
  }, [_vm._v(_vm._s(((_vm$ticket$ticketType = _vm.ticket.ticketTypeId) === null || _vm$ticket$ticketType === void 0 ? void 0 : _vm$ticket$ticketType.name) || ""))])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "media"
  }, [_c("div", {
    staticClass: "media-left"
  }, [_c("figure", {
    staticClass: "image is-48x48"
  }, [_c("img", {
    attrs: {
      src: _vm.user.imageUrl,
      alt: _vm.user.name
    }
  })])]), _vm._v(" "), _c("div", {
    staticClass: "media-content"
  }, [_c("p", {
    staticClass: "title is-4"
  }, [_vm._v(_vm._s(_vm.user.name))]), _vm._v(" "), _c("p", {
    staticClass: "subtitle is-6"
  }, [_vm._v(_vm._s(_vm.user.email))])])])]), _vm._v(" "), _c("div", {
    staticClass: "column",
    staticStyle: {
      "text-align": "right"
    }
  }, [_c("span", [_vm._v("Serviço: " + _vm._s(((_vm$ticket$digitalAss = _vm.ticket.digitalAssetId) === null || _vm$ticket$digitalAss === void 0 ? void 0 : _vm$ticket$digitalAss.name) || ""))])])]), _vm._v(" "), _c("div", {
    staticClass: "ticket-status is-flex is-justify-content-space-between"
  }, [_c("div", [_vm._v("Aberto em "), _c("br"), _vm._v(_vm._s(_vm._f("dateBrazil")(_vm.ticket.createdAt)))]), _vm._v(" "), _vm.ticket.status !== "RESOLVED" && _vm.ticket.status !== "CLOSED" ? _c("div", [_vm._v("\n            " + _vm._s(_vm.ticket.status === "OPEN" ? "Aguardando atendimento" : "Em atendimento") + "\n            "), _c("br"), _vm._v(" Tempo: " + _vm._s(_vm._f("calcDiffDate")(_vm.ticket.createdAt)) + "\n          ")]) : _vm._e(), _vm._v(" "), _c("div", {
    class: {
      "fase-nao-atingida": _vm.ticket.status !== "RESOLVED" && _vm.ticket.status !== "CLOSED"
    }
  }, [_vm._v("\n            Fechado "), _vm.ticket.status !== "RESOLVED" || _vm.ticket.status !== "CLOSED" ? _c("span", [_c("br"), _vm._v(_vm._s(_vm._f("dateBrazil")(_vm.ticket.dateClosed)))]) : _vm._e()])])]), _vm._v(" "), _c("el-divider", [_c("i", {
    staticClass: "el-icon-star-on"
  })]), _vm._v(" "), _c("list-messages", {
    attrs: {
      list: _vm.listMessages
    }
  }), _vm._v(" "), _vm.showCardMessage ? _c("div", {
    staticClass: "chat-container"
  }, [_c("div", {
    staticClass: "chat-card-message"
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 5,
      placeholder: "Escreva sua mensagem"
    },
    model: {
      value: _vm.textNewMessage,
      callback: function callback($$v) {
        _vm.textNewMessage = $$v;
      },
      expression: "textNewMessage"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "is-flex mb-3 content-buttons"
  }, [_c("el-button", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.toggleAttatchFilesCtrlVisibility
    }
  }, [_c("i", {
    staticClass: "el-icon-paperclip"
  }), _vm._v("   Incluir anexo")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitMessage
    }
  }, [_c("i", {
    staticClass: "el-icon-s-comment"
  }), _vm._v("   Enviar mensagem")])], 1), _vm._v(" "), _c("b-collapse", {
    attrs: {
      open: _vm.isAttacheControlVisible
    }
  }, [_c("section", [_c("b-field", [_c("b-upload", {
    attrs: {
      multiple: "",
      "drag-drop": "",
      accept: "image/*,.pdf"
    },
    model: {
      value: _vm.attachedFiles,
      callback: function callback($$v) {
        _vm.attachedFiles = $$v;
      },
      expression: "attachedFiles"
    }
  }, [_c("section", {
    staticClass: "section"
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "upload",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("Selecione o(s) arquivo(s) ou arraste e solte aqui")])])])])], 1), _vm._v(" "), _c("div", {
    staticClass: "tags"
  }, _vm._l(_vm.attachedFiles, function (file, index) {
    return _c("span", {
      key: index,
      staticClass: "tag is-primary"
    }, [_vm._v("\n                  " + _vm._s(file.name) + "\n                  "), _c("button", {
      staticClass: "delete is-small",
      attrs: {
        type: "button"
      },
      on: {
        click: function click($event) {
          return _vm.removeAttachment(index);
        }
      }
    })]);
  }), 0)], 1)])], 1)]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("el-card", {
    staticClass: "box-card card-acompanhamento-interno",
    attrs: {
      "body-style": {
        padding: "0px"
      }
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("h1", {
    staticStyle: {
      display: "inline"
    }
  }, [_vm._v("Atendimento")])]), _vm._v(" "), _vm.isAdmin && _vm.ticket.status === "OPEN" ? _c("div", {
    staticClass: "card-new-sub-section center"
  }, [_c("el-button", {
    staticClass: "ml-3",
    attrs: {
      type: "primary",
      plain: ""
    },
    on: {
      click: _vm.openModalStart
    }
  }, [_vm._v("Iniciar o atendimento")])], 1) : _vm._e(), _vm._v(" "), _vm.ticket.userSupport ? _c("div", {
    staticClass: "card-new-sub-section"
  }, [_c("h2", [_vm._v("Responsável pelo atendimento:")]), _vm._v(" "), _c("div", {
    staticClass: "media mb-2"
  }, [_c("div", {
    staticClass: "media-left"
  }, [_c("el-avatar", {
    attrs: {
      size: 60,
      src: (_vm$ticket = _vm.ticket) === null || _vm$ticket === void 0 ? void 0 : (_vm$ticket$userSuppor = _vm$ticket.userSupport) === null || _vm$ticket$userSuppor === void 0 ? void 0 : (_vm$ticket$userSuppor2 = _vm$ticket$userSuppor.personId) === null || _vm$ticket$userSuppor2 === void 0 ? void 0 : _vm$ticket$userSuppor2.imageUrl
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "media-content"
  }, [_c("p", {
    staticClass: "title is-4"
  }, [_vm._v("\n                " + _vm._s((_vm$ticket2 = _vm.ticket) === null || _vm$ticket2 === void 0 ? void 0 : (_vm$ticket2$userSuppo = _vm$ticket2.userSupport) === null || _vm$ticket2$userSuppo === void 0 ? void 0 : (_vm$ticket2$userSuppo2 = _vm$ticket2$userSuppo.personId) === null || _vm$ticket2$userSuppo2 === void 0 ? void 0 : _vm$ticket2$userSuppo2.name) + "\n              ")]), _vm._v(" "), _c("p", {
    staticClass: "subtitle is-6"
  }, [_vm._v(_vm._s(_vm._f("maskPhone")((_vm$ticket3 = _vm.ticket) === null || _vm$ticket3 === void 0 ? void 0 : (_vm$ticket3$userSuppo = _vm$ticket3.userSupport) === null || _vm$ticket3$userSuppo === void 0 ? void 0 : (_vm$ticket3$userSuppo2 = _vm$ticket3$userSuppo.personId) === null || _vm$ticket3$userSuppo2 === void 0 ? void 0 : _vm$ticket3$userSuppo2.phone)) + " | " + _vm._s((_vm$ticket4 = _vm.ticket) === null || _vm$ticket4 === void 0 ? void 0 : (_vm$ticket4$userSuppo = _vm$ticket4.userSupport) === null || _vm$ticket4$userSuppo === void 0 ? void 0 : (_vm$ticket4$userSuppo2 = _vm$ticket4$userSuppo.personId) === null || _vm$ticket4$userSuppo2 === void 0 ? void 0 : _vm$ticket4$userSuppo2.email))])])])]) : _vm._e(), _vm._v(" "), _vm.listAttachments.length ? _c("div", {
    staticClass: "card-new-sub-section"
  }, [_c("h2", [_vm._v("Anexos vinculados:")]), _vm._v(" "), _c("div", {
    staticClass: "card-attachment-list"
  }, _vm._l(_vm.listAttachments, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "list-item"
    }, [_c("a", {
      attrs: {
        href: item.url,
        target: "_blank"
      }
    }, [_vm._v("Anexo " + _vm._s(i + 1))])]);
  }), 0)]) : _vm._e(), _vm._v(" "), !_vm.isClosed ? _c("div", {
    staticClass: "card-new-sub-section center"
  }, [_c("el-button", {
    staticClass: "ml-3",
    attrs: {
      type: "primary",
      plain: ""
    },
    on: {
      click: _vm.openModalEndTicket
    }
  }, [_vm._v("Finalizar atendimento")])], 1) : _vm._e()])], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;