"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("b-table", {
    attrs: {
      "checked-rows": _vm.checkedRows,
      paginated: _vm.paginated,
      "per-page": _vm.perPage,
      data: _vm.list,
      "default-sort": "ResponseDate",
      "default-sort-direction": "desc",
      striped: "",
      hoverable: ""
    },
    on: {
      "update:checkedRows": function updateCheckedRows($event) {
        _vm.checkedRows = $event;
      },
      "update:checked-rows": function updateCheckedRows($event) {
        _vm.checkedRows = $event;
      }
    }
  }, [_c("b-table-column", {
    attrs: {
      label: "Total de erros",
      field: "totalErrors",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n      " + _vm._s(props.row.totalErrors) + "\n    ")];
      }
    }])
  }), _vm._v(" "), _vm.statusCode ? _c("b-table-column", {
    attrs: {
      label: "Status Code",
      field: "httpStatus",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n      " + _vm._s(props.row.httpStatus) + "\n    ")];
      }
    }], null, false, 3979022769)
  }) : _vm._e(), _vm._v(" "), _c("b-table-column", {
    attrs: {
      label: "Serviço",
      field: "service",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_vm._v("\n      " + _vm._s(props.row.service) + "\n    ")];
      }
    }])
  }), _vm._v(" "), _c("section", {
    staticClass: "section",
    attrs: {
      slot: "empty"
    },
    slot: "empty"
  }, [_c("div", {
    staticClass: "content has-text-grey has-text-centered"
  }, [_c("p", [_c("b-icon", {
    attrs: {
      icon: "emoticon-sad",
      size: "is-large"
    }
  })], 1), _vm._v(" "), _c("p", [_vm._v("Sem registros até o momento…")])])])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;