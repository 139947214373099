"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title,
      isMobile = _context$props.isMobile,
      collapse = _context$props.collapse;
    var vnodes = [];
    if (icon) {
      vnodes.push(h("span", {
        "attrs": {
          "id": 'span-sidebar-menuItem-icon'
        },
        "class": 'icon'
      }, [h("i", {
        "class": icon
      })]));
    }
    if (!isMobile && title && !collapse) {
      vnodes.push(h("span", {
        "attrs": {
          "id": 'span-sidebar-menuItem-title'
        },
        "slot": 'title',
        "class": 'titlte-text'
      }, [title]));
    }
    return vnodes;
  }
};