"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$personalData, _vm$personalData2, _vm$personalData3, _vm$process;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h3", {
    staticClass: "title",
    staticStyle: {
      "margin-top": "-25px"
    }
  }, [_vm._v("\n    Onboarding " + _vm._s((_vm$personalData = _vm.personalData) !== null && _vm$personalData !== void 0 && _vm$personalData.name ? " - ".concat((_vm$personalData2 = _vm.personalData) === null || _vm$personalData2 === void 0 ? void 0 : _vm$personalData2.name) : "") + "\n  ")]), _vm._v(" "), _c("el-card", {
    staticClass: "box-card",
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "header-personal-infos"
  }, [_c("div", [_vm._v("CPF: "), _c("strong", [_vm._v(_vm._s(_vm.cpf))])]), _vm._v(" "), _c("div", [_vm._v("Celular: "), _c("strong", [_vm._v(_vm._s(_vm.phone))])]), _vm._v(" "), _c("div", [_vm._v("CEP: "), _c("strong", [_vm._v(_vm._s(_vm.zipCode))])]), _vm._v(" "), _c("div", [_vm._v("Email: "), _c("strong", [_vm._v(_vm._s((_vm$personalData3 = _vm.personalData) === null || _vm$personalData3 === void 0 ? void 0 : _vm$personalData3.email))])]), _vm._v(" "), _c("div", [_vm._v("Nascimento: "), _c("strong", [_vm._v(_vm._s(_vm.birthday))])]), _vm._v(" "), _c("div", [_vm._v("Status: "), _c("strong", [_vm._v(_vm._s(_vm._f("typeStatusOnboarding")((_vm$process = _vm.process) === null || _vm$process === void 0 ? void 0 : _vm$process.status)))])])])]), _vm._v(" "), _c("div", {
    staticClass: "personal-infos"
  }, [_c("div", {
    staticStyle: {
      width: "390px"
    }
  }, [_c("div", {
    staticClass: "diamond-container"
  }, [_c("canvas", {
    attrs: {
      id: "diamond-canvas",
      width: "343",
      height: "330"
    }
  })]), _vm._v(" "), _vm.scoreRisco.value ? _c("div", {
    staticClass: "vue-ellipse-risco-container"
  }, [_c("vue-ellipse-progress", {
    attrs: {
      half: "",
      angle: "0",
      size: 230,
      thickness: "5%",
      "line-mode": "in",
      progress: _vm.scoreRisco.valueAsInt,
      color: _vm.scoreRisco.gradientLineColors,
      "legend-formatter": function legendFormatter(_ref) {
        var currentValue = _ref.currentValue;
        return "<div class='ellipse-progress-info-risco'><span>".concat(currentValue, "%</span><span>score de risco</span></div>");
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "tag-legenda-risco"
  }, [_c("el-tag", {
    attrs: {
      id: "tag-score-risco",
      color: _vm.scoreRisco.legendTagBgColor,
      effect: "dark"
    }
  }, [_vm._v(_vm._s(_vm.scoreRisco.legendTagText))])], 1)], 1) : _vm._e()]), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "calc(100% - 410px)"
    }
  }, [_c("b-tabs", {
    model: {
      value: _vm.abaAtiva,
      callback: function callback($$v) {
        _vm.abaAtiva = $$v;
      },
      expression: "abaAtiva"
    }
  }, _vm._l(["CPF", "Celular", "CEP", "Email", "Documentos", "Face", "Digital", "Dossiê"], function (aba) {
    return _c("b-tab-item", {
      key: aba,
      attrs: {
        label: aba
      }
    }, [aba === "Face" ? _c("CardValidaFace", {
      attrs: {
        "item-id": _vm.itemId
      }
    }) : aba === "Digital" ? _c("CardValidaFinger", {
      attrs: {
        "item-id": _vm.itemId
      }
    }) : aba === "Documentos" ? _c("CardValidaDocuments", {
      attrs: {
        "item-id": _vm.itemId
      }
    }) : aba === "Dossiê" ? _c("CardDossie", {
      attrs: {
        "item-id": _vm.itemId
      }
    }) : _c("el-table", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        border: "",
        stripe: "",
        data: _vm.insights[aba.toLowerCase()],
        "default-sort": {
          prop: "relevance"
        }
      }
    }, [_c("el-table-column", {
      attrs: {
        prop: "relevance",
        label: "Relevância",
        width: "122",
        sortable: "",
        filters: [{
          text: "Positivo",
          value: "Positivo"
        }, {
          text: "Neutro",
          value: "Neutro"
        }, {
          text: "Alerta",
          value: "Alerta"
        }],
        "filter-method": _vm.relevanceFilterHandler
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c("div", {
            staticStyle: {
              "text-align": "center"
            }
          }, [_c("el-tag", {
            attrs: {
              size: "mini",
              type: scope.row.relevance === "Alerta" ? "danger" : scope.row.relevance === "Neutro" ? "info" : ""
            }
          }, [_vm._v(_vm._s(scope.row.relevance))])], 1)];
        }
      }], null, true)
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        prop: "code",
        label: "Código",
        width: "91",
        sortable: ""
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c("div", {
            staticStyle: {
              "text-align": "center",
              "font-family": "monospace, monospace"
            }
          }, [_vm._v("\n                    " + _vm._s(scope.row.code) + "\n                  ")])];
        }
      }], null, true)
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        prop: "insights",
        label: "Descrição",
        sortable: ""
      }
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        prop: "category",
        label: "Categoria",
        width: "180",
        sortable: ""
      }
    })], 1)], 1);
  }), 1)], 1)]), _vm._v(" "), _vm.process ? _c("el-steps", {
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      "align-center": "",
      active: _vm.stepActiveProcess
    }
  }, [_c("el-step", {
    attrs: {
      title: "10%",
      description: "Inicio do processo"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "20%",
      description: "Dados pessoais"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "40%",
      description: "Dados de endereço"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "50%",
      description: "Validação de Telefone"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "60%",
      description: "Validação de E-mail"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "80%",
      description: "Validação de Documentos"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "90%",
      description: "Validação de Senha"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "100%",
      description: "Processo Completo"
    }
  })], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;