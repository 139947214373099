"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'CardServiceDetailList',
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      default: ''
    },
    service: {
      type: [String, Number],
      default: ''
    },
    iconUp: {
      type: Boolean,
      default: true
    },
    month: {
      type: String,
      default: ''
    }
  }
};