"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$personalData, _vm$personalData2, _vm$personalData3, _vm$personalData4, _vm$personalData5, _vm$personalData6, _vm$personalData7, _vm$personalData8;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h3", {
    staticClass: "title",
    staticStyle: {
      "margin-top": "-25px"
    }
  }, [_vm._v("\n    Prova de Vida " + _vm._s((_vm$personalData = _vm.personalData) !== null && _vm$personalData !== void 0 && _vm$personalData.name ? " - ".concat((_vm$personalData2 = _vm.personalData) === null || _vm$personalData2 === void 0 ? void 0 : _vm$personalData2.name) : "") + "\n  ")]), _vm._v(" "), _c("el-card", {
    staticClass: "box-card",
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "personal-infos"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "header-personal-infos"
  }, [_c("div", [_vm._v("CPF: "), _c("strong", [_vm._v(_vm._s(_vm.cpf))])]), _vm._v(" "), _c("div", [_vm._v("E-mail: "), _c("strong", [_vm._v(_vm._s((_vm$personalData3 = _vm.personalData) === null || _vm$personalData3 === void 0 ? void 0 : _vm$personalData3.mail))])]), _vm._v(" "), _c("div", [_vm._v("Status: "), _c("strong", [_vm._v(_vm._s(_vm._f("typeStatusOnboarding")((_vm$personalData4 = _vm.personalData) === null || _vm$personalData4 === void 0 ? void 0 : _vm$personalData4.status)))])]), _vm._v(" "), ((_vm$personalData5 = _vm.personalData) === null || _vm$personalData5 === void 0 ? void 0 : _vm$personalData5.status) === "downloaded" ? _c("div", [_vm._v("Data do download: "), _c("strong", [_vm._v(_vm._s(_vm._f("date")((_vm$personalData6 = _vm.personalData) === null || _vm$personalData6 === void 0 ? void 0 : _vm$personalData6.dateDownload)))])]) : _vm._e(), _vm._v(" "), ((_vm$personalData7 = _vm.personalData) === null || _vm$personalData7 === void 0 ? void 0 : _vm$personalData7.status) === "completed" || ((_vm$personalData8 = _vm.personalData) === null || _vm$personalData8 === void 0 ? void 0 : _vm$personalData8.status) === "downloaded" ? _c("div", [_c("b-button", {
    attrs: {
      type: "is-info",
      "icon-left": "download"
    },
    on: {
      click: _vm.downloadData
    }
  }, [_vm._v("\n                Baixar dados\n              ")])], 1) : _vm._e()])])]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-tabs", {
    model: {
      value: _vm.abaAtiva,
      callback: function callback($$v) {
        _vm.abaAtiva = $$v;
      },
      expression: "abaAtiva"
    }
  }, _vm._l(["Documentos", "Face"], function (aba) {
    return _c("b-tab-item", {
      key: aba,
      attrs: {
        label: aba
      }
    }, [aba === "Face" ? _c("CardValidaFace", {
      attrs: {
        "item-id": _vm.itemId,
        data: _vm.personalData
      }
    }) : aba === "Documentos" ? _c("CardValidaDocuments", {
      attrs: {
        "item-id": _vm.itemId,
        data: _vm.personalData
      }
    }) : _vm._e()], 1);
  }), 1)], 1)])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;