"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("./components");
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _document = document,
  body = _document.body;
var WIDTH = 992; // refer to Bootstrap's responsive design
var _default = exports.default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Footerbar: _components.Footerbar
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['sidebar', 'device', 'isLoading'])), {}, {
    isNoShowNavMenu: function isNoShowNavMenu() {
      return this.$route.meta.back && this.device === 'mobile';
    },
    isMobile: function isMobile() {
      return this.device === 'mobile';
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
        noSidebar: this.isNoShowNavMenu
      };
    }
  }),
  watch: {
    $route: function $route(route) {
      if (this.device === 'mobile' && this.sidebar.opened) {
        this.closeSideBar({
          withoutAnimation: false
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler);
  },
  mounted: function mounted() {
    var isMobile = this.$_isMobile();
    if (isMobile) {
      this.toggleDevice('mobile');
      this.closeSideBar({
        withoutAnimation: true
      });
    }
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)(['toggleDevice', 'closeSideBar', 'toggleSideBar'])), {}, {
    $_isMobile: function $_isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },
    $_resizeHandler: function $_resizeHandler() {
      if (!document.hidden) {
        var isMobile = this.$_isMobile();
        this.toggleDevice(isMobile ? 'mobile' : 'desktop');
        if (isMobile) {
          this.closeSideBar({
            withoutAnimation: true
          });
        }
      }
    },
    toggle: function toggle() {
      this.toggleSideBar();
    }
  })
};