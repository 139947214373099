"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("../components");
var _components2 = require("@/components");
/**
 * You can register global components here
 */

var globalComponents = {
  install: function install(Vue) {
    Vue.component(_components.Parallax.name, _components.Parallax);
    Vue.component(_components2.HeaderFilter.name, _components2.HeaderFilter);
    Vue.component(_components2.NotFound.name, _components2.NotFound);
    Vue.component(_components2.Tiles.name, _components2.Tiles);
    Vue.component(_components2.CardComponent.name, _components2.CardComponent);
    Vue.component(_components2.CardWidget.name, _components2.CardWidget);
  }
};
var _default = exports.default = globalComponents;