"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-dashboard-monitor-component"
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column is-two-fifths col-details"
  }, [_c("div", {
    staticClass: "card-header"
  }, [_c("div", {
    staticClass: "col-detail-header"
  }, [_c("h3", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))])])]), _vm._v(" "), _c("div", {
    staticClass: "card-content"
  }, [_c("div", {
    staticClass: "content"
  }, [!_vm.load ? _c("h1", [_vm._v(_vm._s(_vm._f("maskNumber")(_vm.total)))]) : _c("b-skeleton", {
    attrs: {
      circle: "",
      width: "84px",
      height: "84px",
      position: "is-centered"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "card-footer"
  }, [_c("h6", [_vm._v("Última atualização")]), _vm._v(" "), _c("div", {
    staticClass: "detail"
  }, [_vm._v(_vm._s(_vm.lastUpdate))])])]), _vm._v(" "), _c("div", {
    staticClass: "column col-dash"
  }, [_c("div", {
    staticClass: "dash-lines"
  }, [_c("div", {
    staticClass: "line_progress__success"
  }, [_c("h2", [!_vm.load ? _c("b", [_vm._v(_vm._s(_vm._f("maskNumber")(_vm.totalSuccess)))]) : _vm._e(), _c("span", [_vm._v("com sucesso")])]), _vm._v(" "), !_vm.load ? _c("b-progress", {
    attrs: {
      max: _vm.total || 1,
      value: _vm.totalSuccess || 0,
      type: "is-success",
      "show-value": "",
      format: "percent"
    }
  }) : _c("b-skeleton", {
    attrs: {
      height: "20px"
    }
  })], 1), _vm._v(" "), _vm.totalError ? _c("div", {
    staticClass: "line_progress__danger"
  }, [_c("h2", [!_vm.load ? _c("b", [_vm._v(_vm._s(_vm._f("maskNumber")(_vm.totalError)))]) : _vm._e(), _c("span", [_vm._v(_vm._s(_vm.errorLabel || "com erro"))])]), _vm._v(" "), !_vm.load ? _c("b-progress", {
    attrs: {
      max: _vm.total || 1,
      value: _vm.totalError || 0,
      type: "is-danger",
      "show-value": "",
      format: "percent"
    }
  }) : _c("b-skeleton", {
    attrs: {
      height: "20px"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "line_progress__info"
  }, [_c("h2", [!_vm.load ? _c("b", [_vm._v(_vm._s(_vm._f("maskNumber")(_vm.total3Number)))]) : _vm._e(), _c("span", [_vm._v(_vm._s(_vm.total3Label))])]), _vm._v(" "), !_vm.load ? _c("b-progress", {
    attrs: {
      max: _vm.total || 1,
      value: _vm.total3Number || 0,
      type: _vm.total3Color,
      "show-value": "",
      format: "percent"
    }
  }) : _c("b-skeleton", {
    attrs: {
      height: "20px"
    }
  })], 1)])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;