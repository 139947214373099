"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-new-services"
  }, [_c("div", {
    staticClass: "header-items"
  }, [_c("h5", {
    staticClass: "grid-h title-page"
  }, [_c("button", {
    attrs: {
      nbButton: ""
    },
    on: {
      click: _vm.goBack
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "arrow-left"
    }
  })], 1), _vm._v("\n      Teste de serviço - " + _vm._s(_vm.service.name) + "\n    ")])]), _vm._v(" "), !_vm.error ? _c("div", {
    staticClass: "content-token-information"
  }, [_vm._m(0)]) : _vm._e(), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm.error ? _c("b-message", {
    attrs: {
      type: "is-danger"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.message) + "\n  ")]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("h4", {
    staticClass: "b-heading b-heading--h4"
  }, [_vm._v("Notas")]), _vm._v(" "), _c("p", {
    staticClass: "b-paragraph"
  }, [_vm._v("\n          Os dados de acesso para o ambiente de produção serão enviados por e-mail.\n        ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content-env-item"
  }, [_c("div", {
    staticClass: "swagger",
    attrs: {
      id: "swagger"
    }
  })]);
}];
render._withStripped = true;