"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "card card-plain"
  }, [_c("div", {
    staticClass: "card-header",
    attrs: {
      id: "headingOne",
      role: "tab"
    }
  }, [_c("a", {
    attrs: {
      "data-toggle": "collapse",
      "data-parent": "#accordion",
      href: "#".concat(_vm.itemId),
      "aria-expanded": _vm.active,
      "aria-controls": "content-".concat(_vm.itemId)
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.activate.apply(null, arguments);
      }
    }
  }, [_vm._t("title", function () {
    return [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")];
  }), _vm._v(" "), _vm._t("icon", function () {
    return [!_vm.noIcon ? _c("i", {
      staticClass: "now-ui-icons arrows-1_minimal-down"
    }) : _vm._e()];
  })], 2)]), _vm._v(" "), _c("collapse-transition", {
    attrs: {
      duration: _vm.animationDuration
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.active,
      expression: "active"
    }],
    staticClass: "collapsed",
    attrs: {
      id: "content-".concat(_vm.itemId),
      role: "tabpanel",
      "aria-labelledby": _vm.title
    }
  }, [_c("div", {
    staticClass: "card-body"
  }, [_vm._t("default")], 2)])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;