"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("card", {
    staticClass: "form-description-api"
  }, [_c("form", {
    attrs: {
      id: "customerDataForm"
    },
    on: {
      submit: _vm.checkForm
    }
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "Imagem do Serviço"
    }
  }, [_c("b-upload", {
    attrs: {
      accept: "image/*",
      "drag-drop": ""
    },
    model: {
      value: _vm.arquivo,
      callback: function callback($$v) {
        _vm.arquivo = $$v;
      },
      expression: "arquivo"
    }
  }, [_c("section", {
    staticClass: "section",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c("div", {
    staticClass: "content has-text-centered"
  }, [_c("b-icon", {
    attrs: {
      icon: "file-document",
      size: "is-large"
    }
  }), _vm._v(" "), !_vm.arquivo && !_vm.link ? _c("p", [_vm._v("Busque ou arraste o arquivo de imagem")]) : _vm.link && !_vm.arquivo ? _c("p", [_vm._v("Busque ou arraste o novo arquivo de imagem")]) : _c("p", [_vm._v(_vm._s(_vm.arquivo.name || "Arquivo selecionado"))])], 1)])])], 1), _vm._v(" "), _vm.link ? _c("section", {
    staticClass: "section",
    staticStyle: {
      "background-color": "white",
      height: "300px",
      "text-align": "center",
      "border-radius": "5px"
    }
  }, [_vm.link ? _c("img", {
    staticStyle: {
      height: "250px",
      "border-radius": "5px"
    },
    attrs: {
      src: _vm.link,
      alt: "Imagem"
    }
  }) : _vm._e()]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("b-field", {
    attrs: {
      label: "Descrição do serviço"
    }
  }, [_c("div", {
    staticClass: "editorStyle",
    staticStyle: {
      height: "430px"
    }
  }, [_c("vue-editor", {
    staticStyle: {
      color: "black",
      height: "350px"
    },
    model: {
      value: _vm.descricao,
      callback: function callback($$v) {
        _vm.descricao = $$v;
      },
      expression: "descricao"
    }
  })], 1)])], 1)]), _vm._v(" "), _c("b-field", {
    staticClass: "field-buttons"
  }, [_c("b-button", {
    staticClass: "btn-save-button",
    attrs: {
      "native-type": "submit"
    }
  }, [_vm._v("\n        Salvar\n      ")])], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;