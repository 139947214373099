"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  isLoading: function isLoading(state) {
    return state.app.isLoading;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.users.token;
  },
  roles: function roles(state) {
    return state.users.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  }
};
var _default = exports.default = getters;