"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var _default2 = exports.default = {
  name: 'AppSelect',
  props: {
    classField: {
      type: String,
      default: ''
    },
    classInput: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    complete: {
      type: String,
      default: 'on'
    },
    placeholder: {
      type: String,
      default: ''
    },
    mask: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: [String, Number, Object, Boolean],
      default: ''
    },
    items: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    included: {
      type: Boolean,
      default: false
    },
    option: {
      type: String,
      default: ''
    },
    nameItem: {
      type: String,
      default: ''
    },
    secondItem: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      newValue: this.value && _typeof(this.value) === 'object' ? this.value.id || '' : this.value
    };
  },
  computed: {
    computedValue: {
      get: function get() {
        return this.newValue;
      },
      set: function set(value) {
        this.newValue = value;
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function value(_value) {
      this.newValue = _value && _typeof(_value) === 'object' ? _value.id || '' : _value;
    }
  },
  methods: {
    translateName: function translateName(item) {
      if (!this.nameItem) return item;
      if (this.secondItem) {
        return item[this.nameItem][this.secondItem];
      }
      return item[this.nameItem];
    },
    translateValue: function translateValue(item) {
      if (!this.option) return item;
      return item[this.option];
    }
  }
};