"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.tag, {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropDown,
      expression: "closeDropDown"
    }],
    tag: "component",
    staticClass: "dropdown",
    class: [{
      show: _vm.isOpen
    }, {
      dropdown: _vm.direction === "down"
    }, {
      dropup: _vm.direction === "up"
    }],
    attrs: {
      "aria-haspopup": "true",
      "aria-expanded": _vm.isOpen
    },
    on: {
      click: _vm.toggleDropDown
    }
  }, [_vm._t("title", function () {
    return [_c("a", {
      staticClass: "dropdown-toggle nav-link",
      class: {
        "no-caret": _vm.hideArrow
      },
      attrs: {
        "data-toggle": "dropdown"
      }
    }, [_c("i", {
      class: _vm.icon
    }), _vm._v(" "), _c("span", {
      staticClass: "no-icon"
    }, [_vm._v(_vm._s(_vm.title))])])];
  }), _vm._v(" "), _c("ul", {
    staticClass: "dropdown-menu",
    class: [{
      "dropdown-menu-right": _vm.position === "right"
    }, {
      show: _vm.isOpen
    }]
  }, [_vm._t("default")], 2)], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;