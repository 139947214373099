"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.type, _vm._b({
    tag: "component"
  }, "component", _vm.linkProps(_vm.to), false), [_vm._t("default")], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;