"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("fade-transition", [_vm.visible ? _c("div", {
    staticClass: "alert",
    class: ["alert-".concat(_vm.type)],
    attrs: {
      role: "alert"
    }
  }, [!_vm.dismissible ? _vm._t("default") : _c("div", {
    staticClass: "container"
  }, [_vm._t("default"), _vm._v(" "), _vm._t("dismiss-icon", function () {
    return [_c("button", {
      staticClass: "close",
      attrs: {
        type: "button",
        "aria-label": "Close"
      },
      on: {
        click: _vm.dismissAlert
      }
    }, [_c("span", {
      attrs: {
        "aria-hidden": "true"
      }
    }, [_c("i", {
      staticClass: "now-ui-icons ui-1_simple-remove"
    })])])];
  })], 2)], 2) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;