"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue2Transitions = require("vue2-transitions");
var _default2 = exports.default = {
  name: 'CollapseItem',
  components: {
    CollapseTransition: _vue2Transitions.CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    noIcon: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    animationDuration: {
      default: 250
    },
    multipleActive: {
      default: false
    },
    addItem: {
      default: function _default() {}
    },
    removeItem: {
      default: function _default() {}
    },
    deactivateAll: {
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      active: false
    };
  },
  computed: {
    itemId: function itemId() {
      return this.id || this.title;
    }
  },
  mounted: function mounted() {
    this.addItem(this);
  },
  destroyed: function destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.removeItem(this);
  },
  methods: {
    activate: function activate() {
      if (!this.multipleActive) {
        this.deactivateAll();
      }
      this.active = !this.active;
    }
  }
};