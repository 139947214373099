"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _vuex = require("vuex");
var _utils = require("@/utils");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _default2 = exports.default = {
  name: 'TableLastRequestsList',
  props: {
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      checkedRows: [],
      isModalActive: false,
      trashObject: null,
      perPage: 10,
      selected: null
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['getUser'])), {}, {
    paginated: function paginated() {
      return this.list.length > this.perPage;
    }
  }),
  watch: {
    selected: function selected(val) {
      if (this.isAdmin()) {
        this.$router.push({
          path: "/detail-dashboard-item-list/".concat(val.protocol)
        });
      }
    }
  },
  methods: {
    trashModalOpen: function trashModalOpen(obj) {
      this.trashObject = obj;
      this.isModalActive = true;
    },
    trashConfirm: function trashConfirm() {
      this.isModalActive = false;
      this.$buefy.snackbar.open({
        message: 'Confirmed',
        queue: false
      });
    },
    trashCancel: function trashCancel() {
      this.isModalActive = false;
    },
    translateStatusProtocolHive: function translateStatusProtocolHive(row) {
      return (0, _utils.translateStatusProtocolHive)(row.status, row.isWebhook);
    },
    user: function user() {
      return (0, _auth.getUser)().user.admin;
    },
    isAdmin: function isAdmin() {
      var user = (0, _auth.getUser)().user;
      return user.roleAdmin;
    }
  }
};