"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-field", _vm._l(_vm.times, function (item, idx) {
    return _c("b-radio-button", {
      key: idx,
      attrs: {
        "native-value": item.value,
        type: "is-primary is-light is-outlined"
      },
      model: {
        value: _vm.timeSelected,
        callback: function callback($$v) {
          _vm.timeSelected = $$v;
        },
        expression: "timeSelected"
      }
    }, [_c("span", [_vm._v(_vm._s(item.name))])]);
  }), 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;